.landingpage *:not(i), .landingpage body:not(i), .landingpage body *:not(i), .landingpage:not(i){
    font-family: paragraph;
}

@font-face {
    font-family: heading;
    src: url("../fonts/Barlow-Light.ttf");
}

@font-face {
    font-family: paragraph;
    src: url("../fonts/Barlow-Regular.ttf");
}

@font-face {
    font-family: boldheading;
    src: url("../fonts/Barlow-Black.ttf");
}

.landingpage .ff-default {
    font-family: Barlow-Regular;
}

.landingpage .websitecontainer {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.landingpage .websitenavbar {
    width: 100%;
    display: flex;
    align-items: center;
}

.landingpage .websitenavbar {
    background: #fff !important;
}

.landingpage .websitelogo {
    width: 85px;
    padding: 10px 0;
    cursor: pointer;
    margin-top: 0px;
}

.landingpage .websitemenu-icon {
    width: 43px;
    height: 32px;
    cursor: pointer;
    display: none;
}

.landingpage .website nav {
    flex: 1;
    text-align: left;
}

    .landingpage .website nav ul li .dropdown-menu li {
     margin-left: 0;
     width: 100%;
    }
    .landingpage .website nav ul li {
        list-style: none;
        display: inline-block;
        margin-left: 20px;
    }

        .landingpage .website nav ul li a {
            text-decoration: none;
            color: #000;
            font-size: 18px;
            padding: 10px 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

            

.landingpage #menuList .join {
    margin-left: 100px;
}

.landingpage #menuList .hirecaas a {
    background-color: #034ea2;
    color: #fff !important;
    padding: 16px 10px;
    box-sizing: border-box;
}

.landingpage .websiterow-slider {
    display: flex;
    align-items: center;
    background-image: url("../images/website/Slider.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 250px 0px; */
    box-sizing: border-box;
    margin-top: 0;
    position: relative;
    height: 600px;
}

/* .landingpage .col-1 {
    flex-basis: 30%;
    position: relative;
    margin-left: 700px;
} */

    .landingpage .websitehead-yellow {
        font-size: 34px;
        background-color: #ffcb05;
        font-weight: bold;
        padding: 10px 12px;
        color: #034ea2;
        margin-right: 30px;
    }

    .landingpage .websitehead-white {
        font-size: 28px;
        background-color: #fff;
        padding: 10px 12px;
        color: #000;
        margin-top: 5px;
        margin-left: 0px;
    }

.landingpage .websitecontainer-middle {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 30px;
}

.landingpage .websitewhoarewe {
    background-image: url("../images/website/building.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 120px 10px;
    box-sizing: border-box;
}

    .landingpage .websitewhoarewe h3 {
        font-size: 16px;
        font-weight: bold;
        color: #14b1e7;
        text-transform: uppercase;
    }

    .landingpage .websitewhoarewe h2 {
        font-size: 44px;
        font-weight: lighter;
        color: #ffffff;
        font-family: heading;
        margin-top: 20px;
    }

.landingpage .websiteinterim,
.landingpage .websiteproject,
.landingpage .websiteconsultant,
.landingpage .websitementor {
    min-width: 10%;
    border: 1px solid #ffffff;
    padding: 14px 10px;
    display: inline-block;
    color: #fff;
    margin-right: 20px;
    margin-top: 50px;
}

.landingpage .websiteteam {
    display: flex;
    width: 100% !important;
    margin-top: -60px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

    .landingpage .websiteteam img {
        margin: 0px 12px;
    }

    .landingpage .websiteteam .websiteleft-arrow,
    .websiteteam .websiteright-arrow {
        margin-top: -120px;
    }

.landingpage .websiteteam1 img,
.landingpage .websiteteam2 img,
.landingpage .websiteteam3 img,
.landingpage .websiteteam4 img,
.landingpage .websiteteam5 img {
    border-radius: 10px;
}

.landingpage .websiteteam1 h3,
.landingpage .websiteteam2 h3,
.landingpage .websiteteam3 h3,
.landingpage .websiteteam4 h3,
.landingpage .websiteteam5 h3 {
    font-size: 15px;
    color: #76b9df;
    font-weight: lighter;
    padding: 7px;
}

.landingpage .websiteteam1 p,
.landingpage .websiteteam2 p,
.landingpage .websiteteam3 p,
.landingpage .websiteteam4 p,
.landingpage .websiteteam5 p {
    color: #002347;
    font-size: 16px;
    font-weight: lighter;
    padding: 7px;
}

.landingpage .websiteteam1 h4,
.landingpage .websiteteam2 h4,
.landingpage .websiteteam3 h4,
.landingpage .websiteteam4 h4,
.landingpage .websiteteam5 h4 {
    color: #002347;
    font-size: 16px;
    font-weight: lighter;
    padding: 7px;
}

.landingpage .websiteservices {
    background-image: url("../images/website/Flower.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 120px 20px;
    box-sizing: border-box;
    margin-top: 30px;
}

.landingpage .websiteservices h3 {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
    }

    .landingpage .websiteservices h2 {
        font-size: 44px;
        font-family: heading;
        color: #ffffff;
        margin-top: 20px;
    }

    .landingpage .websiteservices p {
        color: #fff;
        font-size: 18px;
        line-height: 28px;
        margin-top: 20px;
    }

    .landingpage .websiteservice-block-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -80px;
}

.landingpage .websiteservice-block-2,
.landingpage .websiteservice-block-3,
.landingpage .websiteservice-block-4 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingpage .websiteservices1,
.landingpage .websiteservices2,
.landingpage .websiteservices3,
.landingpage .websiteservices4,
.landingpage .websiteservices5,
.landingpage .websiteservices6,
.landingpage .websiteservices7,
.landingpage .websiteservices8,
.landingpage .websiteservices9,
.landingpage .websiteservices10,
.landingpage .websiteservices11,
.landingpage .websiteservices12,
.landingpage .websiteservices13,
.landingpage .websiteservices14,
.landingpage .websiteservices15,
.landingpage .websiteservices16 {
    background-color: #ebebeb;
    border: 2px solid #fff;
    padding: 30px 50px;
    height: 230px;
    width: 230px;
    text-align: center;
}

.landingpage .websitewhygocaas {
    text-align: center;
    box-sizing: border-box;
    margin-top: 40px;
}

.landingpage .websitewhygocaas h2 {
        font-size: 44px;
        font-family: heading;
        color: #262626;
        margin-top: 20px;
    }

    .landingpage .websitewhygocaas p {
        color: #222222;
        font-size: 18px;
        line-height: 28px;
        margin-top: 20px;
    }

    .landingpage .websiteProcess {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
}

 .landingpage  .websiteProcess h3 {
        color: #007dc5;
        font-family: heading;
    }

    .landingpage .websiteone,
    .landingpage .websitetwo,
    .landingpage .websitethree,
    .landingpage .websitefour {
    border: 2px solid #fff;
    padding: 30px 10px;
    height: 230px;
    width: 250px;
    text-align: center;
}

.landingpage .websitebusiness-block-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
}

.landingpage .websitebusiness-block-2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingpage .websitebusinessone,
.landingpage .websitebusinesstwo,
.landingpage .websitebusinessthree,
.landingpage .websitebusinessfour,
.landingpage .websitebusinessfive,
.landingpage .websitebusinesssix {
    padding: 30px 50px;
    width: 280px;
    height: 280px;
    text-align: left;
    box-sizing: border-box;
}

.landingpage .websitebusinessone h2 {
        color: #328ece;
        font-size: 32px;
        line-height: 34px;
        text-align: left;
        font-family: heading;
        /* position: relative;
        top: 30px; */
    }

    .landingpage .websitebusinessone p {
        color: #262626;
        /* font-size: 16px;
        padding-top: 20px;
        line-height: 24px; */
        text-align: left;
        font-weight: lighter;
        /* position: relative;
        top: 10px; */
    }

    .landingpage .websitebusinesstwo {
    background-image: url("../images/website/businessone.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitebusinessthree {
    background-image: url("../images/website/businesstwo.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitebusinessfour {
    background-image: url("../images/website/businessthree.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitebusinessfive {
    background-image: url("../images/website//Businessfour.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitebusinesssix {
    background-image: url("../images/website/businessfive.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitebusinesstwo h3,
.landingpage .websitebusinessthree h3,
.landingpage .websitebusinessfour h3,
.landingpage .websitebusinessfive h3,
.landingpage  .websitebusinesssix h3 {
        font-size: 14px;
        color: #fff;
        line-height: 18px;
        text-align: left;
        font-weight: bold;
        /* position: relative;
        left: 10px;
        top: 100px; */
    }
    .landingpage .websitebusinessone,
    .landingpage  .websitebusinesstwo,
    .landingpage .websitebusinessthree,
    .landingpage .websitebusinessfour,
    .landingpage  .websitebusinessfive,
    .landingpage  .websitebusinesssix 
     {
        display: flex;
        flex-direction: column;
        justify-content: start;
        position: relative;
    }
    .landingpage .websitebusinesstwo p,
    .landingpage .websitebusinessthree p,
    .landingpage .websitebusinessfour p,
    .landingpage .websitebusinessfive p,
    .landingpage .websitebusinesssix p {
        font-size: 14px;
        color: #fff;
        /* text-align: left;
        position: relative;
        top: 100px; */
    }

    .landingpage .websitetrust-block {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
    /* padding: 40px 0px;
    margin-top: 30px; */
}

.landingpage .websitetrustone {
    padding: 20px 30px;
    width: 240px;
    height: 240px;
    text-align: left;
    box-sizing: border-box;
}

.landingpage .websitetrusttwo,
.landingpage .websitetrustthree,
.landingpage .websitetrustfour {
    padding: 30px 30px;
    width: 200px;
    height: 240px;
    text-align: left;
    box-sizing: border-box;
    display: flex;
    align-items: start;
    position: relative;
}

.landingpage .websitetrusttwo {
    background-image: url("../images/website/trusttwo.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitetrustthree {
    background-image: url("../images/website/trustthree.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitetrustfour {
    background-image: url("../images/website/trustfour.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.landingpage .websitetrustone h2 {
    color: #328ece;
    font-size: 32px;
    line-height: 34px;
    text-align: left;
    font-weight: lighter;
    position: relative;
}

.landingpage .websiteturstone p {
    color: #262626;
    font-size: 16px;
    padding-top: 20px;
    line-height: 24px;
    text-align: left;
    font-weight: lighter;
    position: relative;
}

.landingpage .websitetrusttwo h3,
.landingpage .websitetrustthree h3,
.landingpage .websitetrustfour h3 {
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    text-align: center;
    font-weight: bold;
    /* position: relative;
    left: 10px;
    top: 120px; */
}

.landingpage .websitetestimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    margin-top: 30px;
    border-bottom: 8px solid #000;
}

.landingpage .websitetestimonials-inner {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.landingpage .websitetestimonials-inner .websiteleft-arrow,
.landingpage .websitetestimonials-inner .websitetesti-text {
        padding: 0px 20px;
        margin-bottom: 0px;
    }

    .landingpage .websitetestimonials-inner .websiteclient-image {
        z-index: 4;
        margin-bottom: -12px;
    }

.landingpage .websitetesti-text h3 {
    color: #328ece;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: lighter;
    text-transform: uppercase;
}

.landingpage .websitetesti-text p {
    color: #262626;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
}

.landingpage .websitefooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    margin-top: 30px;
}

.landingpage .websitefooter-inner {
    display: flex;
    align-items: top;
    width: 80%;
}

.landingpage .websitefooter-section1,
.landingpage .websitefooter-section2,
.landingpage .websitefooter-section3,
.landingpage .websitefooter-section4,
.landingpage .websitefooter-section5 {
    width: 24%;
    margin: 0px 1%;
}

.landingpage .casssupport,
.landingpage .support,
.landingpage .about,
.landingpage .community {
    flex: 1;
    text-align: left;
}

.landingpage .casssupport ul li,
.landingpage .support ul li,
.landingpage  .about ul li,
.landingpage  .community ul li {
        list-style: none;
        display: block;
    }

    .landingpage  .casssupport ul li a,
    .landingpage  .support ul li a,
    .landingpage  .about ul li a,
    .landingpage  .community ul li a,
        .landingpage .websitefooter-section1 p {
            text-decoration: none;
            color: #262626;
            font-size: 13px;
            text-align: left;
            padding: 12px 10px;
            box-sizing: border-box;
        }

.landingpage .websitefooter-logo {
    width: 85px;
    padding: 10px 0;
    cursor: pointer;
}

.landingpage .websitefooter-section1 h3,
.landingpage .websitefooter-section2 h3,
.landingpage .websitefooter-section3 h3,
.landingpage .websitefooter-section4 h3,
.landingpage .websitefooter-section5 h3 {
    font-size: 14px;
    color: #007dc5;
    line-height: 18px;
    text-align: left;
    margin-bottom: 5px;
    position: relative;
}

.landingpage .websitecopyright {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    margin-top: 30px;
    border-top: 3px solid #969696;
    width: 100%;
}

.landingpage .websitecopyright-inner {
    display: flex;
    align-items: top;
    width: 80%;
}

.landingpage .social {
    width: 50%;
    text-align: left;
}

.landingpage .websitecopyright-text {
    width: 50%;
    text-align: right;
}

.landingpage .websitecopyright-text p {
        font-size: 12px;
    }

    .landingpage .h-32 {
    height: 32px;
}


/*added 20/11*/
.landingpage .cio-customer {
    display: flex;
    align-items: top;
    justify-content: space-between;
    margin-top: -80px;
    margin-top: 10px;
}

    .landingpage .cio-customer ul li {
        list-style-type: none;
        line-height: 24px;
        margin-top: 20px;
        border-left: 5px solid #14b1e7;
        padding-left: 6px;
    }

.landingpage .forcio,
.landingpage .forcustomer {
    width: 40%;
}

    .landingpage .forcio h3,
    .landingpage .forcustomer h3 {
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #14b1e7;
        margin-top: 20px;
        text-transform: uppercase;
    }

.landingpage .about {
    /* width: 80%; */
    text-align: center;
    padding-top: 60px;
    box-sizing: border-box;
    margin: 0 auto;
}

    .landingpage .about p {
        color: #262626;
        font-size: 18px;
        line-height: 28px;
        margin-top: 20px;
    }

    .landingpage .about h3 {
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #14b1e7;
        margin-top: 20px;
        text-transform: uppercase;
    }

    .landingpage .about h2 {
        display: block;
        font-size: 34px;
        font-family: heading;
        color: #262626;
        margin-top: 10px;
    }
/**/
.landingpage p.fs-13 {
    font-size: 16px;
}

.landingpage li:hover {
    cursor: pointer;
}

.landingpage ul li ul {
    visibility: hidden;
    opacity: 0;
    margin-top: 1rem;
    display: none;
}

.landingpage ul li:hover > ul,
ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    position: absolute;
}

.landingpage .d-flex.justify-content-between.border-green.mt-3.pt-1.ps-1.pe-1.pb-1:hover {
    background-color: #cde2ee;
}

.landingpage .right-0 {
    right: 0;
}

.landingpage .right-13 {
    right: 13px;
}

.landingpage .bottom-20 {
    bottom: 17px;
}
.landingpage .websitefooter-inner.align-center {
    align-items: center;
}
.landingpage .casssupport h3.fs-22 {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}
.landingpage .navbar {
    padding: 0px !important;
}
.landingpage .navbar-expand-lg .navbar-nav .dropdown:hover .nav-link.dropdown-toggle {
    /* color: #fff !important; */
}
.landingpage .website nav ul li .dropdown-menu li a:hover  .head-popup {
    /* color: #fff; */
}

.landingpage .website nav ul li a:hover, .landingpage .website nav ul li a.active {
    background-color: #034ea2;
    color: #fff;
    /* border-radius: 20px;
    padding: 10px 20px; */
}
.landingpage .slide-text {
    position: absolute;
    z-index: 1;
    left: 50%;
}
.landingpage .dropdown-menu[data-bs-popper]{
left: auto;
}
.landingpage .dropdown .dropdown-menu{
    margin-top: 0;
}
.landingpage .dropdown .dropdown-menu .dropdown-item:active,.landingpage .dropdown .dropdown-menu .dropdown-item:hover{
    background: #034ea2;
}
.landingpage .dropdown .dropdown-menu .dropdown-item:active .head-popup, .landingpage .dropdown .dropdown-menu .dropdown-item:hover .head-popup, .landingpage .dropdown .dropdown-menu .dropdown-item:active i, .landingpage .dropdown .dropdown-menu .dropdown-item:hover i{
    color: #fff;
}
.landingpage .slide-text{
    white-space: nowrap;
}
.landingpage, .landingpage body{
    height: 100%;
}
.landingpage .websitebusinesstwo > div,
.landingpage .websitebusinessthree > div,
.landingpage .websitebusinessfour > div,
.landingpage .websitebusinessfive > div,
.landingpage .websitebusinesssix > div 
{
    position: absolute;
 
    justify-content: end;
    top: 6pc;
    width: 100%;
    left: 0;
    padding: 0 30px;
}
.landingpage  .websitetrusttwo > div,
.landingpage  .websitetrustthree > div,
.landingpage  .websitetrustfour > div {
    position: absolute;
 
    justify-content: end;
    top: 8pc;
    width: 100%;
    left: 0;
    padding: 0 30px;
    
}


/**************************************** responsive ******************************************/
@media (min-width: 1200px)
{
    .landingpage .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
    width: auto;
    padding: 0px;
    right: 0;
}
.landingpage .slider-width{
    max-width: 1206px;
}
}
@media (min-width: 992px)
{
    .landingpage .navbar-expand-lg .navbar-nav .dropdown-menu {
    right: 0;
}
}
/*normal ipad*/
@media screen and (min-width: 1023px) and (max-width: 1024px) and (max-height: 768px){

}
/*ipad-normal landscape*/

@media only screen and (max-width: 1024px) and (min-width: 1023px) and (max-height: 1366px){

}

/*large-ipad-landscape ipad 12 pro*/

@media only screen and (max-height: 1025px) and (max-width: 1367px) and (min-width: 1365px){

}
/*large-ipad-landscape ipad 11 pro 1180 X 833*/

@media only screen and (min-height: 821px) and (max-height: 834px) and (max-width: 1250px) and (min-width: 1180px) {

}

/*ipad-normal landscape iPad Air 2019 v13.0*/

@media only screen and (min-height: 830px) and (max-height: 834px) and (max-width: 1140px) and (min-width: 1100px){

}
/*ipad-normal landscape iPad Air 4*/

@media only screen and (min-height: 800px) and (max-height: 820px) and (max-width: 1181px) and (min-width: 1110px){

}
/*ipad landscape 8th gen ipad or mini 2019 and ipad 7*/

@media only screen and (min-height: 800px) and (max-height: 811px) and (max-width: 1081px) and (min-width: 1079px){

}
@media (max-width: 1199px)
{
    .landingpage .navbar-collapse{
        margin-top: 0;
    }
    .landingpage .navbar-collapse.collapse.show{
        width: 100%; 
        background: #fff; 
        position: absolute !important; 
        top: 100%; 
        left: 0; 
        z-index: 2; 
        padding-right: 60px;
   }
   .landingpage .nav-item.dropdown .dropdown-menu{
    width: 100%;
   }
   .landingpage .navbar-collapse {
        flex-direction: column;
    }
    .landingpage .website nav ul{
        width: 100%;
    }
    .landingpage .website nav ul li {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        padding: 0px 0px;
        margin: 0;
    }
    .landingpage .navbar-nav{
        margin-bottom: 20px;
    }
    .landingpage #navbarSupportedContent .navbar-nav{
        /* height: 300px;
    overflow-y: auto; */
    width: 100%;
    }
}
/*mobile-view*/
@media only screen and (max-width: 700px) {
    .landingpage .websitecontainer {
        padding-left: 1%;
        padding-right: 1%;
    }

    .landingpage .website nav ul {
        /* width: 100%;
        background: #fff;
        position: absolute !important;
        top: 100%;
        left: 0;
        z-index: 2;
        padding-right: 60px; */
    }

    .landingpage .mob-pos-abs {
        position: absolute !important;
    }

    .landingpage .website nav ul li {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        padding: 0px 0px;
        margin: 0;
    }

        .landingpage .website nav ul li a {
            color: #000;
        }

    .landingpage .websitemenu-icon {
        display: block;
        margin-top: 20px;
        position: relative;
        left: 14.5pc;
        top: -15px;
    }

    .landingpage #menuList {
        overflow: hidden;
        transition: 0.5s;
    }

    .landingpage .websiterow-slider {
        width: 100%;
        background-position: top left;
        padding: 100px 0px !important;
        box-sizing: border-box;
        margin-top: 0px;
    }

    /* .landingpage .col-1 {
        flex-basis: 70%;
        position: relative;
        margin-left: 100px;
    } */

    .landingpage toggle-icon {
        display: block;
    }

    /* .landingpage .col-1 h2 {
        font-size: 26px;
        background-color: #ffcb05;
        font-weight: bold;
        padding: 10px 15px;
        color: #034ea2;
        margin-right: 30px;
    } */
/* 
    .landingpage .col-1 h3 {
        font-size: 24px;
        background-color: #fff;
        padding: 10px 15px;
        color: #000;
        margin-top: 5px;
        margin-left: 15px;
    } */

    .landingpage .websitewhoarewe h3 {
        font-size: 13px;
        font-weight: bold;
        color: #14b1e7;
        text-transform: uppercase;
    }

    .landingpage .websitewhoarewe h2 {
        font-size: 30px;
        font-weight: lighter;
        color: #ffffff;
        margin-top: 20px;
    }

    .landingpage .websiteinterim,
    .websiteproject,
    .websiteconsultant,
    .websitementor {
        width: 60%;
        /* display: block;
        margin: auto; */
        margin-top: 2%;
        cursor: pointer;
    }

    .landingpage .team {
        width: 60%;
        display: block;
    }

    .landingpage .team1 {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: 30px !important;
    }

    .landingpage .team2,
    .team3,
    .team4,
    .team5 {
        display: none;
    }

    .landingpage .about{
        padding-left: 0px;
    }

    .landingpage .cio-customer {
        display: block;
    }

    .landingpage .forcio,
    .landingpage .forcustomer {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

    .landingpage .websiteleft-arrow {
        position: relative;
        top: 320px;
        left: 110px;
    }

    .landingpage .websiteright-arrow {
        position: absolute;
        margin-top: 120px;
        margin-left: 350px;
        z-index: 3;
    }

    .landingpage .websiteservice-block-1 {
        display: block;
        margin-top: -80px;
        text-align: center;
    }

    .landingpage .websiteservice-block-2,
    .landingpage .websiteservice-block-3,
    .landingpage .websiteservice-block-4 {
        display: block;
        text-align: center;
    }

    .landingpage .websiteservices1,
    .landingpage .websiteservices2,
    .landingpage .websiteservices3,
    .landingpage .websiteservices4,
    .landingpage .websiteservices5,
    .landingpage .websiteservices6,
    .landingpage .websiteservices7,
    .landingpage .websiteservices8,
    .landingpage .websiteservices9,
    .landingpage .websiteservices10,
    .landingpage .websiteservices11,
    .landingpage .websiteservices12,
    .landingpage .websiteservices13,
    .landingpage .websiteservices14,
    .landingpage .websiteservices15,
    .landingpage .websiteservices16 {
        background-color: #ebebeb;
        border: 2px solid #fff;
        padding: 30px 50px;
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 80%;
    }

    .landingpage .websiteProcess {
        display: block;
        align-items: center;
        justify-content: center;
    }

    .landingpage .websiteone,
    .landingpage .websitetwo,
    .landingpage .websitethree,
    .landingpage .websitefour {
        border: 2px solid #fff;
        padding: 30px 50px;
        width: 100%;
        text-align: center;
    }

    .landingpage .websitebusiness-block-1 {
        display: block;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .landingpage .websitebusiness-block-2 {
        display: block;
        align-items: center;
        justify-content: center;
    }

    .landingpage .websitebusinessone,
    .landingpage .websitebusinesstwo,
    .landingpage .websitebusinessthree,
    .landingpage .websitebusinessfour,
    .landingpage .websitebusinessfive,
    .landingpage .websitebusinesssix {
        padding: 30px 50px;
        width: 100% !important;
        text-align: left;
    }

    .landingpage .websitebusinesstwo,
    .landingpage .websitebusinessthree,
    .landingpage .websitebusinessfour,
    .landingpage .websitebusinessfive,
    .landingpage .websitebusinesssix {
        background-size: cover;
    }

    .landingpage .websitebusinesstwo h3,
    .landingpage .websitebusinessthree h3,
    .landingpage .websitebusinessfour h3,
    .landingpage .websitebusinessfive h3,
    .landingpage .websitebusinesssix h3 {
            font-size: 24px;
            color: #fff;
            line-height: 32px;
            text-align: left;
            /* position: relative;
            left: 10px;
            top: 100px; */
        }

        .landingpage .websitetrust-block {
        display: block;
    }

    .landingpage .websitetrustone,
    .landingpage .websitetrusttwo,
    .landingpage .websitetrustthree,
    .landingpage .websitetrustfour {
        padding: 20px 30px;
        width: 100%;
    }

    .landingpage .websitetrusttwo,
    .landingpage .websitetrustthree,
    .landingpage .websitetrustfour {
        background-size: cover;
        background-position: center center;
    }

    .landingpage .websitetrusttwo h3,
    .landingpage .websitetrustthree h3,
    .landingpage .websitetrustfour h3 {
            font-size: 24px;
            line-height: 32px;
        }

        .landingpage .websitetestimonials {
        display: block;
    }

    .landingpage .websitetestimonials-inner {
        width: 70%;
        display: block;
        margin: 0 auto;
    }

    .landingpage .websitetestimonials-inner .websiteleft-arrow {
            display: none;
        }

        .landingpage .websitetesti-text h3 {
        color: #328ece;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        font-weight: lighter;
        text-transform: uppercase;
    }

    .landingpage .websitetesti-text p {
        color: #262626;
        font-size: 18px;
        line-height: 28px;
        text-align: left;
    }

    .landingpage .websitefooter-inner {
        display: block;
    }

    .landingpage .websitefooter-section1,
    .landingpage .websitefooter-section2,
    .landingpage .websitefooter-section3,
    .landingpage .websitefooter-section4,
    .landingpage .websitefooter-section5 {
        width: 80%;
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .landingpage .websitebusinesstwo h3,
    .landingpage .websitebusinessthree h3,
    .landingpage .websitebusinessfour h3,
    .landingpage .websitebusinessfive h3,
    .landingpage .websitebusinesssix h3 {
        text-align: left;
    }

    .landingpage .casssupport,
    .landingpage .support,
    .landingpage .about,
    .landingpage .community {
        display: block;
        text-align: center;
        margin: 15px 0;
    }

    .landingpage .websitefooter-section1 h3,
    .landingpage .websitefooter-section2 h3,
    .landingpage .websitefooter-section3 h3,
    .landingpage .websitefooter-section4 h3,
    .landingpage .websitefooter-section5 h3 {
        text-align: center;
    }

    .landingpage .casssupport ul li a,
    .landingpage .support ul li a,
    .landingpage .about ul li a,
    .landingpage .community ul li a,
    .landingpage .footer-section1 p {
        text-align: center;
        padding: 18px 10px;
    }
}
@media (max-width: 480px)
{
    .landingpage .slide-text {
        position: absolute;
        z-index: 1;
        left: 20%;
    }
    .landingpage .websiteinterim{
        margin-top: 30px;
    }
    .landingpage .websiteone,
    .landingpage .websitetwo,
    .landingpage .websitethree,
    .landingpage .websitefour {
        border: 2px solid #fff;
        padding: 20px 20px;
        width: 100%;
        text-align: center;
    }
    .landingpage body *{
        font-size: 12px;
    }
    .landingpage .websitebusinessone,.landingpage .websitebusinesstwo,.landingpage .websitebusinessthree,.landingpage .websitebusinessfour,.landingpage .websitebusinessfive,.landingpage .websitebusinesssix,.landingpage .websiteone,.landingpage .websitetwo,.landingpage .websitethree,.landingpage .websitefour,.landingpage .websitetrustone{
        height: 200px;
    }
    .landingpage .websitebusiness-block-1{
        margin-top: 0;
    }
    .landingpage .websitetrusttwo h3,.landingpage .websitetrustthree h3,.landingpage .websitetrustfour h3{
        font-size: 18px;
    }
    .landingpage .websitecopyright-inner {
        display: flex;
        align-items: top;
        flex-direction: column;
        width: 100%;
    }
    .landingpage .social{
        width:100%;
        text-align: center;
    }
    .landingpage .websitecopyright-text {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .landingpage .container{
        padding: 0 25px;
    }
    .landingpage .websitebusinesstwo > div,
.landingpage .websitebusinessthree > div,
.landingpage .websitebusinessfour > div,
.landingpage .websitebusinessfive > div,
.landingpage .websitebusinesssix > div 
{

    top: 3pc;
 
}
}
.landingpage .linked-inimg{
    margin-bottom: 1px;
    height: 20px;
    width: 20px;
  }
