/* custom css */
/* Common css vv */
/* text alignment */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* no margin no padding */

.padding_no {
  padding: 0px !important;
}

.margin_no {
  margin: 0px !important;
}

.style-type-none {
  list-style-type: none;
}

.inline-block {
  display: inline-block;
}

/* margin css 100px*/

.mt_0 {
  margin-top: 0px;
}

.mt_5 {
  margin-top: 5px;
}
.mt_8 {
  margin-top: 8px;
}
.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_45 {
  margin-top: 45px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_65 {
  margin-top: 65px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_85 {
  margin-top: 85px;
}

.mt_90 {
  margin-top: 90px;
}

.mt_95 {
  margin-top: 95px;
}

.mt_100 {
  margin-top: 100px;
}

.mb_0 {
  margin-bottom: 0px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_45 {
  margin-bottom: 45px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_55 {
  margin-bottom: 55px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_65 {
  margin-bottom: 65px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_75 {
  margin-bottom: 75px;
}

.mb_80 {
  margin-bottom: 80px;
}

.mb_85 {
  margin-bottom: 85px;
}

.mb_90 {
  margin-bottom: 90px;
}

.mb_95 {
  margin-bottom: 95px;
}

.mb_100 {
  margin-bottom: 100px;
}

.ml_0 {
  margin-left: 0px;
}

.ml_15 {
  margin-left: 5px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_30 {
  margin-left: 30px;
}

.ml_35 {
  margin-left: 35px;
}

.ml_40 {
  margin-left: 40px;
}

.ml_45 {
  margin-left: 45px;
}

.ml_50 {
  margin-left: 50px;
}

.ml_55 {
  margin-left: 55px;
}

.ml_60 {
  margin-left: 60px;
}

.ml_65 {
  margin-left: 65px;
}

.ml_70 {
  margin-left: 70px;
}

.ml_75 {
  margin-left: 75px;
}

.ml_80 {
  margin-left: 80px;
}

.ml_85 {
  margin-left: 85px;
}

.ml_90 {
  margin-left: 90px;
}

.ml_95 {
  margin-left: 95px;
}

.ml_100 {
  margin-left: 100px;
}

.ml_200 {
  margin-left: 200px;
}

.mr_0 {
  margin-right: 0px;
}

.mr_5 {
  margin-right: 5px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_14 {
  margin-right: 14px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 20px;
}

.mr_25 {
  margin-right: 25px;
}

.mr_30 {
  margin-right: 30px;
}

.mr_35 {
  margin-right: 35px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_45 {
  margin-right: 45px;
}

.mr_50 {
  margin-right: 50px;
}

.mr_55 {
  margin-right: 55px;
}

.mr_60 {
  margin-right: 60px;
}

.mr_65 {
  margin-right: 65px;
}

.mr_70 {
  margin-right: 70px;
}

.mr_75 {
  margin-right: 75px;
}

.mr_80 {
  margin-right: 80px;
}

.mr_85 {
  margin-right: 85px;
}

.mr_90 {
  margin-right: 90px;
}

.mr_95 {
  margin-right: 95px;
}

.mr_100 {
  margin-right: 100px;
}

.mr_140 {
  margin-right: 140px;
}

/* padding css 100px*/

.pt_0 {
  padding-top: 0px;
}

.pt_5 {
  padding-top: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_25 {
  padding-top: 25px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_35 {
  padding-top: 35px;
}

.pt_40 {
  padding-top: 40px;
}

.pt_45 {
  padding-top: 45px;
}

.pt_50 {
  padding-top: 50px;
}

.pt_55 {
  padding-top: 55px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_65 {
  padding-top: 65px;
}

.pt_70 {
  padding-top: 70px;
}

.pt_75 {
  padding-top: 75px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_85 {
  padding-top: 85px;
}

.pt_90 {
  padding-top: 90px;
}

.pt_95 {
  padding-top: 95px;
}

.pt_100 {
  padding-top: 100px;
}

.pb_0 {
  padding-bottom: 0px;
}

.pb_5 {
  padding-bottom: 5px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_15 {
  padding-bottom: 15px;
}

.pb_20 {
  padding-bottom: 20px;
}

.pb_25 {
  padding-bottom: 25px;
}

.pb_30 {
  padding-bottom: 30px;
}

.pb_35 {
  padding-bottom: 35px;
}

.pb_40 {
  padding-bottom: 40px;
}

.pb_45 {
  padding-bottom: 45px;
}

.pb_50 {
  padding-bottom: 50px;
}

.pb_55 {
  padding-bottom: 55px;
}

.pb_60 {
  padding-bottom: 60px;
}

.pb_65 {
  padding-bottom: 65px;
}

.pb_70 {
  padding-bottom: 70px;
}

.pb_75 {
  padding-bottom: 75px;
}

.pb_80 {
  padding-bottom: 80px;
}

.pb_85 {
  padding-bottom: 85px;
}

.pb_90 {
  padding-bottom: 90px;
}

.pb_95 {
  padding-bottom: 95px;
}

.pb_100 {
  padding-bottom: 100px;
}

.pl_0 {
  padding-left: 0px;
}

.pl_5 {
  padding-left: 5px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_15 {
  padding-left: 15px;
}

.pl_20 {
  padding-left: 20px;
}

.pl_25 {
  padding-left: 25px;
}

.pl_30 {
  padding-left: 30px;
}

.pl_35 {
  padding-left: 35px;
}

.pl_40 {
  padding-left: 40px;
}

.pl_45 {
  padding-left: 45px;
}

.pl_50 {
  padding-left: 50px;
}

.pl_55 {
  padding-left: 55px;
}

.pl_60 {
  padding-left: 60px;
}

.pl_65 {
  padding-left: 65px;
}

.pl_70 {
  padding-left: 70px;
}

.pl_75 {
  padding-left: 75px;
}

.pl_80 {
  padding-left: 80px;
}

.pl_85 {
  padding-left: 85px;
}

.pl_90 {
  padding-left: 90px;
}

.pl_95 {
  padding-left: 95px;
}

.pl_100 {
  padding-left: 100px;
}

.pr_0 {
  padding-right: 0px;
}

.pr_5 {
  padding-right: 5px;
}

.pr_10 {
  padding-right: 10px;
}

.pr_15 {
  padding-right: 15px;
}

.pr_20 {
  padding-right: 20px;
}

.pr_25 {
  padding-right: 25px !important;
}

.pr_30 {
  padding-right: 30px;
}

.pr_35 {
  padding-right: 35px;
}

.pr_40 {
  padding-right: 40px;
}

.pr_45 {
  padding-right: 45px;
}

.pr_50 {
  padding-right: 50px;
}

.pr_55 {
  padding-right: 55px;
}

.pr_60 {
  padding-right: 60px;
}

.pr_65 {
  padding-right: 65px;
}

.pr_70 {
  padding-right: 70px;
}

.pr_75 {
  padding-right: 75px;
}

.pr_80 {
  padding-right: 80px;
}

.pr_85 {
  padding-right: 85px;
}

.pr_90 {
  padding-right: 90px;
}

.pr_95 {
  padding-right: 95px;
}

.pr_100 {
  padding-right: 100px;
}

.bold {
  font-weight: bold;
}

.Valign_bottom {
  vertical-align: bottom;
}

.Valign_top {
  vertical-align: top;
}

.Valign_middle {
  vertical-align: middle;
}

.ml-auto {
  margin-left: auto;
}

.ht-50 {
  height: 50px;
}

.ht-100 {
  height: 100px;
}

/* Common css vv */

/* custom css */
/* vetivel started */
.word-dotted {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-inverse-secondary {
  background-color: rgba(5, 195, 251, 0.2) !important;
  background-image: none;
  border-color: rgba(5, 195, 251, 0);
}

.TextBlue {
  color: #1f3bb3;
}

body * .brand-logo *,
.brand-logo {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 26px !important;
}

.auth .brand-logo {
  margin-bottom: 1rem;
}

.auth .auth-form-light {
  border: 1px solid #ccc;
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
}

.sidebar-icon-only .navbar .navbar-menu-wrapper {
  box-shadow: 2px 1px 6px 0 rgb(32 33 36 / 28%);
}

.sidebar-icon-only .sidebar {
  /* background: #203b68; */
  background: #0a66c2;
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  border: 2px solid #ccc;
}

.with-welcome-text .navbar.headerLight .welcome-text,
.with-welcome-text .navbar.headerLight .welcome-sub-text {
  display: block !important;
}

.navbar .navbar-brand-wrapper,
.navbar .navbar-menu-wrapper,
.navbar.headerLight .navbar-menu-wrapper,
.navbar.headerLight .navbar-brand-wrapper {
  height: 55px;
}

.page-body-wrapper {
  min-height: calc(100vh - 0px);
  padding-top: 50px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
  top: 50px;
}

#UserDropdown {
  border-radius: 50%;
  padding: 0;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

#UserDropdown i {
  position: relative;
  top: 0px;
}

html,
body,
.sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar .nav .nav-item .nav-link .menu-title,
.sidebar .nav.sub-menu .nav-item .nav-link,
.home-tab .nav-tabs .nav-item .nav-link,
.home-tab .statistics-details .statistics-title,
.home-tab .statistics-details .statistics-title,
.home-tab .btn,
.home-tab .fc button,
.fc .home-tab button,
.home-tab .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .home-tab .ajax-file-upload,
.home-tab .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .home-tab .swal2-styled,
.home-tab .wizard > .actions a,
.wizard > .actions .home-tab a,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .form-control,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .asColorPicker-input,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  select,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  input[type="text"],
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  select,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  input[type="number"],
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .select2-selection--single,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .select2-search__field,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .typeahead,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .tt-query,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .navbar-date-picker .tt-hint,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown-bordered,
.dropdown .dropdown-menu .dropdown-item,
p,
body *,
#performance-line-legend .chartjs-legend ul li,
.text-small,
.form-control,
.asColorPicker-input,
.dataTables_wrapper select,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint,
.form-group label,
.btn.btn-sm,
.btn-group-sm > .btn,
.fc .btn-group-sm > button,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
.wizard > .actions .btn-group-sm > a,
.fc button,
.ajax-upload-dragdrop .btn-sm.ajax-file-upload,
.ajax-upload-dragdrop .fc button.ajax-file-upload,
.fc .ajax-upload-dragdrop button.ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-sm.swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc button.swal2-styled,
.fc .swal2-modal .swal2-buttonswrapper button.swal2-styled,
.wizard > .actions a.btn-sm,
.form-check .form-check-label {
  font-size: 14px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .small-text,
.home-tab .chartjs-legend ul li,
.table td,
.jsgrid .jsgrid-table td {
  font-size: 12px;
}

.home-tab .chartjs-legend ul li {
  margin-right: 3%;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .welcome-text,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .welcome-text span {
  font-size: 28px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .welcome-text span {
  color: #1a2f89;
}

.navbar-brand-wrapper {
  pointer-events: none;
}

.card-primary.card-outline {
  border-top: 3px solid #007bff;
}

.card.card-rounded {
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
}

.sidebar-icon-only .sidebar .nav .nav-item.active .nav-link i.menu-icon {
  color: #fff;
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon,
.sidebar .nav .nav-item .nav-link i,
.sidebar .nav .nav-item .nav-link i,
.sidebar .nav .nav-item:hover > .nav-link i,
.sidebar .nav .nav-item.active > .nav-link i {
  color: #fff;
}

.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link,
.sidebar .nav:not(.sub-menu) > .nav-item:hover[aria-expanded="true"] {
  /* background: linear-gradient(50deg, #bd7c42, #5a635e); */
  background: linear-gradient(50deg, #f4f5f7, #f4f5f7);
}

li#cio_reg:hover {
  background: unset !important;
}

.sidebar .nav:not(.sub-menu) > .nav-item.active .nav-link {
  border-radius: 0;
  color: #007dc5;
}

.sidebar .nav .nav-item.active > .nav-link {
  /* background: #9e9e9e; */
  /* background: linear-gradient(50deg, #bd7c42, #5a635e); */
  background: linear-gradient(50deg, #f4f5f7, #f4f5f7);
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link,
.sidebar .nav:not(.sub-menu) > .nav-item:hover[aria-expanded="true"],
.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link i {
  background: #fff;
  color: #0a66c2;
}
li.nav-item.title-card a.nav-link.text-center.pl_2.br-btm:hover {
  background: unset;
}

li.nav-item.title-card:hover {
  background: unset !important;
}

.menu-title {
  font-weight: bold;
}

.infinite-spin,
#settings-trigger i {
  animation-name: none;
}

span.menu-title2.sidebar-title.main-title {
  font-family: calibri;
  font-size: 16px;
}

.sidebar .nav:not(.sub-menu) > .nav-item > .nav-link[aria-expanded="true"] {
  background: transparent;
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 12px 26px;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 10px 31px;
}

.content-wrapper {
  background-color: #fff;
}

button.btn.btn-primary.add-pi.float-right.btn-rounded.btn-icon {
  position: relative;
  bottom: 15px;
  padding: 8px !important;
}

a.nav-link.text-center.pl_2.br-btm {
  padding-left: 1px !important;
  border-bottom: 1px solid;
  border-radius: 0;
  width: 75%;
  margin-bottom: 15px !important;
  margin-left: 1rem !important;
}

img.img-responsive.pi-image {
  margin: 0 auto;
  width: 250px;
  position: relative;
  top: 5em;
}

a.btn-primary.add-pi.float-right.btn-rounded.btn-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 15px;
}

.modal-title {
  font-weight: bold;
}

.sidebar-icon-only .sidebar .nav .nav-item.active .nav-link:before {
  background: unset;
}

.main-panel {
  min-height: calc(100vh - 55px);
}

.timesheert-button-group .btn,
.time-entry-week .btn {
  padding: 0.5rem 1.5rem;
  margin-right: 15px;
}

.timesheert-button-group .btn-light:hover,
.timesheert-button-group .btn-light:focus,
.time-entry-week .btn-light:hover,
.time-entry-week .btn-light:focus,
.btn.btn-light {
  color: #1e283d;
}

.form-group label {
  font-size: 1rem;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day {
  font-size: 16px;
  padding: 0.5rem 0;
  color: #1f1f1f;
}

.template-demo-Department th:nth-last-child(1) {
  width: 20%;
}

.datedrop {
  padding: 5px;
  border: 1px solid #999;
}

.prvweek,
.nextweek {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing,
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
  width: 230px;
}

.modal .modal-dialog .modal-content .modal-header .close span {
  line-height: 1;
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 5px 26px;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
}

.Timesheet-add input {
  width: 100px;
}

.Timesheet-add .form-select {
  width: 150px;
}

.Timesheet-add textarea {
  width: 300px;
}

.modal-body {
  overflow-y: auto;
  text-align: left;
}

.modal .modal-dialog {
  margin-top: 40px;
}

legend .form-check .form-check-label {
  color: #1a2f89;
  font-weight: bold;
  font-size: 15px;
}

.sidebar .nav .nav-item:nth-last-child(1) .collapse {
  /* height: 250px; */
  overflow-y: auto;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #dacdf7;
}

select.form-control,
select.asColorPicker-input,
.dataTables_wrapper select,
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.select2-container--default select.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
  background-size: 20px;
}

.switch-custom .form-check-label {
  margin-left: 0rem;
}

.switch-custom .form-check-input {
  margin-left: 0em;
  margin-right: 0.75em;
}

h4.card-title {
  border-bottom: 1px solid;
  color: #1e3bb3 !important;
  padding-bottom: 15px;
}

.border_bottom {
  margin-top: 15px;
  border-bottom: 1px solid #dee2e6 !important;
  margin-bottom: 20px;
}

.calendar-body {
  height: 200px;
  overflow-y: auto;
}

.card .calendar-body {
  padding: 0.7rem 0.7rem;
}

.botton-space {
  margin-bottom: 20px;
}

.mr-auto {
  margin-right: auto;
}

.brand_logo {
  height: 30px !important;
}

.row-disabled {
  opacity: 0.5;
}

@media (min-width: 576px) {
  .modal-dialog.modal-md {
    max-width: 600px;
    margin: 30px auto;
  }
}

/* vetivel End */
/*prasanth css*/
.white-space-normal {
  white-space: normal !important;
}

textarea.form-control.ht-100 {
  height: 100px;
}

.link {
  cursor: pointer;
  color: #0d6efd;
}

.card-header {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  background-color: #fff;
}

.card-bordered {
  border: 6px solid #e5e8eb;
}

.card-bordered .card-header .navbar,
.card-bordered .card-header {
  background-color: #d8f9f5 !important;
}

select.form-control {
  color: #212529;
}

table td.whitespace-wrap {
  white-space: normal;
}

.btn-secondary,
.btn-primary,
.btn-danger {
  color: #ffffff !important;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 15px 26px;
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 5px 26px;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 5px 31px;
}

.timesheetadd .form-group {
  margin-bottom: 10px;
}

.timesheetadd .col-form-label {
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.boeder-left {
  border-left: 1px solid;
}
.ajax-file-upload-container {
  min-height: auto;
}

.ht-30pc {
  height: 30pc;
}

fieldset {
  padding: 10px;
  border: 3px solid #007bff;
}

legend {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  color: #2f4e71;
  width: auto;
  padding: 0;
  border-bottom: none;
  padding-right: 5px;
  float: none;
  margin: 0;
  padding: 0;
}

legend span {
  font-size: 1em !important;
  padding-right: 5px;
  padding-top: 5px;
}

.card-primary.card-outline {
  border-top: 3px solid #007bff;
}

.form-group label {
  font-weight: 500;
  font-size: 1.1rem;
  font-family: "Barlow-Regular";
}

span.icon-calendar.input-group-text.calendar-icon {
  padding-top: 12px;
  padding-bottom: 12px;
}

textarea.form-control {
  height: auto;
}

.table thead th,
.jsgrid .jsgrid-table thead th,
.table td,
.jsgrid .jsgrid-table td {
  font-size: 1rem;
}

.form-timesheet {
  position: relative;
}

.removenewtimerow {
  position: absolute;
  right: 0;
  top: -20px;
}

.mdi-close-circle-outline {
  color: red;
}

/*prasanth*/
/*praveen*/
.hide {
  display: none;
}

.panel1,
.panel2,
.panel3,
.panel4,
.panel5 {
  background-color: #d9e3eb;
  padding: 20px 50px;
  border-radius: 20px;
  text-decoration: none;
}

.panel1:hover,
.panel2:hover,
.panel3:hover,
.panel4:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

a.panel1.active,
a.panel2.active,
a.panel3.active,
a.panel4.active {
  border: 2px solid #4c8ac0;
}

a.dropdown-item.preview-item {
  cursor: pointer;
}

.table.select-table th,
.jsgrid .select-table.jsgrid-table th {
  color: #ffffff;
  background-color: steelblue;
  padding-left: 15px;
}

.flot-chart-container {
  width: 50%;
}

.btn-info {
  background: #4c8ac0;
  color: #ffffff !important;
}

.content-wrapper {
  padding: 1.5rem 1.187rem 1.5rem 1.5rem;
}

.filterable {
  margin-top: 15px;
}

.filterable .filters input[disabled] {
  background-color: transparent;
  border: none;
  cursor: auto;
  box-shadow: none;
  padding: 0;
  height: auto;
  font-weight: bold;
  width: 130px;
}

i.menu-icon.mdi.mdi-arrow-down-drop-circle-outline {
  line-height: 0.8;
}

input.form-control.filter_box.descr-box {
  width: 450px;
}

input.form-control.filter_box {
  width: 170px;
}

.filterable .filters input[disabled]::-webkit-input-placeholder {
  color: #333;
}

.filterable .filters input[disabled]::-moz-placeholder {
  color: #333;
}

.filterable .filters input[disabled]:-ms-input-placeholder {
  color: #333;
}

.float-right {
  float: right;
}

.panel-heading.table-head {
  background-color: steelblue;
  padding: 0px 10px;
  color: white;
}

button.btn.btn-default.btn-xs.btn-filter {
  margin-bottom: 0;
  background-color: #4682b4;
  border: 0;
  color: white;
}

span.text-muted.text-center.text-sm-left.d-block.d-sm-inline-block a {
  color: #4c8ac0;
}

.table-responsive {
  overflow-x: auto;
  display: block;
}

tr.filters {
  background-color: aliceblue;
}

.reports-page table.table {
  border: 1px solid #d3d3d3;
}

table.table-condensed td {
  cursor: pointer;
}

.input-group.input-daterange.d-flex.align-items-center.col-lg-6 {
  width: 50%;
}

.multiselect {
  text-align: left;
  padding-right: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiselect .caret {
  right: 12px;
  top: 45%;
  position: absolute;
}

.multiselect-container.dropdown-menu {
  min-width: 0px;
}

.multiselect-container > li > a > label {
  white-space: normal;
  padding: 5px 15px 5px 35px;
}

.multiselect-container > li > a > label > input[type="checkbox"] {
  margin-top: 3px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.multiselect {
  width: 280px;
  font-size: 15px;
  padding-bottom: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  outline: none;
  padding-top: 4px;
  height: 40px;
}

.multiselect:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.multiselect.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid transparent;
}

.multiselect > .title {
  cursor: pointer;
  height: 16px;
  padding: 6px;
}

.multiselect > .title > .text {
  max-width: 100%;
  max-height: 101px;
  display: block;
  float: left;
  overflow: hidden;
  line-height: 1.3em;
}

.multiselect > .title > .expand-icon,
.multiselect > .title > .close-icon {
  float: right;
  border-radius: 50%;
  padding: 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 700;
  transition: 0.2s;
  display: none;
}

.multiselect.selection > .title > .expand-icon {
  display: none;
}

.multiselect > .title > .expand-icon,
.multiselect.selection > .title > .close-icon {
  display: block;
}

.multiselect > .title > .close-icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: rgb(203, 32, 32);
  color: #fff;
}

.multiselect > .container {
  max-height: 200px;
  overflow: auto;
  margin-top: 4px;
  margin-left: -1px;
  width: 170px;
  transition: 0.2s;
  position: absolute;
  z-index: 99;
  background: #fff;
}

.multiselect.active > .container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 0;
}

.multiselect:hover > .container {
  border-top-color: rgba(0, 0, 0, 0.3);
}

.multiselect.active:hover > .container {
  border-color: rgba(0, 0, 0, 0.3);
}

.multiselect > .container > option {
  display: none;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.multiselect > .container > option.selected {
  background: rgb(122, 175, 233);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #fff;
}

.ml-10 {
  margin-left: 10px;
}

.vl {
  border-right: 3px solid #d3d3d3;
  height: 180px;
}

.form-check {
  cursor: pointer;
}

.multiselect > .container > option:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
}

.multiselect.active > .container > option {
  display: block;
}

p.mb-0.profile-info {
  font-size: 14px;
  color: #000;
}

.flex-row {
  display: flex;
}

a.edit-profile i {
  color: #007dc5 !important;
}

.img-lg {
  width: 150px;
  height: 150px;
}

h4.profile-name {
  color: #007dc5;
  font-size: 18px;
  font-family: "Barlow-Medium";
  white-space: nowrap;
}

a.btn.btn-warning.btn-md.previous-btn {
  /* background-color: #8a8a8a; */
  /* background-color: #007dc5; */
  background-color: #8a8a8a;
  border: 1px solid #8a8a8a;
}

.cardPad.card-body.border.border-radius {
  border-top-left-radius: 10px;
  border-top: 7px solid #007dc5 !important;
  border-top-right-radius: 10px;
}

button.btn.industry-list {
  color: #000;
  border: 1px solid #007dc5;
  padding: 2px 20px;
  font-size: 14px;
}

.form-group label {
  /* font-size: 0.812rem; */
  line-height: 1.4rem;
  vertical-align: top;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:before {
  background: #4c8ac0;
  border-width: 0;
}

.form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #4c8ac0;
  border-width: 0;
  border-color: #4c8ac0;
}

a#messageDropdown {
  height: 37px;
  padding-top: 11px;
}

.form-check .form-check-label input[type="radio"] + .input-helper:before {
  border: solid #4c8ac0;
}

fieldset {
  padding: 10px;
  border: 1px solid #d3d3d3;
}

legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  color: #2f4e71;
  width: auto;
  padding: 0;
  border-bottom: none;
  padding-right: 5px;
  float: none;
  margin: 0;
  padding: 0 8px;
}

td.total-section,
.total-section,
th.total-section {
  background-color: aliceblue;
  font-weight: bold;
}

/* 01-7-2022 */
.sidebar-icon-only .navbar .navbar-brand-wrapper {
  /* box-shadow: 2px 1px 6px 0 rgb(32 33 36 / 28%);
  border-left: 1px solid #dee2e6; */
}

.navbar .navbar-menu-wrapper {
  padding: 0.5rem 1.187rem 0.5rem 1.5rem;
}

.h_500 {
  min-height: 480px;
}

.search-box-container {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.25rem !important;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}

/* Project request */
article {
  position: relative;
}

.brows-job-list {
  display: flex;
  width: 100%;
  clear: both;
  padding: 30px 20px 20px 20px;
  transition: 0.4s;
  margin-top: 25px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgb(88 96 109 / 14%);
  -webkit-box-shadow: 0 0 10px 0 rgb(88 96 109 / 14%);
  -moz-box-shadow: 0 0 10px 0 rgba(88, 96, 109, 0.14);
  align-items: center;
}

.brows-job-half {
  flex: 1;
}

.item-fl-box {
  width: 100%;
  display: flex;
  align-items: center;
}

.item-click .brows-job-position {
  padding: 0;
}

.item-fl-box .brows-job-company-img {
  margin: 0;
  margin-right: 15px;
  display: table;
}

.brows-job-company-img {
  width: 75px;
  margin: 0 auto;
  margin: 10px auto;
  height: 75px;
  display: inline-block;
  background: #f4f5f7;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 75px;
}

.brows-job-company-img img {
  margin: 0 auto;
  vertical-align: middle;
  display: inline-block;
}

.brows-job-position h3 {
  font-size: 17px;
  padding: 0;
  margin-bottom: 5px;
  line-height: 1.4;
  position: relative;
  width: auto;
  display: inline-block;
}

.item-click .brows-job-position p {
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
  line-height: 23px;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
}

.njsb-location {
  margin-top: 6px;
  color: #717f86;
}

.theme-cl,
.input-with-icon .theme-cl {
  color: #03a84e;
}

.brows-job-position .job-type {
  padding: 3px 12px;
  color: #fff;
  margin-left: 10px;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 13px;
}

.brows-job-position p span {
  margin-top: 12px;
}

html body .cl-success {
  color: #11b719 !important;
}

html body .bg-trans-success {
  background: rgba(15, 183, 107, 0.12) !important;
}

.njsb-location i {
  margin-right: 5px;
}

a,
a:active,
a:focus,
a:hover {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.jb-approved a {
  color: #272f46;
  margin-top: 0;
  font-weight: 700;
}

.brows-job-list:hover .njsb-link .btn.btn-default,
.brows-job-list:focus .njsb-link .btn.btn-default {
  background: #11b719;
  color: #fff;
}

.njsb-link .btn.btn-default {
  border: none;
  background: #eaebed;
  color: #677897;
  border-radius: 50px;
  text-shadow: none;
  transition: all ease 0.4s;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 8px rgb(102 103 107 / 15%);
}

.brows-job-list:hover {
  border-left: 4px solid #03a84e;
}

.brows-job-list:hover,
.brows-job-list:focus {
  -webkit-box-shadow: 0 0 10px 0 rgb(88 96 109 / 14%);
  -moz-box-shadow: 0 0 10px 0 rgba(88, 96, 109, 0.14);
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}

.brows-job-company-img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid #ccc;
  padding: 0px 5px;
  box-shadow: 0 2px 8px rgb(102 103 107 / 15%);
}

.tg-themetag {
  top: 7px;
  left: -5px;
  z-index: 2;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  position: absolute;
  background: #ff526c;
  padding: 5px 3px 3px 10px;
  text-transform: uppercase;
}

.tg-featuretag:before {
  top: 0;
  left: 100%;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 10px solid #ff526c;
}

.tg-featuretag:before,
.tg-featuretag:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
}

.tg-featuretag:after {
  top: 100%;
  left: 0;
  border-top: 5px solid #eb344f;
  border-left: 5px solid transparent;
}

.tg-featuretag:before,
.tg-featuretag:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
}

/* Job Datils */
.inner-header-page {
  border-bottom: 2px solid #03a84e;
}

.inner-header-page {
  padding: 20px 0 20px;
  text-align: left;
  background: #f5f6f7;
  border-bottom: 2px solid #11b719;
}

.left-side-container {
  display: table;
  width: 100%;
}

.inner-header-page .freelance-image {
  height: 140px;
  flex: 0 0 140px;
  margin-right: 35px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 10%);
  display: inline-block;
  padding: 0 20px;
  line-height: 140px;
  float: left;
}

.inner-header-page .freelance-image img {
  max-width: 120px;
  margin-top: 10px;
}

.header-details {
  margin-top: 20px;
}

.header-details h4 {
  margin: 0 0 5px;
  font-size: 24px;
}

.inner-header-page .header-details ul {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 24px;
  margin-bottom: -7px;
}

.inner-header-page .header-details li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 12px;
}

html body .br-gary {
  border-color: #e2e5e8 !important;
}

.bl-1 {
  border-left: 1px solid #eaeff5 !important;
}

.right-side-detail ul {
  padding: 0;
  margin: 0;
}

.right-side-detail ul li {
  list-style: none;
  padding: 5px 0;
  display: flex;
}

.right-side-detail ul li .detail-info {
  width: 30%;
  font-weight: 500;
  display: inline-block;
}

.right-side-detail ul li span {
  width: 70%;
}

.right-side-detail ul.social-info li {
  display: inline-block;
  margin: 5px;
}

.right-side-detail ul.social-info li a {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #e3e8ec;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
}

.inner-header-page .header-details li .star-rating {
  position: relative;
  top: 0;
}

.star-rating::before {
  content: attr(data-rating);
  float: left;
  background-color: #febe42;
  color: #fff;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  position: relative;
  top: 1px;
  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 7px;
}

.inner-header-page .header-details li .star-rating .fa.fill {
  color: #febe42;
}

.header-details h4 {
  margin: 0 0 5px;
  font-size: 24px;
}

.header-details a {
  color: #272f46;
}

.verified-action {
  position: relative;
  height: 26px;
  display: flex;
  top: -1px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background-color: #30ab4a;
  text-align: center;
  z-index: 10;
  font-weight: 500;
  border-radius: 4px;
  padding: 0 8px 0 0;
  margin: 0;
  overflow: hidden;
  padding-left: 34px;
  line-height: 27px;
}

.verified-action:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  height: 26px;
  width: 26px;
  display: inline-block;
  background-color: #38b653;
}

.container-detail-box {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #eaeff5;
}

.apply-job-header {
  margin-bottom: 40px;
}

.apply-job-detail {
  margin-bottom: 30px;
}

.apply-job-header h4 {
  font-size: 22px;
}

.apply-job-header a {
  margin-right: 15px;
}

.apply-job-header a i,
.apply-job-header span i {
  margin-right: 5px;
}

ul.skills li {
  display: inline-block;
  list-style: none;
  padding: 3px 15px;
  border: 1px solid #b9c5ce;
  border-radius: 50px;
  margin: 5px;
  font-weight: 500;
  color: #657180;
}

ul.skills,
ul.job-requirements {
  margin: 15px 0;
  padding: 0;
  list-style: none;
}

.apply-job-detail h5 {
  font-size: 18px;
}

.job-detail-statistic {
  margin-bottom: 15px;
  margin-top: 15px;
  border: 1px solid #e9eef3;
  padding: 15px;
  -webkit-box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 9%);
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 9%);
  background: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

@media (min-width: 768px) {
  .job-detail-statistic .statistic-item {
    width: 33%;
    float: left;
  }

  .flex-middle-sm {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
  }
}

.job-detail-statistic .statistic-item {
  margin: 0;
  border: none;
  border-right: 1px solid #e9eef3;
  padding-right: 20px;
  padding-left: 20px;
}

.job-detail-statistic .statistic-item:last-child {
  border: none;
}

.container-detail-box {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #eaeff5;
}

h4 {
  font-size: 16px;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-initialized .slick-slide {
  display: block;
}

.freelance-box {
  padding: 0px;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

/*------------ Slick Slider -------------------*/
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

html body .bg-danger {
  background: #f21136 !important;
}

.popular-jobs-status {
  position: absolute;
  left: 32px;
  top: 25px;
  color: #fff;
  text-transform: capitalize;
  padding: 4px 18px;
  border-radius: 50px;
  font-weight: 500;
}

.popular-jobs-box {
  text-align: center;
  padding: 50px 20px 15px;
}

.popular-jobs-container {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  transition: 0.4s;
  border: 1px solid #eaeff5;
}

.flc-rate {
  position: absolute;
  right: 32px;
  top: 20px;
  font-size: 18px;
  font-weight: 500;
}

.popular-jobs-box-detail h4 {
  margin-bottom: 4px;
  font-size: 20px;
  text-transform: capitalize;
}

.popular-jobs-box-detail .desination,
.popular-jobs-box-detail .location {
  font-weight: 500;
  font-size: 15px;
  display: block;
  color: #677484;
}

.popular-jobs-box-extra ul {
  margin: 15px 0;
  padding: 0;
}

.popular-jobs-box-extra ul li {
  display: inline-block;
  list-style: none;
  padding: 3px 15px;
  border: 1px solid #b9c5ce;
  border-radius: 50px;
  margin: 5px;
  font-weight: 500;
  color: #657180;
}

.popular-jobs-box-extra ul li.more-skill {
  color: #fff;
  border-color: #1194f7;
}

.popular-jobs-box-extra ul li {
  display: inline-block;
  list-style: none;
  padding: 3px 15px;
  border: 1px solid #b9c5ce;
  border-radius: 50px;
  margin: 5px;
  font-weight: 500;
  color: #657180;
}

html body .bg-primary {
  background: #1194f7 !important;
}

.btn.btn-popular-jobs {
  padding: 17px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  background: #f2f3f5;
}

.btn.btn-popular-jobs:hover,
.btn.btn-popular-jobs:focus {
  background: #01c73d;
  color: #fff;
}

/* Job list */
.grid-view.brows-job-list {
  position: relative;
  text-align: center;
  padding-bottom: 0;
  margin-top: 30px;
  display: table;
}

.brows-job-list {
  display: flex;
  width: 100%;
  clear: both;
  padding: 20px;
  transition: 0.4s;
  margin-top: 25px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgb(88 96 109 / 14%);
  -webkit-box-shadow: 0 0 10px 0 rgb(88 96 109 / 14%);
  -moz-box-shadow: 0 0 10px 0 rgba(88, 96, 109, 0.14);
  align-items: center;
}

.grid-view .brows-job-company-img {
  width: 80px;
  margin: 10px auto;
  height: 80px;
  display: flex;
  border: 1px solid #e4e4e4;
  background: #fff;
  align-items: center;
  border-radius: 50%;
  line-height: 80px;
}

.grid-view .brows-job-company-img img {
  margin: 0 auto;
  vertical-align: middle;
  display: inline-block;
  max-width: 50px;
  line-height: 50px;
}

.brows-job-position {
  padding: 0 15px 15px;
}

.brows-job-position h3 {
  font-size: 17px;
  padding: 0;
  margin-bottom: 5px;
  line-height: 1.4;
  position: relative;
  width: auto;
  display: inline-block;
}

span.job-num {
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: capitalize;
  color: #8da2b3;
  background: #f3f6fb;
}

.grid-view .brows-job-type span {
  position: absolute;
  padding: 4px 15px;
  top: 10px;
  right: 0;
  line-height: 1.4;
  font-size: 12px;
  border-radius: 2px 0 0 2px;
}

.full-time {
  background: rgba(3, 165, 4, 0.1);
  color: #03a504;
}

.part-time {
  background: rgba(255, 2, 2, 0.1);
  color: #ff0000;
}

.grid-view-caption {
  margin: 2.5em 0 0;
  padding: 0;
  border-top: 1px solid #eaeff5;
  display: flex;
  width: 100%;
}

.grid-view-caption li:first-child {
  border-right: 1px solid #eaeff5;
}

.grid-view-caption li {
  display: inline-block;
  float: left;
  width: 50%;
  padding: 10px 0;
  line-height: 2.2;
}

.grid-view-caption .brows-job-location {
  margin-top: 0;
}

.grid-view-caption .brows-job-location p,
.grid-view-caption .brows-job-sallery {
  font-size: 14px;
  margin-bottom: 0;
  color: #828fa2;
}

.grid-view-caption .brows-job-location p i {
  margin-right: 0;
}

.grid-view-caption li i {
  padding-right: 10px;
}

.brows-job-location p i {
  font-size: 16px;
  margin-right: 10px;
}

.grid-view .brows-job-type .full-time-view {
  position: absolute;
  padding: 4px 15px;
  top: 10px;
  left: 0;
  right: auto;
  line-height: 1.4;
  font-size: 12px;
  border-radius: 2px 0 0 2px;
}

/* job deatils2 */
.full-card {
  background: #fff;
  border-radius: 4px;
  padding-bottom: 30px;
  border: 1px solid #eceff3;
  box-shadow: 0 0 10px 0 rgb(64 65 67 / 10%);
}

html body .mrg-0 {
  margin: 0 !important;
}

h2.detail-title {
  font-size: 18px;
  background: #f5f6f7;
  padding: 8px 20px;
  margin-bottom: 10px;
  line-height: 1.6;
  border-radius: 2px;
  color: #252d40;
  border-left: 3px solid #11b719;
}

ul.job-detail-des {
  padding: 0;
}

ul.job-detail-des li {
  padding: 0;
  color: #66727b;
  list-style: none;
  padding: 4px 12px 4px 20px;
}

ul.job-detail-des li span {
  font-weight: 600;
  margin-right: 20px;
  /* min-width   : 180px; */
  display: inline-block;
}

ul.job-detail-des li:nth-child(even) {
  /* background: #f3f6f8; */
}

.category-icon span {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  background: #333;
}

.full-detail-description p {
  margin-bottom: 20px;
  line-height: 1.9;
  font-size: 15px;
  padding: 0 20px;
}

ul.detail-list li {
  padding: 0;
  margin-bottom: 10px;
  line-height: 1.9;
  font-size: 17px;
  color: #707c88;
  list-style: none;
}

ul.detail-list li:before {
  content: "\f101";
  position: relative;
  font-size: 17px;
  color: #11b719;
  left: -12px;
  font-family: fontawesome;
}

ul.detail-list {
  padding-left: 33px;
}

.right-sidebar1 h2.side-widget-title {
  background: #11b719;
  margin: 0;
  text-align: left;
  padding-left: 12px;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  line-height: 48px;
}

.side-widget {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eceff3;
  box-shadow: 0 0 10px 0 rgb(64 65 67 / 10%);
  margin-bottom: 30px;
  overflow: hidden;
}

html body .padd-0 {
  padding: 0 !important;
}

.ur-detail-wrap {
  width: 100%;
  display: block;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0 2em 1.5em;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
}

html body .padd-top-20 {
  padding-top: 20px !important;
}

.sidebar1.right-sidebar1 ul {
  margin: 0;
  padding: 0;
}

.sidebar1.right-sidebar1 ul li {
  list-style: none;
  padding: 10px 10px 8px;
  color: #707c88;
  border-bottom: 1px solid #eceff3;
  margin-bottom: 1.5em;
}

ul.ove-detail-list li i {
  font-size: 27px;
  float: left;
  margin-right: 10px;
  color: #a4abb5;
}

ul.ove-detail-list li h5 {
  margin-bottom: 2px;
}

.ove-detail-list h5 {
  color: #272f46;
  font-weight: 700;
}

.flex-middle {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
}

.statistic-item {
  margin-bottom: 20px;
  border: 1px solid #e9eef3;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 13px 20px;
  background-color: #fff;
}

.statistic-item .icon i {
  font-size: 30px;
}

.theme-cl,
.input-with-icon .theme-cl {
  color: #03a84e;
}

.statistic-item .text {
  padding-left: 15px;
}

.statistic-item .number {
  font-size: 16px;
  color: #222;
  padding-right: 5px;
}

.brows-job-position .jb-approved {
  border: 1px solid #ddd;
  padding: 0px 5px;
}

/* Approval */
ul.list {
  margin: 0;
  padding: 0;
}

.manage-list-row {
  background: #fff;
  border: 1px solid #e9f0f3;
  border-radius: 4px;
  padding: 20px 0;
  margin-bottom: 15px;
  margin-left: 0;
  list-style: none;
  box-shadow: 0 3px 10px 0 rgb(62 28 131 / 2%);
  -webkit-box-shadow: 0 3px 10px 0 rgb(62 28 131 / 2%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
}

.mysp-sites-list .site-name,
.manage-list-row .job-info {
  float: left;
  padding-left: 80px;
  position: relative;
}

.manage-list-row .job-info {
  padding-left: 25px;
}

.manage-list-row .job-info .job-img {
  border-radius: 2px;
  float: left;
  margin-right: 25px;
  width: 60px;
}

.manage-list-row .job-info .job-img img {
  display: block;
  max-width: 70px;
}

.manage-list-row .job-details {
  display: inline-block;
  font-size: 16px;
}

.manage-list-row .job-info h3 {
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0;
  line-height: 1.3;
}

.manage-list-row .job-info h3 a {
  font-size: 1.5rem;
}

.manage-list-row small {
  color: #546e7a;
  display: inline-block;
  line-height: 1.3;
  margin-right: 15px;
}

.small,
small {
  font-size: 85%;
}

.manage-list-row small i {
  margin-right: 7px;
}

.job-company i,
.job-package i,
.job-location i {
  font-size: 16px;
  margin-right: 8px;
  color: #505f7d;
}

.job-company i {
  color: #ef9b0b;
}

.shortlisted-can {
  position: relative;
  height: 26px;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background-color: #30ab4a;
  text-align: center;
  font-weight: 500;
  border-radius: 4px;
  padding: 0 8px 0 0;
  margin: 0;
  padding-left: 34px;
  line-height: 27px;
  text-transform: capitalize;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.skill-tag {
  background-color: #eaedf3;
  border-radius: 0 2px 2px 0;
  color: #8492af;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.2727272727;
  margin: 2px 15px 2px 0;
  padding: 3px 7px;
  position: relative;
  text-transform: uppercase;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  -ms-border-radius: 0 2px 2px 0;
  -o-border-radius: 0 2px 2px 0;
}

.skill-tag:before {
  border-top: 10px solid transparent;
  border-left: 9px solid #eaedf3;
  border-bottom: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: 0;
  right: -8px;
  width: 0;
}

.skill-tag:after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 4px;
  position: absolute;
  top: 8px;
  right: -2px;
  width: 4px;
}

.shortlisted-can:before {
  content: "\e64c";
  font-family: "themify";
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 0 0 4px;
  line-height: 26px;
  height: 26px;
  width: 26px;
  display: inline-block;
  background-color: #38b653;
}

.manage-list-row .job-buttons {
  margin-top: 7px;
}

.mysp-sites-list .site-options,
.manage-list-row .job-buttons {
  float: right;
  margin-right: 25px;
  position: relative;
}

a.btn.btn-gary.manage-btn {
  background: #f4f5f7;
  box-shadow: none;
}

.btn.manage-btn {
  margin: 0 2px;
  padding: 10px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a.btn.btn-blue.manage-btn {
  background: #2196f3;
  color: #fff;
}

a.btn.btn-cancel.manage-btn {
  background: #f44336;
  color: #fff;
}

a.btn.btn-shortlist.manage-btn {
  background: #353535;
  color: #fff;
}

/* Dashboard */
.bg-theme {
  background: #f9f9f9;
  min-height: 220px;
}

.category-icon .text-primary {
  color: #1f3bb3 !important;
}

.bg-theme:hover {
  transform: scale(1.025);
  opacity: 0.95;
}

.bg-theme .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-light-theme {
  background: #ecf3ff;
}

.category-box {
  border: 2px solid #512da8;
  position: relative;
  height: 210px;
  background: #fff;
  box-shadow: 0 5px 25px 0 rgb(41 128 185 / 15%);
  -webkit-box-shadow: 0 5px 25px 0 rgb(41 128 185 / 15%);
  border-radius: 5px;
  text-align: center;
  padding: 30px 12px 30px;
  box-sizing: border-box;
  margin-top: 30px;
  overflow: hidden;
  transition: 0.6s linear;
  -webkit-transition: 0.6s linear;
  -moz-transition: 0.6s linear;
  -ms-transition: 0.6s linear;
  -o-transition: 0.6s linear;
}

.category-box .category-desc {
  z-index: 2;
}

.category-box .category-desc .category-icon {
  font-size: 40px;
  color: #ff7c39;
  margin-bottom: 10px;
  transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
  -webkit-transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
  -moz-transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
  -ms-transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
  -o-transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
}

.category-box .category-desc i {
  font-size: 40px;
  color: gray;
  margin-bottom: 7px;
  width: auto;
  height: auto;
  transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
}

.category-box .category-desc i.abs-icon {
  position: absolute;
  bottom: 5px;
  left: 5px;
  opacity: 0;
  transition: all ease-in 0.4s;
  color: #eff1f3eb;
}

.category-box .category-desc .category-detail h4 {
  font-weight: 500;
  margin-bottom: 5px;
}

.category-box .category-desc .category-detail p {
  margin-bottom: 5px;
}

.category-box:hover i.abs-icon {
  position: absolute;
  bottom: 15px;
  left: 15px;
  opacity: 1;
  color: #eff1f3;
}

.category-box .category-desc .badge i {
  font-size: 12px;
  color: #fff;
}

.category-box .category-desc .badge {
  font-size: 15px;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
  color: white !important;
  background: #4da761 !important;
  border: 1px solid #4da761 !important;
  border-radius: 4px;
}

.category-box:hover {
  transform: scale(1.02);
  opacity: 0.7;
  box-shadow: 0px 15px 26px rgb(0 0 0 / 50%);
  transition: all 0.2s ease-in;
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

ul.ove-detail-list li {
  list-style: none;
  margin-bottom: 1.5em;
  padding: 0;
}

.BoxPanel {
  width: 100%;
  border: 0;
  display: block;
  padding: 0;
  cursor: pointer;
}

.left-col-deatil {
  /* border-right: 1px solid #ddd; */
}
/* search */
.item-card9-imgs {
  /* height: 197px; */
}

.item-card9-img {
  width: 200px;
}

.item-card9-img1 {
  width: 200px;
}

.item-card9-imgs {
  -ms-flex-direction: column;
  flex-direction: column;
}

.item-card9-imgs {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
}

.item-card9-imgs {
  position: relative;
}

.item-card9-img .item-card9-imgs a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
}

.bg-theme {
}

.item-card9-imgs img {
  padding: 0.5rem;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.border-start {
  border-left: 1px solid #e8ebf3 !important;
}

.bg-light-blue {
  background-color: #f0f3fa;
}

.searchpublish .card .card a {
  color: #333;
}

.searchpublish .card .card i {
  /* color: #333 !important; */
}

.searchpublish .card .card {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.form-group {
  margin-bottom: 1.5rem;
}

.star-rating {
  line-height: 32px;
  font-size: 1.25em;
}

* {
  margin: 0;
  padding: 0;
}

.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked) > label:before {
  content: "★ ";
}

.rate > input:checked ~ label {
  color: #ffc700;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.category-icon span {
  border-radius: 50%;
  height: 65px;
  width: 65px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 50%);
}

.BoxPanel {
  background: transparent;
}

.grid-margin .bg-facebook,
.grid-margin .bg-linkedin,
.grid-margin .bg-twitter,
.grid-margin .bg-primary {
  min-height: 215px;
}

.grid-margin .bg-facebook:hover,
.grid-margin .bg-linkedin:hover,
.grid-margin .bg-twitter:hover,
.grid-margin .bg-primary:hover {
  opacity: 0.85;
  transform: scale(1.02);
}

.section-vbox {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
/* project deatils */
.kt-portlet__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 60px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.kt-portlet__head-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-first;
  align-content: flex-first;
}

.project-signle-content-main .card {
  box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -webkit-box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}

.project-signle-content-main.card,
.project-signle-content-main .card-group {
  margin-bottom: 30px;
}

.project-signle-content-main.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
}

#project-details-id .row {
  font-weight: 300;
}

#project-details-id > div {
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.column-details-1,
.column-details-2 {
  /* font-size: 17px; */
  padding-top: 2px;
  padding-bottom: 2px;
}

.sidebar-ul > li {
  box-sizing: border-box;
  padding: 15px;
  background: white;
  border-radius: 8px;
  margin-bottom: 15px;
  border-radius: 9px;
  border: 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -webkit-box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  border: 1px solid #d8d8d8;
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.sidebar-ul > li {
  padding: 15px;
}

.btn-outline-primary {
  color: #00cc83;
  border-color: #00cc83;
}

.btn-outline-primary:hover {
  background-color: #00c17c;
  border-color: #00c17c;
}

.sharer {
  margin-left: 3px;
  margin-right: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

input.search {
  height: auto;
  padding: 10px;
}

.sidebar-archive .section-head input {
  border: 0;
  margin: 0;
}

.section-head .search-field-wrap {
  overflow: hidden;
}

.sidebar-archive .section-head .search-button {
  float: right;
  padding: 7px 18px;
}

.sidebar-archive .section-head {
  border: 1px solid #d8dbe4;
  color: #6d6096;
  margin-bottom: 1em;
}

.sidebar-archive .section-head .search-button {
  float: right;
  padding: 10px 18px;
  border-radius: 0;
}

.search-button {
  background-color: #564885;
}

.search-button {
  border: 0;
  padding: 9px 22px;
  margin: 0;
}

.search-button {
  background-color: #564885;
}

.sidebar-archive h4 {
  background-color: #e1e4ef;
  margin: 0;
  color: #425057;
  font-size: 1em;
  padding: 15px;
}

.sidebar-archive ul.parent {
  border-left: solid 1px #edeff6;
  border-right: solid 1px #edeff6;
  margin-bottom: 0;
  padding: 1em;
}

.sidebar-archive ul.parent > li > label,
.sidebar-archive ul.parent > label {
  font-weight: 600;
  color: #888;
  padding-top: 0.5em;
}

.sidebar-archive label {
  font-size: 0.875em;
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin-bottom: 0.1875em;
}

.sidebar-archive ul {
  list-style-type: none;
}

.sidebar-archive span.custom.checkbox {
  margin-right: 3px;
}

form.custom .custom {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  border: solid 1px #ccc;
  background: #fff;
}
/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */
/*********************** responsive **********************/
@media (max-width: 991px) {
  .MobResView .d-none {
    display: block !important;
  }

  .sidebar .nav:not(.sub-menu) > .nav-item > .nav-link[aria-expanded="true"] {
    color: #ffff;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing,
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    width: 100%;
  }

  .navbar .navbar-menu-wrapper {
    width: calc(100%);
  }

  .sidebar {
    min-height: calc(100vh - 55px);
    width: 201px;
  }

  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 55px);
    top: 55px;
    right: -202px;
  }

  .sidebar .nav .nav-item .nav-link {
    color: #fff;
    padding: 10px;
  }

  .sidebar .nav .nav-item:hover > .nav-link i,
  .sidebar .nav .nav-item:hover > .nav-link .menu-title,
  .sidebar .nav .nav-item:hover > .nav-link .menu-arrow {
    color: #fff;
  }

  .sidebar .nav .nav-item .nav-link i {
    margin-right: 1rem;
  }

  .sidebar .nav .nav-item.active > .nav-link i,
  .sidebar .nav .nav-item.active > .nav-link .menu-title,
  .sidebar .nav .nav-item.active > .nav-link .menu-arrow {
    color: #fff;
  }
}
/*praveen*/

.float-sm-right {
  float: right;
}

textarea.form-control.project-desc {
  height: 130px;
  resize: vertical;
}

.form-control {
  border: 1px solid #cbcbcb;
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

a.btn.btn-circle.btn-default {
  background-color: #512da8;
  color: #fff;
}

h3.fw-bold.mb-20.mt_20.text-center.thanku-text {
  font-size: 30px;
  font-family: calibri;
  color: #6c757d;
  margin-top: 3em;
  margin-bottom: 3em;
}

a.d-block.mr_5.pb-2.editpopupclient,
a.d-block.pb-2.deletepopup {
  background-color: #512da8;
  color: #fff;
  border-radius: 21px;
  margin-bottom: 10px;
  padding: 10px;
  width: 200px;
}

span.project-title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Manrope";
  color: #3f2a72;
}

.form-wizard {
  /* color: #888888; */
  padding: 30px;
}

.form-wizard .wizard-form-radio {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}

.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #fb1647;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::after {
  content: "";
  display: inline-block;
  webkit-animation: click-radio-wave 0.65s;
  -moz-animation: click-radio-wave 0.65s;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}

.form-wizard .wizard-form-radio input[type="radio"] ~ label {
  padding-left: 10px;
  cursor: pointer;
}

.form-wizard .form-wizard-header {
  text-align: center;
}

.form-wizard .form-wizard-next-btn,
.form-wizard .form-wizard-previous-btn,
.form-wizard .form-wizard-submit {
  background-color: #d65470;
  color: #ffffff;
  display: inline-block;
  min-width: 100px;
  min-width: 120px;
  padding: 10px;
  text-align: center;
}

.btn {
  padding: 10px;
  min-width: 135px;
}

.btn-primary.form-wizard-next-btn,
.form-wizard-previous-btn {
  background: #1f3bb3 !important;
}

.form-wizard-next-btn.btn-success {
  background: gray !important;
}

.form-wizard .form-wizard-next-btn:hover,
.form-wizard .form-wizard-next-btn:focus,
.form-wizard .form-wizard-previous-btn:hover,
.form-wizard .form-wizard-previous-btn:focus,
.form-wizard .form-wizard-submit:hover,
.form-wizard .form-wizard-submit:focus {
  color: #ffffff;
  opacity: 0.6;
  text-decoration: none;
}

.form-wizard .wizard-fieldset {
  display: none;
}

.form-wizard .wizard-fieldset.show {
  display: block;
  padding: 15px 35px;
}

.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.form-wizard .form-wizard-previous-btn {
  background-color: #fb1647;
}

.form-wizard .form-control {
  font-weight: 300;
  min-height: 35px;
  /* padding: 15px; */
  color: #888888;
}

.form-wizard .form-select {
  min-height: 40px;
}

.form-wizard .form-control:focus {
  box-shadow: none;
}

.form-wizard .form-group {
  position: relative;
  /* margin: 25px 0; */
  margin-bottom: 15px;
}

.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 16px;
  transition: 0.2s linear all;
}

.form-wizard .focus-input .wizard-form-text-label {
  color: #d65470;
  top: -18px;
  transition: 0.2s linear all;
  font-size: 12px;
}

.form-wizard .form-wizard-steps {
  margin: 30px 0;
}

.form-wizard .form-wizard-steps li {
  width: 32%;
  float: left;
  position: relative;
}

.form-wizard .form-wizard-steps li::after {
  background-color: #f3f3f3;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.form-wizard .form-wizard-steps li span {
  background-color: #dddddd;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 1;
}

.form-wizard .form-wizard-steps li:last-child::after {
  width: 50%;
}

.form-wizard .form-wizard-steps li.active span,
.form-wizard .form-wizard-steps li.activated span {
  background-color: #512da8;
  color: #ffffff;
}

.form-wizard .form-wizard-steps li.active::after,
.form-wizard .form-wizard-steps li.activated::after {
  background-color: #512da8;
  left: 50%;
  width: 50%;
  border-color: #512da8;
}

.form-wizard .form-wizard-steps li.activated::after {
  width: 100%;
  border-color: #512da8;
}

.form-wizard .form-wizard-steps li:last-child::after {
  left: 0;
}

h3.company_reg_confirmation_messg {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #203ab3;
  line-height: 30px;
  font-family: calibri;
}

.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }

  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0;
  }
}

.sidebar-title {
  padding-left: 10px;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    padding-left: 1.5rem;
    padding-right: 2rem;
    text-align: left;
    position: static;
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto;
  }
}

h3.mb-0.ml_50.view-response a,
h3.mb-0.ml_50.view-response {
  color: #0d121b;
  font-weight: bold;
}

img.h-80.img-responsive {
  height: 80px;
  margin-top: 4pc;
}

.badge.badge-opacity-warning.me-3 a {
  color: #805905;
  border-radius: 6px;
  font-size: 12px;
}

.form-search {
  margin: 0 auto;
  text-align: center;
  font: bold 13px sans-serif;
  max-width: 70%;
  position: relative;
}

.form-search input {
  width: 60%;
  box-sizing: border-box;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: #ffffff;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 14px 15px 14px 40px;
  border: 1px solid #b6c3cd;
  border-right: 0;
  color: #4e565c;
  outline: none;
  -webkit-appearance: none;
}

.form-search button {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #6caee0;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  color: #ffffff;
  padding: 15px 22px;
  margin-left: -4px;
  cursor: pointer;
  border: none;
  outline: none;
}

.form-search i {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 16px;
  color: #80a3bd;
}

.badge.badge-opacity-warning.me-3.h-30.float-right {
  max-height: 25px;
  margin-top: 15px;
}
/* .media {
  background-color: aliceblue;
} */
ul.navbar-nav.float-right.marg-left {
  margin-left: 78pc;
}

.v2 {
  position: absolute;
  height: 60px;
  border-right: 1px solid #d3d3d3;
  right: 10pc;
}

.v3 {
  position: absolute;
  height: 49px;
  border-right: 1px solid #d3d3d3;
  right: 14pc;
  top: 0px;
}

.v4 {
  position: absolute;
  height: 57px;
  border-right: 1px solid #d3d3d3;
  right: 21pc;
  top: 0px;
}

.v5 {
  position: absolute;
  height: 68px;
  border-right: 1px solid #949494;
  right: 8pc;
  top: 0px;
}

.v6 {
  position: absolute;
  height: 50px;
  border-right: 1px solid #d3d3d3;
  right: 7.5pc;
  top: -10px;
}

.v7 {
  position: absolute;
  height: 57px;
  border-right: 1px solid #949494;
  right: 7pc;
  top: 0px;
}

.me-3.h-30.pt_20.float-right a {
  font-size: 16px;
  color: #454343;
}

.fc-event.text-center.pt-3.pb-2 {
  border-top: 1px solid #e1e1e1;
}

.col-12.col-md-2.float-right i {
  position: relative;
  left: 2pc;
  font-size: 24px;
  top: 6px;
}

.badge.badge-light {
  color: #000;
}

h3.fw-bold.post-rfp.float-right a {
  color: #3f2a72;
}

li.list-group-item-section.col-12.col-sm-4 {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #34b1aa;
  text-decoration: none;
  background-color: #d6efee;
  border: 0px solid #cbd2d9;
}

.form-search.col-12.col-sm-6.mb_10
  .form-check.form-check-primary.d-inline-block {
  /* float: right; */
  position: absolute;
  right: 10pc;
  bottom: 10px;
}

a.btn.btn-dark.btn-lg.btn-block.frwrd-btn {
  padding: 5px;
  background: #1f3bb3 !important;
}

textarea.form-control.min-height-150 {
  min-height: 170px;
}

.min-height-60 {
  min-height: 50px;
}

p.company-name.mt_30 {
  font-weight: bold;
  color: #512da8;
  text-transform: uppercase;
}

.float-right.view-more {
  position: absolute;
  right: 0px;
  top: 10px;
}

h5.notification-text {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #3f2a72;
}

.notify-box {
  margin-top: 8pc;
}

img.img-responsive.completeimg {
  width: 100px;
  text-align: center;
  position: relative;
  left: 14pc;
}

.card.border-primary.border.pricing-card-body
  .card.border-primary.border.pricing-card-body
  .card.overflow-hidden.border-0.box-shadow-0.border-start.br-0.mb-0
  .card-header.pt-3.pb-3 {
  border-color: #949494 !important;
}

span.float-right.pt-1 a {
  background-color: #6c757d;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  border: unset;
}

.mod-edit-btn {
  /* text-align: right; */
  padding-right: 50px;
  padding-top: 10px;
  background-color: #f0f5f5;
  padding-bottom: 10px;
  border-bottom: 1px solid #a9a7a7;
  border-top: 1px solid #a9a7a7;
}

.btn-group .btn-default {
  background-color: #d3d3d3;
  margin-bottom: 1;
  border: 1px solid #9e9b9b;
}

h4.mod-edit-btn a {
  color: #000;
}

.border-primary {
  border-color: #a9a7a7 !important;
}

input.form-control.min-height-150 {
  min-height: 150px;
}

.box-shadow {
  border: 1px solid #e3e3e3;
  margin-bottom: 10px;
  border-radius: 3px;
}

label.heading-new {
  color: #010101;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: 600;
}

.EndUser span.float-right.pt-1 a,
.pr span.float-right.pt-1 a {
  background-color: gray;
}

.btn-dribbble.btn-social-icon-text i {
  background-color: gray;
  border-radius: 50%;
  margin-right: 5px;
}

.btn-dribbble.btn-social-icon-text {
  background-color: rgb(0, 0, 0, 0.3);
  border: 1px solid gray;
  border-radius: 20px;
  text-align: left;
}

.bg-gray,
.btn-light {
  background-color: #d0d0d0 !important;
  color: #000 !important;
}

.module-border {
  padding: 0 30px;
}

button {
  min-width: auto !important;
}

div#heading-2,
div#collapse-2 {
  /* background-color: #d6efee !important; */
  border: 1px solid;
}

.accordion.card .card-header a[aria-expanded="false"]:before {
  content: "\e61a";
}

.accordion.card .card-header a:before {
  font-family: "themify";
  position: absolute;
  right: 7px;
  top: 0;
  font-size: 14px;
  display: block;
}

.accordion.card .card-header a {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  position: relative;
  -webkit-transition: color 0.5s ease;
  -moz-transition: color 0.5s ease;
  -ms-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
  padding-right: 1.5rem;
}

.accordion.card .card-header a[aria-expanded="true"]:before {
  content: "\e622";
}

.accordion.card .card-header a:before {
  font-family: "themify";
  position: absolute;
  right: 7px;
  top: 0;
  font-size: 14px;
  display: block;
}

.register-employer .primary-bg-color,
.register-freelancer .primary-bg-color {
  min-width: 190px;
}

.btn-secondary,
.wizard > .actions .disabled a,
.btn-secondary,
.wizard > .actions .disabled a:hover {
  background: #1f3bb3;
  color: #ffffff;
  opacity: 0.5;
  border-color: #1f3bb3;
}

.wizard > .steps .number,
.wizard > .steps a {
  font-size: 1rem;
}

textarea.form-control {
  white-space: normal;
}

.UserImg {
  width: 100px;
}
/* User details */
.for-portfolio .resume_details-title:before {
  background-image: url(../images/icon-project-highlights_43d002.svg);
}

.for-experience .resume_details-title:before {
  background-image: url(../images/icon-experience_35e48f.svg);
}

.resume_details-map_wrapper .resume_details-title:before {
  background-image: url(../images/icon-location_207c6d.svg);
}

.for-availability .resume_details-title:before {
  background-image: url(../images/icon-availability_72ff6e.svg);
}

.for-preferred-environment .resume_details-title:before {
  background-image: url(../images/icon-preferred-environment_9be50a.svg);
}

.for-the-most-amazing .resume_details-title:before {
  background-image: url(../images/icon-most-amazing_17eb21.svg);
}

.resume_details-amazing_thing
  .resume_details-amazing_thing_content
  .closing_quote {
  background: url(../images/quote-close_56d99e.png) no-repeat;
  background-size: 22px 14px;
  height: 14px;
  width: 22px;
  bottom: 0;
  position: absolute;
  right: -20px;
}

.resume_details-amazing_thing
  .resume_details-amazing_thing_content
  .opening_quote {
  background: url(../images/quote-open_575659.png) no-repeat;
  background-size: 22px 14px;
  height: 14px;
  width: 22px;
  left: -15px;
  position: absolute;
  top: -8px;
}

.js-employments .resume_section-title:before {
  background-image: url(../images/icon-employment_486dfd.svg);
}

.for-experience .resume_section-title:before {
  background-image: url(../images/icon-experience_35e48f.svg);
}

.js-skills .resume_section-title:before {
  background-image: url(../images/icon-skills_26b332.svg);
}

.js-educations .resume_section-title:before {
  background-image: url(../images/icon-education_69af13.svg);
}

.is-certification .resume_section-title:before {
  background-image: url(../images/icon-certification_700ec1.svg);
}

.resume_top-photo_wrapper:before {
  content: "";
  position: relative;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  width: 48px;
  height: 24px;
  background: no-repeat url(../images/icons.png);
  background-size: cover;
}

.resume_top-photo_wrapper {
  border: 1px solid #ccc;
}

.footer {
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(23, 23, 23, 0.32);
}

.modal-title {
  margin-top: 0;
  font-family: "Barlow-Medium";
  color: #203ab3;
  font-size: 20px;
  /* text-transform: uppercase; */
}

a.btn.btn-warning.btn-md.next-btn {
  background-color: #007dc5;
  border: 1px solid #007dc5;
}

.btn {
  height: auto;
  line-height: normal;
}

.btn,
.btn-large,
.btn-large-white,
.btn-mid,
.btn-rp {
  background: sss;
}

.justify-content-between {
  align-items: center;
}

.footer {
  visibility: visible;
  padding: 10px 2.45rem;
}

.card-header h3 {
  margin-bottom: 0;
}

/*praveen-18-7-22*/
.block {
  height: auto;
  /* margin-bottom: 20px; */
  text-align: center;
  padding: 50px;
}

.shine {
  height: 20px;
  width: 60%;
  background-image: radial-gradient(
    farthest-side at 50% -50%,
    hsla(0, 0%, 0%, 0.5),
    hsla(0, 0%, 0%, 0)
  );
  position: relative;
}

.shine::before {
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 0%, 0),
    hsla(0, 0%, 0%, 0.75) 50%,
    hsla(0, 0%, 0%, 0)
  );
}

html.company-homepage .navbar li a {
  color: #666666;
  /* font-size: 16px; */
  text-decoration: none;
}

html.company-homepage .navbar li a.active {
  color: #ff7c33;
  font-weight: bold;
}

html.company-homepage a.btn.btn-primary.fw-bold.register-btn.btn-sm {
  width: 300px;
  background-color: #002d62;
  height: 45px;
  font-size: 24px;
  padding-top: 5px;
  margin-top: 70px;
}

html.company-homepage a.navbar-link {
  color: #666666;
  font-size: 16px;
  text-decoration: none;
  margin-right: 25px;
  margin-left: 10px;
}

.text-center.pricing-card-head h3 {
  font-size: 30px;
  font-weight: bold;
  color: #002d62;
}

.text-center.pricing-card-head p {
  font-size: 16px;
}

h2.home-page-heading.h3.pt-3.text-muted.txt-white {
  color: #fff !important;
}

h2.home-page-heading.h3.pt-3.text-muted {
  color: #002d62 !important;
  font-size: 45px;
}

.MultiCarousel {
  float: left;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  position: relative;
}

.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item {
  float: left;
  padding: 0 50px;
}

.MultiCarousel .MultiCarousel-inner .item > div {
  text-align: center;
  /* padding: 10px;
      margin: 10px;
      background: #f1f1f1; */
  color: #666;
}

.effect-text-in img {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.effect-text-in img:hover {
  filter: grayscale(100%);
}

.MultiCarousel .leftLst,
.MultiCarousel .rightLst {
  position: absolute;
  border-radius: 50%;
  top: calc(50% - 20px);
  height: 35px;
  width: 35px;
}

.MultiCarousel .leftLst {
  left: 0;
}

.MultiCarousel .rightLst {
  right: 0;
}

.MultiCarousel .leftLst.over,
.MultiCarousel .rightLst.over {
  pointer-events: none;
  background: #ccc;
  border: none;
}

.contain {
  background-color: #eee;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

div.form {
  background-color: #eee;
}

.contact-wrapper {
  margin: auto 0;
}

.submit-btn {
  float: left;
}

.reset-btn {
  float: right;
}

.form-headline:after {
  content: "";
  display: block;
  width: 10%;
  padding-top: 10px;
  border-bottom: 3px solid #f89828;
}

.highlight-text {
  color: #ec1c24;
}

.hightlight-contact-info {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5;
  text-align: left;
}

.highlight-text-grey {
  font-weight: 500;
}

.email-info {
  margin-top: 20px;
}

::-webkit-input-placeholder {
  /* Chrome */
  font-family: "Roboto", sans-serif;
}

.card-title,
.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
  font-family: "Barlow-Medium";
}

@font-face {
  font-family: "Barlow-Black";
  src: url("../fonts/Barlow-Black.otf") format("truetype");
}

@font-face {
  font-family: "Barlow-Black";
  src: url("../fonts/Barlow-Black.otf") format("truetype");
}

@font-face {
  font-family: "Barlow-light";
  src: url("../fonts/Barlow-Light.otf") format("truetype");
}

@font-face {
  font-family: "Barlow-Medium";
  src: url("../fonts/Barlow-Medium.otf") format("truetype");
}

.required-input {
  color: black;
}

@media (min-width: 600px) {
  .contain {
    padding: 0;
  }
}

h3,
ul {
  margin: 0;
}

h3 {
  margin-bottom: 1rem;
}

.form-input:focus,
textarea:focus {
  outline: 1.5px solid #ec1c24;
}

.form-input,
textarea {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.wrapper > * {
  padding: 1em;
}

@media (min-width: 992px) {
  .wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .wrapper > * {
    padding: 2em 2em;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.contacts {
  color: #212d31;
}

.form {
  background: #fff;
}

form {
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

form#submit-form input {
  margin-bottom: 20px;
}

form label {
  display: block;
}

form p {
  margin: 0;
}

.full-width {
  grid-column: 1 / 3;
}

button,
.submit-btn,
.form-input,
textarea {
  padding: 1em;
}

button,
.submit-btn {
  background: transparent;
  border: 1px solid #ec1c24;
  color: #ec1c24;
  border-radius: 15px;
  padding: 5px 20px;
  text-transform: uppercase;
}

hr.shine {
  margin: 0 auto;
}

.error {
  color: #ec1c24 !important;
}

h2.form-headline {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  text-align: left;
}

h4.home-page-heading.h3.pt-3.text-muted {
  color: #002d62 !important;
  font-weight: bold;
  padding-left: 45px;
}
html.company-homepage div#contact-us1 {
  background: linear-gradient(
      140deg,
      rgba(0, 79, 165, 0.92) 0%,
      rgba(0, 45, 98, 0.92) 100%
    ),
    url(../images/contactUs-new.jpg) !important;
}

html.company-homepage div#service {
  background: url(../images/cu.png);
}

ul.font-18 li {
  font-size: 24px;
  text-align: left;
}

html.partner_register-page h3.fw-bold.mb-20.mt_20.text-center.thanku-text {
  color: #002d62;
}

img.img-responsive.width-150 {
  width: 100px;
  display: inline;
  text-align: center;
}

a.btn.btn-primary.btn-outline-primary.btn-block {
  font-size: 18px;
  font-weight: bold;
}

html.company-homepage div.form {
  background-color: #eeeeee00;
}

input.submit-btn {
  background-color: #002d62;
  border-color: #002d62;
  color: #fff;
}

button.reset-btn {
  background-color: #f79727;
  border-color: #f79727;
  color: #fff;
}

footer.footer {
  padding: 20px 20px;
}

html.partner_register-page .wizard > .content {
  min-height: 70em;
}

html.company_register-page .wizard > .content,
html.partner_register-page .wizard > .content {
  min-height: 50em;
  overflow: hidden;
  padding: 25px;
}

.form-group.display-flex {
  display: flex;
}

html.company_register-page .auth-form-light,
html.partner_register-page .auth-form-light {
  border-radius: unset;
}

html.signup-page .fre-register-default {
  padding-bottom: 0;
}

html.signup-page .fre-register-wrap {
  padding-top: 0;
}

html.company-homepage .col-lg-12.px-5.bg-light-theme.pt-4.pb-3 {
  min-height: 420px;
}

html.company-homepage
  .timeline
  .timeline-wrapper
  .timeline-panel
  .timeline-title {
  font-size: 16px;
  font-weight: bold;
}

html.company-homepage .form li,
p.full-width {
  color: #fff;
}

html.company_register-page section#steps-uid-0-p-0 {
  padding: 25px;
}

.comment {
  margin: 50px auto;
  padding: 0px 20px 0px 30px;
  border-left: solid 2px #ccc;
}

.comment p {
  border-left: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  padding: 10px;
  line-height: 1.6;
  position: relative;
}

.comment p::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border: solid 2px #ccc;
  border-radius: 50%;
  background-color: #2e5c90;
  top: 10px;
  left: -40px;
}

html.login-page .fre-register-wrap .register-freelancer h3 {
  margin: 0 0 0px;
}

.text-justify {
  text-align: justify;
}

.btn-sm,
.btn-group-sm > .btn,
.fc .btn-group-sm > button,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
.wizard > .actions .btn-group-sm > a,
.fc button {
  padding: 0.5rem 0.81rem;
  font-size: 0.812rem;
  border-radius: 0.1875rem;
}

.box-shadow {
  border-radius: 0;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%), 0 1px 2px 0 rgb(0 0 0 / 10%),
    0 2px 20px 0 rgb(0 0 0 / 20%);
  margin: 20px 0;
}

.boxshadow {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%), 0 1px 2px 0 rgb(0 0 0 / 10%),
    0 2px 20px 0 rgb(0 0 0 / 20%);
}

.upload-img {
  position: absolute;
  top: 13px;
  right: 12px;
}

html.preview-page .inline-block.width_100 {
  width: 100%;
}

html.preview-page b.float-start {
  width: 150px;
}

.prefill b {
  width: 160px;
  font-weight: normal;
}

.Employment .prefill b {
  width: 250px;
}

.cardPad.card-body.box-shadow.left-border {
  border-left: 10px solid #1e3bba;
}

.cardPad.card-body.border.border-blue {
  border-top-color: #1e3bba !important;
}

button.tn.btn-warning.upload-cv-btn.btn-icon-text {
  position: relative;
  margin-top: 150px;
  width: 330px;
  background-color: #0a66c2;
}

.box.box-f {
  border: 1px dotted #555454;
  padding: 25px;
}

a.text-right.add-popup {
  color: #504e4e;
  font-weight: bold;
}

.pricing-card-head {
  min-height: 12pc;
}

h4.home-page-heading.h3.pl_0.pt-3.text-left {
  color: #002d62 !important;
  font-weight: bold;
}

.suffix-email div::after {
  content: "@iamcaas.com";
  color: #007bff;
}

.suffix-email div::after {
  position: absolute;
  top: 8px;
  right: 0.5em;
  transition: all 0.05s ease-in-out;
}

.suffix-email div {
  display: block;
  position: relative;
}

.EndUserReg .form-control,
.EndUserReg .wizard > .content > .body input,
.EndUserReg .wizard > .content > .body select,
.EndUserReg .modal-body select.form-select {
  border: 1px solid #add3fc;
}

.suffix-email1 label {
  color: #1c80eb !important;
}

.suffix-email1 {
  border: 1px solid #1c80eb !important;
}

.EndUserReg form .form-group label {
  color: #0b73e9;
}

.rounded-top {
  background-color: #5671e2;
  /* height: 200px; */
}

.placeholder1 {
  z-index: 1;
}

.p-image {
  width: 150px;
}

.mt-80 {
  margin-top: 80px;
}

.text-heading-xlarge {
  font-size: 1.74em;
}

.text-body-medium {
  font-size: 1.2em;
}

.text-body-small {
  font-size: 1em;
}

.ViewDetails {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #fff;
  border-radius: 50%;
  padding: 0;
  height: 35px;
  width: 35px;
}

.alert.box-shadow-1.align-items-center {
  box-shadow: 0px 0px 0px 4px #00000029;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%), 0 1px 2px 0 rgb(0 0 0 / 10%),
    0 2px 20px 0 rgb(0 0 0 / 20%);
}

.accordion.accordion-multi-colored .card:nth-child(1) {
  background: #1f3bb3;
}

.searchpublish .card .card a {
  color: #333;
  font-weight: bold;
}

html.searchandpublish
  .accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="true"] {
  background: #512da8;
}

.companyvorpage div#heading-2 {
  border: 0;
}

html.searchandpublish
  .accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="true"] {
  background: #512da8;
}

html.companyvorpage .accordion > .card .card-header,
html.forward-page accordion > .card .card-header,
html.forward-page div#heading-2 {
  padding: 10px;
}

html.companyvorpage div#heading-2,
html.companyvorpage div#collapse-2,
html.forward-page div#collapse-2,
html.forward-page div#heading-2 {
  border: unset;
}

div#heading-3 {
  padding: 10px;
}

.accordion > .card .card-header,
.collapse.show {
  /* padding: 10px; */
  border: 0 !important;
}

.collapse.show {
  padding: 0px;
}

#sidebar .collapse.show {
  padding: 0px;
}

div#heading-2,
div#collapse-2 {
  border: 0px solid !important;
}

.ShortlistTab td {
  white-space: normal;
  line-height: 1.2;
}

.ShortlistTab td:nth-child(1) {
  font-weight: bold;
}

.HoverPrimary .card.rounded.border:hover {
  border-color: #007bff !important;
  transform: scale(1.02);
}

.HoverSuccess .card.rounded.border:hover {
  border-color: green !important;
  transform: scale(1.02);
}

.HoverWarning .card.rounded.border:hover {
  border-color: orange !important;
  transform: scale(1.02);
}

.HoverPrimary .form-check,
.HoverSuccess .form-check,
.HoverWarning .form-check {
  margin-top: 0;
  margin-bottom: 0;
}

.HoverPrimary .media,
.HoverSuccess .media,
.HoverWarning .media {
  align-items: center;
}
/* add plus */
.mainsection .hideFirst {
  display: none;
}

.mainsection:first-child .hideFirst {
  display: block;
}

.DetailsHistory b {
  /* width: 100px; */
  display: inline-block;
}

.DetailsHistory td:last-child {
  white-space: normal;
}

.TabView h5 {
  display: table;
}

.TabView h5 span,
.TabView h5 span {
  display: table-cell;
}
/* 21-7-22 */
html.projectlistpage input.form-control.height-searchbox {
  height: 2.92rem;
}

html.projectlistpage button.input-group-text.btn-search-close:hover,
button.input-group-text.btn-search-close:focus {
  background: #e9ecef;
  color: #171717;
}

html.projectlistpage
  a.btn.btn-outline-light.fw-bold.mt-4.float-right.ml_20:hover {
  color: #000 !important;
}

html.projectlistpage a.btn.btn.btn-light.col-md-3.fw-bold.mt-4.mb-4 {
  background-color: #ededed !important;
}

html.projectlistpage
  a.btn.btn-outline-light.btn-default.fw-bold.mt-4.float-right.ml_20.disabled {
  background-color: #d3d3d3;
  color: #777;
  border: 1px solid #898b8e;
  cursor: not-allowed;
}

html.projectlistpage button.input-group-text.btn-search-close {
  color: #000;
}

html.projectlistpage a.btn.btn-primary {
  background-color: #0071e0;
  border: 1px solid#0071e0;
}

html.projectlistpage .bg-theme {
  background: #e2eefe;
  min-height: 220px;
}

html.projectlistpage a.btn.btn-primary.disabled {
  background-color: #d3d3d3;
  border: 1px solid#898b8e;
  color: #777 !important;
}

a.edit-profile.btn-orange.quote-btn {
  background: #007dc5;
  border: 1px solid #007dc5;
}

.modal-dialog.modal-medium,
.modal-dialog.modal-lg {
  /* min-width: 75%;
  max-width: 75%; */
}

.DealContractCol {
  width: 150px;
  vertical-align: middle;
  text-align: center;
}

.DealContractCols td:nth-child(2) {
  text-align: center;
  display: flex;
  justify-content: center;
}

.nav-link {
  padding: 0.75rem 1rem;
}

.company-homepage .joinus-link {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
}

.JoinUs,
.JoinUs:hover,
.JoinUs:focus {
  font-size: 5pc;
  font-weight: bold;
  color: rgb(247, 151, 39);
}

.joinus-link h5 {
  font-size: 2.5pc;
  font-weight: bold;
  color: #fff;
}

.company-homepage .navbar-light .navbar-nav .nav-link.active {
  color: #1f3bb3;
}

.disabled {
  pointer-events: none;
  background-color: #e9ecef;
  opacity: 1;
}

.searchpublish .card .card a.moreinfo {
  color: #0a58ca;
}

.searchpublish .card .card a.moreinfo i {
  font-size: 1em;
}

#ScrollGrid.active .scrollDiv {
  position: fixed;
  top: 6pc;
  height: 500px;
  overflow-y: auto;
  bottom: 1pc;
  display: block;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-property: right, box-shadow;
}
/* width */
.scrollDiv::-webkit-scrollbar,
.SideBarSearchScroll::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollDiv::-webkit-scrollbar-track,
.SideBarSearchScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollDiv::-webkit-scrollbar-thumb,
.SideBarSearchScroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollDiv::-webkit-scrollbar-thumb:hover,
.SideBarSearchScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .scrollDiv .accordion{
  padding-bottom: 20px;
} */
#settings-trigger.NewSearch {
  position: unset;
  border-radius: 20px;
  width: auto;
  height: auto;
  display: inline-block;
  border: 0;
}

#theme-settings .settings-close {
  top: 25px;
}

.dropdown .dropdown-toggle:after {
  font-size: 0.75rem;
}

.settings-panel .settings-heading {
  padding: 16px 0 13px 16px;
}

.img-rounded {
  /* width: 200px;
  padding: 0.5rem; */
}

.table.colrow td img {
  width: 145px;
  height: auto;
  padding: 0.5rem;
  margin: 0 auto;
}

.table.colrow td:nth-child(1) {
  width: 22%;
}

.table.colrow td:nth-child(2) {
  width: 63%;
}

.table.colrow td:nth-child(3) {
  width: 15%;
  text-align: center;
}

.table.colrow td {
  white-space: normal;
  padding: 1rem 0.75rem;
}

.table.colrowCaas td img {
  width: 145px;
  height: 145px;
  padding: 0.5rem;
  margin: 0 auto;
}

.table.colrowCaas td:nth-child(1) {
  width: 19%;
}

.table.colrowCaas td:nth-child(2) {
  width: 50%;
}

.table.colrowCaas td:nth-child(3) {
  width: 31%;
  text-align: center;
}

.table.colrowCaas td {
  white-space: normal;
  padding: 1rem 0.75rem;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03) !important;
  border-top: 1px solid #e3e3e3;
}

.settings-panel {
  top: 0;
  width: 35%;
  right: -42pc;
}

.SideBarSearchScroll {
  overflow-y: auto;
  height: calc(100vh - 55.5px);
}

.hideScroll {
  overflow: hidden;
}

.ModifySearch#settings-trigger {
  background: transparent;
}

.ModifySearch#settings-trigger i {
  color: #0071e0;
}

.settings-panel.open {
  z-index: 1050;
}

.accordion .card-header h6 a {
  font-weight: bold !important;
}

.Quote ul.job-detail-des li {
  padding: 4px 0;
}

.accordion > .card .card-body {
  padding: 15px;
}

.accordion > .card .card-header {
  padding: 10px;
}

.btnsecondary:hover,
.btnsecondary {
  color: #fff !important;
  background-color: #5c636a !important;
  border-color: #565e64 !important;
}
.accordion.accordion-solid-content .card .card-body {
  padding: 2rem;
  background: unset;
  color: #000;
  border-radius: 0px 0px 8px 8px;
}
.card.box-shadow {
  border: 0.5px solid rgb(0 0 0 / 25%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #fff;
  border-width: 0.1px;
  border-radius: unset !important;
}
.accordion.accordion-solid-content .card .card-header a:before,
a.collapsed {
  color: #0a66c2 !important;
}
.font-heading {
  font-family: calibri;
}
.title-card {
  margin-bottom: 20px;
}
/* sep 6 */
/* section {
  margin: 20px;
} */

input::-webkit-file-upload-button:hover {
  background-color: #bd6e1f;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

/* sep 7 */
html.preview-page-2 .sidebar-icon-only .main-panel {
  width: calc(100% - 0px);
}

.container-fluid.bg-danger.preview-edit-section {
  background-color: #213b68 !important;
  height: 130px;
}

.card-body.container1.profile-section {
  width: 85%;
  margin: 0 auto;
  position: relative;
  bottom: 4em;
}

.mt_100 {
  margin-top: 100px;
}

.back-container {
  margin: 20px;
  margin-top: -148px;
}

.div-footer {
  position: sticky;
  bottom: 0em;
  right: 1em;
  padding: 10px;
  margin-top: 12em;
  width: 100%;
  background-color: #fff;
  border: 0;
}
/******************************************************* 1-9-2022 *********************************************/
.images_bg {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
}
.wrapper_popular {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.border-img {
  border: 3px solid #838383;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  /* opacity: 0.4; */
  position: absolute;
  /* transition: opacity 0.5s ease 0s; */
  width: 100%;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}
.text {
  color: #0a66c2;
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Barlow-bold;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.text_bg {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: left;
  padding: 0 30px;
}
.text_bg.image-2 {
  transform: translate(-50%, -20%);
}
.wrapper_popular .overlay_bg .text_bg {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.wrapper_popular:hover .overlay_bg .text_bg {
  opacity: 1;
  color: #fff;
}
.wrapper_popular:hover .overlay_bg {
  background-color: #0a66c2;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.wrapper_popular .overlay_bg {
  transition: all 0.3s ease-in-out;
}
.wrapper_popular:hover .overlay {
  opacity: 0;
}
.head-orange {
  font-family: Barlow-bold;
}
.text_bg h4 {
  font-size: 32px;
  font-family: Calibri_Bold;
}

/* Timeline start*/
@import url(https://fonts.googleapis.com/css?family=Dosis:500);
.timeline:before {
  background-color: transparent;
}
.partner-register {
  border: 1px solid #1f3bb3;
  border-radius: 10px;
  padding: 8px 20px;
  color: #1f3bb3;
}
.partner-register:hover,
.partner-register:focus,
.partner-register:visited,
.partner-register:active,
.partner-register:link {
  color: #1f3bb3;
}
.align_row * {
  width: 100%;
  max-width: 100%;
}
.timeline {
  counter-reset: number;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.timeline li:nth-child(6)::after {
  background: transparent;
}
.timeline li {
  list-style: none;
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: "Dosis", sans-serif;
  padding-left: 4.25pc;
  padding-right: 4.25pc;
  min-height: 14.5pc;
}

/* ul:nth-child(1){
color: #4caf50;
} */

.timeline li:before {
  counter-increment: number;
  content: counter(number);
  width: 50px;
  height: 50px;
  border: 3px solid #0a66c2;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 50px;
  margin: 0 auto 10px auto;
  background: #0a66c2;
  color: #fff;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  z-index: 2;
  position: relative;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  font-family: Calibri_Bold;
}

.timeline li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: grey;
  top: 25px;
  left: -50%;
  z-index: 1;
  transition: all ease-in-out 0.3s;
}

.timeline li:first-child:after {
  content: none;
}
.timeline li.active {
  color: #555555;
}
.timeline li.active:before {
  background: #0a66c2;
  color: #f1f1f1;
}

.timeline li.active + li:after {
  background: #000000;
}

/* Timeline end*/
.Timeline-head {
  color: #000000;
  font-weight: bold;
  font-family: Barlow-SemiBold;
  font-size: 16px;
  text-transform: uppercase;
}
.timeline-gray {
  color: #6f6f6f;
  font-size: 14px;
  font-family: "Barlow-Regular";
}
.wh-25 {
  height: 25px;
}
.company-homepage .joinus-link {
  left: 50%;
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
}
.Text-Green-Head {
  font-family: Barlow-SemiBold;
  font-size: 32px;
  line-height: 38px;
  color: #597529;
}
.JoinUs,
.JoinUs:hover,
.JoinUs:focus {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  font-family: Barlow-Bold;
  text-transform: uppercase;
  border: 2px solid #fff;
  padding: 7px 20px;
}
.navbar-expand-lg .navbar-nav .nav-link:last-child {
  padding-right: 0rem;
}
.navbar-brand h1,
.navbar-brand h1 span {
  font-family: Calibri_Bold;
  font-size: 36px;
}
.width_60 {
  width: 60%;
}
.joinus-link h5 {
  font-size: 62px;
  font-weight: bold;
  color: #340000;
  line-height: 1.3;
  font-family: Calibri_Bold;
}
.joinus-link h5 span {
  font-size: 62px;
  font-weight: bold;
  color: #fff;
  font-family: Calibri_Bold;
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: #000000 !important;
}

.wizard > .steps .number,
.wizard > .steps a {
  /* padding: 0; */
  margin: 0;
  font-size: 20px;
}

body,
body * {
  font-family: Calibri_Regular;
  font-family: Barlow-Regular;
  font-size: 15px;
  font-weight: 400;
}

.table thead th,
.jsgrid .jsgrid-table thead th,
.table td,
.jsgrid .jsgrid-table td {
  font-size: 15px;
}

@font-face {
  font-family: Calibri_Bold;
  src: url(../fonts/Calibri_Bold.TTF);
}

@font-face {
  font-family: Calibri_Light;
  src: url(../fonts/Calibri_Light.ttf);
}

@font-face {
  font-family: Calibri_Regular;
  src: url(../fonts/Calibri_Regular.ttf);
}

@font-face {
  font-family: Barlow-Black;
  src: url(../fonts/Barlow-Black.otf);
}

@font-face {
  font-family: Barlow-Bold;
  src: url(../fonts/Barlow-Bold.otf);
}

@font-face {
  font-family: Barlow-Regular;
  src: url(../fonts/Barlow-Regular.otf);
}

span.menu-title2.sidebar-title.main-title {
  font-family: "Barlow-Regular";
}

a.nav-link.text-center.pl_2.br-btm {
  margin: 0 auto !important;
}

#RFP_reg {
  pointer-events: none;
}

#RFP_reg .sidebar-title {
  padding-left: 0;
}

.sidebar .nav .nav-item#RFP_reg .nav-link {
  padding-left: 0;
  padding-right: 0;
}

a.nav-link.text-center.pl_2.br-btm {
  width: 90%;
  border-color: #d9d9d9;
}

.text-orange,
.text-orange:active,
.text-orange:hover,
.text-orange:link,
.text-orange:visited,
.text-orange:focus {
  /* color: #da7120; */
  color: #007dc5;
}
.bg-user {
  border: 10px solid #0a66c2;
  background: #0a66c2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.bg-user i {
  font-size: 2rem !important;
}
h4.text-green {
  border-color: #597529 !important;
}

.text-blue,
.text-blue:link,
.text-blue:hover,
.text-blue:focus,
.text-blue:active {
  color: #1e3bb3;
  font-family: "Barlow-Medium";
  text-decoration: none;
}

.box-shadow {
  border: 0.5px solid rgb(0 0 0 / 25%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #fff;
  border-width: 0.1px;
}

.list-item-details .d-table .d-table-cell:nth-child(1) {
  width: 25%;
}

.list-item-details {
  width: 80%;
}

.list-item-details .d-table {
  width: 80%;
  margin-bottom: 15px;
}

.text-green {
  color: green;
  font-weight: bold;
}

.text-red {
  color: red;
  font-weight: bold;
}

.text-grey {
  color: gray;
  font-weight: bold;
}

a.filter_by {
  color: #6f6f6f;
}

img.sidebar-icons {
  width: 25px;
}

.btn-orange {
  background: #0a66c2;
  border: 1px solid #0a66c2;
  border-radius: 10px;
  padding: 5px 25px;
  color: #fff;
  white-space: nowrap;
}

.footer {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.RFP-btn-group.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.RFP-btn-group {
  border-radius: 0;
}

.RFP-btn-group .btn {
  border-radius: 0;
  width: 250px;
  height: 53px;
  border: 2.5px solid #0a66c2;
  color: #597529;
  font-weight: 600;
  font-size: 20px;
}

.RFP-btn-group .btn.active {
  border-color: #0a66c2;
  background: #0a66c2;
  color: #fff;
}

.RFP-btn-group .btn:hover,
.RFP-btn-group .btn:focus,
.RFP-btn-group .btn:active,
.RFP-btn-group .btn:link,
.RFP-btn-group .btn:visited {
  border-color: #0a66c2;
  background: #0a66c2;
  color: #fff;
}

.main-panel {
  min-height: calc(100vh - 115px);
}
html.Post_RFP .page-body-wrapper {
  min-height: calc(100vh - 0px);
  padding-top: 0px;
}
html.Post_RFP .wizard-fieldset .form-control,
.wizard-fieldset .form-select,
.wizard-fieldset .datepicker.input-group {
  color: #454545;
}
html.Post_RFP,
html.RFP .page-body-wrapper,
.sidebar {
  min-height: calc(100vh - 80px);
}

html.Post_RFP,
html.RFP .container-fluid.page-body-wrapper {
  padding-top: 50px;
}

.fs-24 {
  font-size: 24px;
}

.mh-500 {
  min-height: 400px;
}

.accordion > .card .card-header a[aria-expanded="true"] {
  color: #0a66c2;
}

.btn-outline-secondary:hover,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel:hover {
  background: #007dc5;
  color: #ffffff !important;
}

.RFP-btn-group.btn-group > .btn.border-radius-50 {
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  background: #0a66c2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.RFP-btn-group.btn-group > .btn.border-radius-50:not(:last-child) {
  margin-right: 50px;
}

.Post_RFP .wizard .steps {
  padding-top: 0px;
  padding-bottom: 0px;
}

.Post_RFP .wizard .steps > ul {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#7b7f89),
    to(#7b7f89)
  );
  /* background-size: calc(100% - 50px) 4px; */
  background-repeat: no-repeat;
  background-position: center;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#000000)); */
  background-size: calc(100% - 50px) 2px;
}

.Post_RFP .wizard .steps > ul li a {
  display: inline-block;
}

.Post_RFP .wizard .audible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.Post_RFP .wizard .steps > ul li.active span.number,
.Post_RFP .wizard .steps > ul li.done span.number {
  background-color: #0a66c2;
}

.Post_RFP .wizard .steps > ul li span.number {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid #0a66c2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  /* line-height: 50px; */
  color: #0a66c2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Post_RFP .wizard .steps > ul li.active span.number {
  color: #fff;
}

.Post_RFP .wizard > .steps .current a {
  background: transparent;
  margin: 0;
  padding: 0;
}

.Post_RFP .wizard > .steps > ul > li {
  float: none;
  width: unset;
  text-align: unset;
}

.Post_RFP .wizard > .steps .disabled a {
  background: transparent;
  margin: 0;
  padding: 0;
}

.Post_RFP .wizard > .steps .disabled {
  background: transparent;
}

.Post_RFP #wizard {
  width: 80%;
  margin: 0 auto;
}

.grid-margin {
  margin-bottom: 0;
}

.Post_RFP .wizard > .steps a:hover {
  margin: 0;
}

.Post_RFP .wizard .steps > ul li a {
  pointer-events: none;
}

.Post_RFP .wizard .steps > ul li.active a {
  pointer-events: visible;
}

.wizard-fieldset .form-control,
.wizard-fieldset .form-select,
.wizard-fieldset .datepicker.input-group {
  border-radius: 0;
  border: 1px solid #848484;
}

.input-group-append .input-group-text {
  color: #000;
}

.file-upload {
  min-height: 100px;
}

.file-upload {
  border: 1px solid #848484;
}

.wizard-fieldset .form-select {
  padding: 0.65rem 1.375rem;
}

#uploadBtn {
  display: none;
}

#upload_prev span {
  display: flex;
  padding: 5px;
  font-size: 12px;
}

p.close {
  margin: 0 5px 0 15px;
  cursor: pointer;
  padding-bottom: 0;
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 1;
  filter: alpha(opacity=20);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #ff0000;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.border_green {
  border-bottom: 1px solid #597529;
  padding-bottom: 15px;
}

div.tagsinput input {
  width: auto;
  padding: 3px;
}

div.tagsinput div {
  width: 100%;
}

div.tagsinput span.tag {
  background: #d9d9d9;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

div.tagsinput span.tag a {
  color: rgba(0, 0, 0, 0.35);
  font-size: 3.5rem;
  line-height: 0.5;
  font-weight: 100;
  margin-top: -5px;
}

.main_skill {
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  border-top: 10px solid #0a66c2;
  padding: 25px;
}

.main_skill .d-table {
  width: 100%;
  margin-bottom: 10px;
}

.main_skill .d-table .d-table-cell:not(:last-child) {
  width: 30%;
}

.box-skill {
  position: relative;
}

.Edit_Skills {
  position: absolute;
  right: 1.5pc;
  top: 1.5pc;
  z-index: 1;
}

.btn-gray {
  background: #8a8a8a;
  border-radius: 10px !important;
  border-color: #8a8a8a;
  color: #fff !important;
  height: 35px;
}

.btn-orange {
  background: #0a66c2;
  border: 1px solid #0a66c2;
  color: #fff !important;
  border-radius: 10px !important;
  height: 35px;
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange:visited,
.btn-orange:active,
.btn-orange:link {
  background: #0a66c2;
  border: 1px solid #0a66c2;
  color: #fff !important;
}

.btn:hover,
.btn:focus,
.btn:visited,
.btn:active {
  opacity: 0.75 !important;
}
#Step_1 fieldset {
  padding: 0;
}
#Step_2 table th,
#Step_2 table td {
  white-space: initial;
}
#Step_2 table td:not(:last-child) {
  white-space: nowrap;
}
.border_right {
  border-right: 2px solid #d9d9d9;
  padding-right: 15px;
}
.border_right + span {
  padding-left: 15px;
}
.v-middle,
.v-middle span {
  vertical-align: middle;
}
.mx-20 {
  margin-left: 21px;
  margin-right: 21px;
}
.step_2 table td:not(:last-child) {
  min-width: 20%;
}
.List_Skills .border_right:last-child {
  padding-left: 0;
  border: 0;
  line-height: 1.3;
}
.List_Skills .border_right {
  margin-bottom: 10px;
  display: inline-block;
}
#Step_1 table td,
#Step_2 table td {
  vertical-align: top;
}
.btn-gray,
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray:visited,
.btn-gray:link {
  background: #8a8a8a;
  line-height: 1;
  opacity: 1;
  border: 0;
}

.width_left_hover_panel .wrapper_popular,
.width_left_hover_panel .wrapper_popular img {
  height: 100%;
}
.box_panel {
  border-radius: 10px;
  font-family: Barlow-SemiBold;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.green_box {
  background: #214735;
}
.light_brown_box {
  background: #d4af8b;
  border-radius: 10px;
}
.sliver_gray_box {
  background: #2f4454;
}
.sandal_box {
  background: #d8c3a5;
}
.dark_green_box {
  background: #1c3334;
}
.section-why-wc {
  background: #f4f2f2;
}
.head-white-why-wc {
  font-size: 26px;
  font-family: Barlow-SemiBold;
  display: block;
  width: 100%;
  max-width: 100%;
}
.count-value {
  font-size: 90px;
  font-family: Barlow-SemiBold;
  display: block;
  width: 100%;
  max-width: 100%;
}
/* corsole start */
@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: flex;
  }
}
.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}
@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }
  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
  .carousel-item .col-lg-4 {
    padding: 0 12px;
  }
}
.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

/* corsole end */
#recipeCarousel .carousel-item .card {
  min-height: 300px;
  border-top: 10px solid #0a66c2;
  border-radius: 12px;
}
#recipeCarousel .carousel-item .card .card-img-overlay {
  border: 1px solid #6f6f6f;
  border-radius: 0;
}
#recipeCarousel .card-img-overlay {
  text-align: left;
}
#recipeCarousel .carousel-control-next {
  opacity: 1;
  right: 0;
  justify-content: end;
}
#recipeCarousel .carousel-control-prev {
  opacity: 1;
  left: 0;
  justify-content: start;
}
#recipeCarousel .carousel-control-next-icon,
#recipeCarousel .carousel-control-prev-icon {
  background-color: #587624;
  border-radius: 50%;
  padding: 10px;
  height: 45px;
  width: 45px;
  border: 5px solid #587624;
}
.section-industry-news,
.section-footer {
  background-image: linear-gradient(to right, #cd864b 10%, #777471 70%);
}
.head-industry {
  font-size: 24px;
  font-family: Barlow-SemiBold;
}
#carouselExampleCaptions .carousel-indicators {
  position: relative;
}
#carouselExampleCaptions .carousel-indicators [data-bs-target] {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #393939;
  opacity: 1;
  border: 0;
}
#carouselExampleCaptions .carousel-indicators .active {
  background: #fff;
}
#carouselExampleCaptions .carousel-indicators {
  margin-bottom: 0;
}
.bg_contact {
  background-repeat: no-repeat;
  background-size: contain;
}
/* custom css for input css Start*/
/* End of basic info */

.input-block {
  display: block;
  max-width: 650px;
  margin: auto;
  text-align: left;
  background: #fff;
  padding: 40px 30px;
  box-shadow: 0px 0px 25px 6px rgba(0, 0, 0, 0.1);
}

/* Copy this style form on your website */
/* Inputs style */

.brise-input {
  position: relative;
  /* margin: 5px; */
  overflow: hidden;
  padding-top: 15px;
  margin-bottom: 10px;
}
.brise-input input {
  width: 100%;
  padding: 8px 0px;
  border: none;
  outline: none;
  border-bottom: 1px solid #1f3bb3;
  box-sizing: border-box;
  font-size: 16px;
  position: relative;
  z-index: 5;
  background: none;
}
.brise-input input:focus ~ label,
input:valid ~ label {
  top: 0px;
  transform: scale(0.94) translateX(-2px);
  color: #1f3bb3;
}
.brise-input input:focus {
  outline: 0;
  border-bottom: 1px solid #1f3bb3;
}
.brise-input .line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #1f3bb3;
  left: -999px;
  transition: 0.25s;
  opacity: 0;
  z-index: 6;
}
.brise-input input:focus ~ .line {
  left: 0;
  opacity: 1;
}
.brise-input label {
  position: absolute;
  left: 0px;
  top: 45%;
  transition: ease-out 0.15s;
  color: gray;
}
button.btn.btn-outline-primary.contact-submit-btn {
  border-color: #1f3bb3;
  color: #1f3bb3;
  width: 120px;
  font-weight: bold;
}

/* End Inputs */

/* custom css for input css End */
body,
html {
  background: #f4f5f7;
  /* height: 100%; */
}
.contact-form {
  width: 497px;
  min-height: 420px;
}
.contact-form {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.contact-head {
  font-family: Barlow-SemiBold;
  font-size: 22px;
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:before,
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  top: 5px;
}
.text-comment {
  font-size: 24px;
  font-family: Barlow-SemiBold;
}
.text-28 {
  font-size: 28px;
  font-family: Barlow-SemiBold;
}
.text-20 {
  font-size: 20px;
  font-family: Barlow-SemiBold;
}
.text-56 {
  font-size: 56px;
  font-family: Barlow-SemiBold;
  line-height: 0.78;
}
.footer-section {
  background: rgba(0, 0, 0, 0.5);
  padding: 30px 0px;
}
.contect-text .list-inline-item:not(:last-child) {
  margin-right: 2.5rem;
}
.text-18 {
  font-family: "Barlow-Medium";
  font-size: 18px;
}
.navbar-light .navbar-nav .nav-link {
  min-height: 60px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
button:focus:not(:focus-visible) {
  box-shadow: none;
}
.collapse.show {
  padding: 0px;
}
.carousel-item .col-lg-4:last-child .card-img-overlay {
  padding-right: 2.5rem;
}
/* new 8-9-22 */
.blue-btn {
  background: #1f3bb3;
  width: 144px;
  height: 44px;
  color: #fff;
  font-family: "Barlow-SemiBold";
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
/* .navbar .nav-item:not(:last-child) {
  margin-right: 35px;
} */

.dropdown-toggle::after {
  /* transition: transform 0.15s linear;  */
}

.show.dropdown .dropdown-toggle::after {
  /* transform: translateY(3px); */
}

.dropdown-menu {
  margin-top: 0;
}
nav .text-dark,
.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
}
.green-text {
  font-size: 18px;
  font-family: "Barlow-SemiBold";
  color: #597529;
}
.border-green {
  border: 1px solid #597529;
  padding: 20px 20px;
  border-radius: 10px;
}
.head-popup {
  color: #000000;
  font-size: 16px;
  font-family: "Barlow-SemiBold";
}
.gray-text {
  font-size: 13px;
  font-family: "Barlow-Regular";
  color: #6f6f6f;
}
/******************************************************* 1-9-2022 *********************************************/

ul.disp-flex {
  display: flex;
}
.addProfileBtn {
  float: right;
}
.div-footer {
  position: fixed;
  bottom: 0em;
  right: 1em;
  padding: 10px;
  margin-top: 0;
  width: 100%;
  background-color: #fff;
  border: 0;
}
.text-left.mb_10.prefill .fw-bold {
  width: 115px;
}

select.col-md-3.select-language {
  width: 200px;
  margin: 4px 10px;
}

.noborder {
  border: 0px;
}
.edit-profile i {
  margin-right: 15px;
}

/* 13-9-2022 */
.form-check-primary.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: #0a66c2;
}
.form-check-primary.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  /* background: #da7120; */
}
.bg-user {
  height: 40px;
  width: 40px;
  max-width: 40px;
  min-width: 40px;
}

/***************************** 15-9-22 ***********************/
.Home body {
  background: #e9e9e9;
  background: #f1f1f1;
  height: 100%;
}
.Home body,
.Home body :not(i) {
  font-family: Calibri_Regular;
}
html {
  height: 100%;
}
.header {
  background: #fff;
  padding: 10px;
  border-radius: 17px;
}
.box-home {
  background: #fff;
  padding: 25px 10px;
  border-radius: 20px;
}
.outline-hide:active,
.outline-hide:hover,
.outline-hide:focus,
.outline-hide:visited,
.outline-hide:link {
  outline: 0;
  box-shadow: none;
  border: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.border-secondary {
  border-color: #ccc !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.btn-outline-secondary {
  color: #ccc;
  border-color: #ccc;
  padding: 0.875rem 1.375rem;
  height: 2.4rem;
}
.no-gutters {
  padding-left: 12px;
  padding-right: 12px;
}
.search-input {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  background: #f5f5f5;
  height: 2.2rem;
}
.search-btn {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background: #f5f5f5;
  height: 2.2rem;
  display: flex;
  align-items: center;
}
.search-btn:hover,
.search-btn:active,
.search-btn:visited,
.search-btn:focus,
.search-btn:link {
  opacity: 1 !important;
  background: #f5f5f5;
  outline: 0;
  box-shadow: none;
  border-color: #ccc;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:visited {
  color: #ccc;
}
.search-input:focus {
  background: #f5f5f5;
}
.Home-Brand h1,
.Home-Brand h1 span {
  font-family: Calibri_Bold;
  font-size: 30px;
}
.box_shadow_1 {
  /* box-shadow: 0 2px 6px 0 rgb(23 23 23 / 32%); */
  box-shadow: 0 2px 15px 0 rgb(23 23 23 / 10%);
}
.text-link,
.text-link:active,
.text-link:link {
  color: #0a66c2;
}

/* a.text-link:hover, a.text-link:active {
  color: #fff;
} */
.badge.rounded-pill {
  min-height: 15px;
  min-width: 15px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background: url("../images/company.png") no-repeat;
  background-size: cover;
  margin: 0px;
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 15px 0 rgb(23 23 23 / 10%);
}
.icon-box i,
.main-right.icon-box .mdi {
  width: 18.13px;
}
.icon-box a:not(:nth-last-child) {
  margin-bottom: 2.8rem !important;
}
.Home .col-md-2,
.Home .col-md-5,
.Home .col {
  /* padding: 0 12px; */
}
.nowarp-text span,
.nowarp-text p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.main-company-list.icon-box i {
  margin-left: auto;
}
.img-jobs {
  border-radius: 10px;
  height: 45px;
  width: 45px;
}

.hexagon1 {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background: url("../images/tech-logo2.png") no-repeat;
  background-size: cover;
  margin: 0px;
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 15px 0 rgb(23 23 23 / 10%);
}
.hexagon2 {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background: url("../images/tech-logo3.png") no-repeat;
  background-size: cover;
  margin: 0px;
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 15px 0 rgb(23 23 23 / 10%);
}
.icon-box i,
.main-right.icon-box .mdi {
  font-size: 1.3rem;
}
.h_58 {
  height: 58%;
}
.height_33 {
  height: 31.5%;
}
.img-IT-rounded {
  border-radius: 12px;
}
.height_89 {
  height: 89%;
}
.img-profile {
  height: 50px;
  width: 50px;
}
.cursor {
  position: relative;
}
.cursor i {
  position: absolute;
  width: 1px;
  height: 45%;
  background-color: gray;
  left: 0%;
  top: 25%;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.cursor input:focus + i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.List_post li span {
  font-size: 12px !important;
}
.List_post li {
  margin-bottom: 10px;
}
.sticky-top {
}
.bg-body {
  background: #f1f1f1;
}
.vh-100 {
  height: 100vh;
}
.img-profile {
  border-radius: 15px;
  height: auto;
  width: 100px;
}
.text-green,
.text-green:hover,
.text-green:active,
.text-green:visited,
.text-green:link,
.text-green:checked {
  color: #27c955;
}
.img-profile-head {
  border-radius: 20px;
  height: 40px;
  width: 40px;
}
.img-jobs-chat {
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-bottom: 1.8rem;
}
.chat-box1 {
  background: rgba(46, 49, 146, 0.2);
  border: 2px solid rgba(46, 49, 146, 0.5);
  align-self: flex-start;
  color: rgb(46 49 146);
  padding: 5px;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.chat-box {
  background: rgba(0, 125, 197, 0.2);
  border: 2px solid rgba(0, 125, 197, 0.5);
  align-self: flex-start;
  color: #6c6c6c;
  padding: 5px;
  border-top-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.w_90 {
  width: 90%;
}
.w_95 {
  width: 95%;
}
.border-round {
  border-radius: 20px;
}
span.text-shadow {
  text-shadow: 1px 6px 14px #5f5c5c;
}
/* .mob-remove-padding{
  padding: 0;
}
.mob-remove-pe{
  padding-right: 0;
} */
.height_79 {
  height: 79.5%;
}

/* 16 Sep 2022 */
.dropdown-menu.dropdown-menu-right.navbar-dropdown.show {
  left: -11em !important;
}
.customedit {
  position: relative;
  bottom: 0px;
}

table.table.table-borderless.first-tab td {
  word-break: break-word;
}

img.caas_logo {
  width: 65px;
  margin-left: 5px;
}

img.caas_logo_big {
  width: 85px;
}

/* 19-9-2022 */
.body-content {
  padding: 0 2pc;
}
.box-home a {
  white-space: nowrap;
}
.h_60 {
  height: 60%;
}
.img-jobs {
  border-radius: 10px;
  height: 45px;
  max-width: 45px;
}
.h-100 {
  height: 100vh !important;
}
/* scrollbar */
.h_62 {
  height: 62%;
}
::-webkit-scrollbar-thumb {
  background: #a7c0da;
  border-radius: 8px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
img.caas_logo {
  width: 90px;
}

/* 20-9-22 */
html.acknowledgement .page-body-wrapper {
  padding-top: 35px;
}
.box-home.custom-scroll {
  overflow-y: auto;
  height: calc(100vh - 40vh);
  border-radius: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
p.mb-0.text-black-50.text-center.font-small {
  font-size: 14px;
}

.border-top-gray {
  border-top: 20px solid #f0f0f0;
  border-radius: 20px;
}
.box_1 .sticky-top .box_shadow_1:last-child,
.box_3 .box_shadow_1.sticky-top {
  overflow: hidden;
}
.box_1 .sticky-top .box_shadow_1:last-child:hover {
  overflow-y: hidden;
}
.box_3 .box_shadow_1.sticky-top:hover {
  overflow-y: hidden;
}
.w_17 {
  width: 17% !important;
}
.w_46 {
  width: 46% !important;
  padding: 10px;
}
.f_14 {
  font-size: 14px !important;
}
.f_18 {
  font-size: 18px !important;
}
/* 21-9-22 */
html.preview-page .page-body-wrapper {
  padding-top: 35px;
}
img.smi_icon {
  width: 30px;
}
a.send-btn {
  background: #0a58ca;
  padding: 8px 12px;
  border-radius: 30px;
  color: #fff !important;
  font-size: 19px;
  margin-left: 15px;
}
a.text-black-50.send-btn i {
  color: #fff;
}
input.w-375 {
  width: 375px;
}
a.accept-btn,
a.accept-btn:hover {
  background: linear-gradient(#1f3bb3, #162c89);
  border: unset;
  opacity: unset !important;
}
a.ignore-btn,
a.ignore-btn:hover {
  background: linear-gradient(#5d7b8b, #5d7b8b);
  border: unset;
  opacity: unset !important;
}
.modal-content.cardPad.card-body.border.border-radius.profile-card {
  border-top: 10px solid #fff !important;
  padding-top: 0;
}
.box-shadow.p-2.border-round.w-100.custom_width {
  width: 95% !important;
  margin-left: 15px !important;
}
i.fa-regular.icon-cursor.rotate-45 {
  rotate: 43deg;
}

.box_3:hover .ConversationsBody {
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
}
.box_1 .list-company {
  overflow-y: hidden; /* scroll */
  overflow-x: hidden;
}
.box_1 .border-top-gray:hover .list-company {
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
  overflow-x: hidden;
}

.box_3:hover .ConversationsBody {
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
  overflow-x: hidden;
}
/* 25/9/22 */
.main-body-section-social,
.main-body-section-social :not(i) {
  font-family: Calibri_Regular;
}

.box-home.box_shadow_1.icon-box.px-4:before,
.main-right.icon-box:before {
  content: unset;
}

a.collapsed {
  color: rgba(0, 0, 0, 0.5) !important;
}

.caasmembers1 a.collapsed {
  color: #0066b3 !important;
}
.ms-3 small {
  white-space: break-spaces;
  line-height: normal;
  display: block;
}
.hiddenInputFile {
  display: none;
}
.uploadFileIcon {
  cursor: pointer;
}
/* 27-9-22 */
.box-home.box_shadow_1.icon-box.px-4 {
  display: block;
}
.main-body-section.main-body-section-social {
  background-color: #f1f1f1;
}
.flex-row.createPostMediaPreviewDiv {
  border: 1px solid #d3d3d3;
  border-radius: 18px;
  padding: 15px;
}

a.position-relative.text-link.inline-block.mr_15 {
  border-right: 1px solid #ccc;
  padding-right: 15px;
}

button.text-black-50.like-btn {
  border: unset;
  padding-right: 0;
  position: relative;
  bottom: 3px;
  color: red;
}

video {
  /* height: 150px; */
  width: 100%;
}

span#likebtn {
  color: red;
}

@-webkit-keyframes like-btn {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes like-btn {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.like-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.like-btn:hover,
.like-btn:focus,
.like-btn:active {
  -webkit-animation-name: like-btn;
  animation-name: like-btn;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  /* background-color: red; */
}
input.form-control.comment-section {
  width: 450px;
  border-radius: 20px;
}

.liked {
  color: #ec1c24;
}

/* .maxHeight{
  height: 100%; 
 } */

.img-modal {
  padding-right: 5px;
  position: fixed;
  /* padding-top: 100px; */
  width: 100%;
  height: 100%;
}

.img-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #f1f1f1;
  padding: 10px 0;
  height: 150px;
}

.img-modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.zoomImgclose {
  position: absolute;
  /* top: 15px; */
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.zoomImgclose:hover,
.zoomImgclose:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .img-modal-content {
    width: 100%;
  }
}
.filter-option {
  position: relative;
}
.filter-option i {
  position: absolute;
  right: 1.5pc;
}
input::placeholder {
  font-size: 11px;
}
input::placeholder {
  overflow: visible;
}

a.groupname {
  color: #0b63bd;
  font-weight: bold;
}
.wrapper.bg-1 {
  background-color: #f0f1f0;
}

a.btn.groupcreate_btn {
  background-color: #044fa2;
  font-weight: bold;
  font-size: 15px;
}
a.text-black-50.d-block:hover {
  /* color: #f79727 !important; */
  color: #0a66c2 !important;
  font-weight: bold;
}
.w_66 {
  width: 66% !important;
}
input.height-searchbox {
  height: 3.02rem;
}
p.text-opacity {
  opacity: 0.7;
}

/* 10/10/22 - Aravind Sirivelli */
.emoji-icon {
  width: 45px;
}
.EmojiPickerReact {
  left: 680px;
  position: fixed !important;
  z-index: 10;
  top: 0;
}

.blur {
  filter: blur(2px);
}
.fit-div {
  width: 135px;
  /* border: 5px solid black; */
}

.fit-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.innerscroll {
  /* overflow-y: auto; */
}

.infinite-scroll-component.ins.mt-10 {
  position: relative;

  bottom: 25px;
}
.menu-nav {
  display: flex;
  justify-content: space-between;
}

.menu-item {
  color: #fcc;
  padding: 3px;
}

.three-dots:after {
  cursor: pointer;
  color: #444;
  content: "\2026";
  font-size: 20px;
  padding: 0 5px;
}

a {
  text-decoration: none;
  /* color: white; */
}

a div {
  padding: 2px;
}

.dropdown {
  /* position: relative;
  right: 10px;
  background-color: #808080;

  outline: none;
  /* opacity: 0; */
  /* z-index: -1;
  max-height: 0;
  transition: opacity 0.1s, z-index 0.1s;
  max-height: 5s; */
}

.dropdown-container:focus {
  /* outline: none; */
}

.dropdown-container:focus .dropdown {
  /* opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s;
  max-height: 0.2s; */
}
.fs_50 {
  font-size: 50px;
}
.fs_25 {
  font-size: 25px;
}
/* select#filterval {
  width: 160px;
} */

input.form-control.comment-section {
  width: 100%;
  border-radius: 20px;
}

.box-home.box_shadow_1.icon-box.px-4 {
  display: block;
  /* overflow-y: auto; */
}
.breakContent {
  word-break: break-word;
}

.whitetext {
  color: white !important;
  visibility: hidden;
}
li.nav-item.dropdown.d-block.user-dropdown.mr_20.mt_10 {
  z-index: 9999;
}

/* 18-10-2022 */
.f-100 {
  font-size: 100px;
}
ul.slides {
  display: block;
  position: relative;
  /* height: 600px; */
  height: 475px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}

.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

ul.slides input {
  display: none;
}

.slide-container {
  display: block;
}

.slide-image {
  display: block;
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: center;
  top: 2em;
  opacity: 1;
  transition: all 0.7s ease-in-out;
  /* background-color: #f1f1f1; */
  background-color: #fff;

  padding: 30px;
  border-radius: 25px;
}

.slide-image img {
  width: auto;
  /* min-width: 100%; */
  height: 100%;
  border-radius: 25px;
}
.slide-image video {
  width: auto;
  /* min-width: 100%; */
  height: 100%;
  border-radius: 25px;
}

.carousel-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 100px;
  /* line-height: 600px; */
  line-height: 400px;
  color: #212529;
}

.carousel-controls label {
  /* display: none; */
  position: absolute;
  padding: 0 20px;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}
/* .videoHeight-100 {
  height: 100%;
} */

.slide-image:hover + .carousel-controls label {
  opacity: 0.5;
}

.carousel-controls label:hover {
  opacity: 1;
}

.carousel-controls .prev-slide {
  /* width: 49%; */
  text-align: left;
  left: 0;
}

.carousel-controls .next-slide {
  /* width: 49%; */
  text-align: right;
  right: 0;
}

.carousel-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}

.carousel-dots .carousel-dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: lightslategray;
  /* background-color:#000000; */
  /* #fff; */
  opacity: 0.5;
  margin: 10px;
}

.main-carousel-dot {
  /* background-color: #000000 !important; */
  background-color: #0d6efd !important;
}
.other-carousel-dot {
  background-color: lightslategray;
}
.ease-io-tran {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}
/* animation styles */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fadeInClass {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: linear;
}

input:checked + .slide-container .slide-image {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .carousel-controls label {
  display: block;
}

input#img-1:checked ~ .carousel-dots label#img-dot-1,
input#img-2:checked ~ .carousel-dots label#img-dot-2,
input#img-3:checked ~ .carousel-dots label#img-dot-3,
input#img-4:checked ~ .carousel-dots label#img-dot-4,
input#img-5:checked ~ .carousel-dots label#img-dot-5,
input#img-6:checked ~ .carousel-dots label#img-dot-6 {
  opacity: 1;
}

input:checked + .slide-container .nav label {
  display: block;
}

.error-shadow {
  box-shadow: 0 0 0.5em red;
}

.chat_entry > .ConversationsBody {
  /* height: 468.2px !important; */
  height: calc(100vh - 32vh);
}
a.mini {
  font-size: 23px;
}

/*popup*/

.l_c_h {
  width: 19.7%;
  position: fixed;
  bottom: 0;
  border: 1px solid #ccc;
  right: 0px;
  background: #fff;
  z-index: 5000;
  display: block;
  padding: 0;
  border-radius: 20px 0 0 0;
}
.l_c_h .c_h {
  cursor: pointer;
  border-radius: 0px;
  /*background:#c61432;*/
  background: #0a58ca;
  line-height: 34px;
  border-radius: 20px 0 0 0;
}
.l_c_h .left_c {
  color: #ebebeb;
  width: 150px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: start;
}
.l_c_h .right_c {
  text-align: center;
  /*background:#DE4A4A;*/
  background: #0a58ca;
  line-height: 36px;
  border-radius: 20px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.l_c_h .right_c a {
  color: #ebebeb;
  border-radius: 3px;
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset,
    0 1px 2px rgba(0, 0, 0, 0.05) !important; */
  cursor: pointer !important;
  font-size: 16px;
  line-height: normal !important;
  margin-top: 0 !important;
  padding: 1px 0px !important;
  text-align: center !important;
  text-decoration: none;
  font-weight: 600;
}
.clear {
  clear: both;
}
.chat_container {
  /*		display:none;*/
  padding: 5px 0px;
}
.chat_container .no_provider {
  text-align: left;
  color: #5e5652;
  font-size: 12px;
  width: 300px;
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5px;
}
.chat_container .footer_c {
  text-align: left;
  color: #5e5652;
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}
.chat_container .footer_c a {
  text-decoration: underline;
  color: #777;
  font-size: 11px;
}
.chat_container .chat_entry {
  margin: 10px;
  margin-top: 0px;
}
.chat_container .chat_entry p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.chat_container .chat_entry h4 {
  text-align: left;
  color: #1a1a1a;
  font-weight: 400;
  font-size: 16px;
  margin-top: 0px;
}
.chat_container .chat_entry input[type="text"] {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #999;
  font-size: 13px;
  padding: 8px 8px;
  margin-bottom: 5px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  /* 	height:30px; */
  font-family: Arial, Helvetica, sans-serif;
}
.chat_container .chat_entry input[type="text"]:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
  border-color: #66afe9;
}
.chat_container .chat_entry input[type="submit"] {
  padding: 5px 12px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0px;
  color: #fff;
  /*background-color: #DD4A4A;*/
  background-color: #2dc3e8 !important;
  font-family: Arial, Helvetica, sans-serif;
}
.chat_container .chat_entry input[type="submit"]:hover {
  background-color: #c64545;
}
.form-error {
  display: none;
}
.chat_message {
  height: 250px;
  overflow-y: scroll;
  padding: 10px;
  border-bottom: 1px solid #222222;
  padding-top: 10px;
}
.chat_message .message_contain {
  margin: 5px;
  background: #fcf8e2;
  padding: 5px;
}
.chat_message .user_message {
  background: #f5f5f5;
}
.chat_message .message_contain .header_image {
  float: left;
  width: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.chat_message .message_contain .chat_mess {
  font-size: 13px;
  color: #444444;
  margin-top: 0px;
}
.chat_message .message_contain .chat_date {
  color: #777;
  font-size: 11px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.chat_text_area {
  margin: 5px;
}
.chat_text_area .messag_send {
  width: 100%;
  resize: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  padding: 5px;
  font-size: 13px;
  height: 35px;
}
.chat_text_area .messag_send:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
  border-color: #66afe9;
}
.left {
  float: left;
}
.right {
  float: right;
}
.left_icons {
  width: 35px;
  height: auto;
  text-align: center;
  color: #999;
  /*background:#DE4A4A;*/
  background: #ff8f32;
  font-size: 15px;
}
.left_icons a {
  color: #fff;
  font-weight: normal;
}
.center_icons {
  text-align: center;
  padding: 2px 0px 0px 5px;
}
.logout img {
  margin-top: 8px;
}
.ConversationsBody.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-dialog.modal-md.chat-popup {
  right: 18.3em;
  position: absolute;
  width: 25% !important;
  margin: 0 auto;
}

.chatbox-holder {
  position: fixed;
  right: 18em;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  height: 0;
}

.chatbox {
  width: 400px;
  /* height: 400px; */
  margin: 0 20px 0 0;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  border-radius: 10px 10px 0 0;
  background: white;
  bottom: 0;
  transition: 0.1s ease-out;
}

.chatbox-top {
  position: relative;
  display: flex;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
  background: rgb(10 88 202);
}

.chatbox-icons {
  padding: 0 10px 0 0;
  display: flex;
  position: relative;
}

.chatbox-icons .fa {
  background: rgba(220, 0, 0, 0.6);
  padding: 3px 5px;
  margin: 0 0 0 3px;
  color: white;
  border-radius: 0 5px 0 5px;
  transition: 0.3s;
  border-radius: 5px 0 5px 0;
  background: rgba(220, 0, 0, 1);
}

.chatbox-icons .fa:hover {
  border-radius: 5px 0 5px 0;
  background: rgba(220, 0, 0, 0.6);
}

.chatbox-icons a,
.chatbox-icons a:link,
.chatbox-icons a:visited {
  color: white;
}

.chat-partner-name,
.chat-group-name {
  flex: 1;
  padding: 0 0 0 55px;
  font-size: 15px;
  font-weight: bold;
  color: #30649c;
  text-shadow: 1px 1px 0 white;
  transition: 0.1s ease-out;
}

.status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #cacaca;
  margin: 0 3px 0 0;
}

.online {
  background: #27c955;
}

.away {
  background: #ffae00;
}

.donot-disturb {
  background: #ff4343;
}

.chatbox-avatar {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  background: white;
  padding: 3px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  position: absolute;
  transition: 0.1s ease-out;
  bottom: 3px;
  left: 6px;
}

.chatbox-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-messages {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  /* flex: 1; */
  height: calc(100vh - 45vh);
  overflow-y: auto;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.message-sender {
  font-size: 14px;
  margin: 0 0 15px;
  color: #000;
  align-self: flex-start;
}

.message-sender a,
.message-sender a:link,
.message-sender a:visited,
.chat-partner-name a,
.chat-partner-name a:link,
.chat-partner-name a:visited {
  color: #fff;
  text-decoration: none;
}

.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: rgba(0, 125, 197, 0.2);
  border: 2px solid rgba(0, 125, 197, 0.5);
  color: #6c6c6c;
  font-size: 14px;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid rgba(0, 125, 197, 0.5);
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-partner {
  background: rgba(46, 49, 146, 0.2);
  border: 2px solid rgba(46, 49, 146, 0.5);
  align-self: flex-start;
  color: rgb(46 49 146);
}

.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 114, 135, 0.2);
  border-bottom: 10px solid rgba(46, 49, 146, 0.5);
  border-left: none;
}

.chat-input-holder {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.chat-input {
  resize: none;
  padding: 5px 10px;
  height: 40px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #999999;
  flex: 1;
  border: none;
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.chat-input:focus,
.message-send:focus {
  outline: none;
}

.message-send::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.message-send {
  -webkit-appearance: none;
  background: #0a58ca;
  /* background: -moz-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: -webkit-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: -o-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: -ms-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: linear-gradient(180deg, #00d8ff, #00b5d6); */
  color: white;
  font-size: 14px;
  padding: 0 15px;
  border: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.attachment-panel {
  padding: 3px 10px;
  text-align: right;
}

.attachment-panel a,
.attachment-panel a:link,
.attachment-panel a:visited {
  margin: 0 0 0 7px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

.chatbox-min {
  margin-bottom: -362px;
  /*   height: 46px; */
}

.chatbox-min .chatbox-avatar {
  width: 60px;
  height: 60px;
}

.chatbox-min .chat-partner-name,
.chatbox-min .chat-group-name {
  padding: 0 0 0 75px;
}

.settings-popup {
  background: white;
  border-radius: 20px/10px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 13px;
  opacity: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 33px;
  transition: 0.15s;
  transform: scale(1, 0);
  transform-origin: 50% 0;
  width: 120px;
  z-index: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}

.settings-popup:after,
.settings-popup:before {
  border: 7px solid transparent;
  border-bottom: 7px solid white;
  border-top: none;
  content: "";
  position: absolute;
  left: 45px;
  top: -10px;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
}

.settings-popup:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.25);
  top: -11px;
}

.settings-popup:after {
  border-top-color: transparent;
}

#chkSettings {
  display: none;
}

#chkSettings:checked + .settings-popup {
  opacity: 1;
  transform: scale(1, 1);
}

.settings-popup ul li a,
.settings-popup ul li a:link,
.settings-popup ul li a:visited {
  color: #999;
  text-decoration: none;
  display: block;
  padding: 5px 10px;
}

.settings-popup ul li a:hover {
  background: rgba(0, 0, 0, 0.05);
}

/*28-10-22*/
/* a.collapsed {
  vertical-align: bottom;
} */
label.form-check-label {
  font-size: 13px !important;
}

.d-flex.justify-content-start.align-items-center {
  margin-bottom: 10px;
}

#subscribeToGroupBtn {
  background-color: #fff !important;
  color: #034b9c !important;
  float: right;
  font-size: 18px;
  padding: 9px;
  border-radius: 16px;
  float: right !important;
  height: 42px;
  position: absolute;
  right: 20px;
}

button.commentSubmitBtn,
button.commentSubmitBtn:hover,
button.commentSubmitBtn:focus,
button.commentSubmitBtn:visited {
  background-image: url("../images/commentImg.png");
  width: 14% !important;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 50%;
  padding: 15px;
  left: 10px;
  border-radius: 20px;
}

/* AS */
.dropbtnPostModify {
  background-image: url("../images/three_dots.png");
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
/* 
.dropbtnPostModify:hover, .dropbtnPostModify:focus {
  background-color: #2980B9;
} */

.dropdownPost {
  display: inline-block;
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.post-dropdown-content {
  /* display: none; */
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 10px;
}

.post-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownPost a:hover {
  background-color: #ddd;
}
.bar_login {
  padding: 10px;
  margin: 10px -1px;
  color: #333;
  background: #fafafa;
  border: 1px solid #ccc;
}
.error_login {
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
}
i.ico {
  display: inline-block;
  width: 20px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
}

#cio_reg {
  pointer-events: none;
}

.lightGallery {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

.lightGallery .image-tile {
  width: 100%;
  margin: 0;
  min-height: 190px;
  display: grid;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  box-shadow: 1px -1px 13px 5px #0000001c;
  align-items: end;
}

.lightGallery .image-tile > div {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightGallery .image-tile img {
  object-fit: contain;
  height: inherit;
  width: inherit;
}
.caasMemberYouMayKnow {
  word-break: break-word;
}
.border-radius-20 {
  border-radius: 20px;
}
.word-wrap {
  word-break: break-all;
}
/* 4-11-22 */
/* .infinite-scroll-component.ins.mt-10 {
  height: unset !important;
} */

.border-radius-20 {
  border-radius: 20px;
}

.highlight {
  display: inline;
  padding: 0.25em 0;
  background: #f9e990; /* #FFC107; */
  /* color: #ffffff; */
  /* box-shadow: .5em 0 0 #FFC107, -.5em 0 0 #FFC107; */
}

.filterLabel {
  white-space: nowrap;
}

.caasmemberfilterDiv {
  overflow-y: auto !important;
}
/* 9-11-22 */
p.profile-name.pb_0 {
  color: #034ea2;
  font-size: 22px;
  font-family: "Barlow-Bold";
  white-space: nowrap;
}
.font-family-inherit {
  font-family: "Barlow-Medium";
}
img.img-fluid.edit-profile-pic {
  max-width: 200px;
}
span.badge.badge-opacity-warning.cio-code.me-3 {
  font-weight: bold;
  background: #db7629;
  color: #fff;
  vertical-align: bottom;
}
span.mention {
  cursor: pointer;
}
span.mention,
.noselect {
  user-select: none;
}
button.mb-0.w-150.me-4.border-primary {
  width: auto;
  display: inline-flex;
}
i.fa-solid.fa-plus.w-10.btn-primary.add-pi.float-right.btn-rounded.btn-icon.addbox {
  border-radius: 20px;
  padding: 5px;
}
a.d-blocktext-black-50.text-dark.fs-5.fw-bold {
  text-transform: initial;
  font-size: 13px !important;
}
i.mdi.mdi-playlist-plus.text-success.addempSkills.fs-5.ms-3.mt-2,
i.fa-solid.fa-trash.remove.fs-6.ms-3.mt-2.text-muted,
i.fa-solid.fa-plus.w-10.btn-primary.add-pi.float-right.btn-rounded.btn-icon.addbox {
  cursor: pointer;
}
i.mdi.mdi-playlist-plus.text-success.addempSkills.fs-5.ms-3.mt-2 {
  color: #1f3bb3 !important;
  font-size: 26px !important;
}
i.fa-solid.fa-trash.remove.fs-6.ms-3.mt-2.text-muted {
  position: relative;
  top: 6px;
  font-size: 26px !important;
}

.user_icon {
  height: 100px;

  display: flex;

  margin: 0 auto;
}
.myconnections .col_myconnection .box_1,
.caasmembers1 .cassmembers2 .box_1,
.myconversationsss .myconversations1 .box_1,
.groupnotificationss .My_notifications .box_1,
.enterprisenetworkss .My_enterprisenetwork .box_1 {
  grid-area: box_1;
}
.myconnections .col_myconnection .box_2,
.caasmembers1 .cassmembers2 .box_2,
.myconversationsss .myconversations1 .box_2,
.groupnotificationss .My_notifications .box_2,
.enterprisenetworkss .My_enterprisenetwork .box_2 {
  grid-area: box_2;
}
.myconnections .col_myconnection .box_3,
.caasmembers1 .cassmembers2 .box_3,
.myconversationsss .myconversations1 .box_3,
.groupnotificationss .My_notifications .box_3,
.enterprisenetworkss .My_enterprisenetwork .box_3 {
  grid-area: box_3;
}
.myconnections .col_myconnection .box_4,
.caasmembers1 .cassmembers2 .box_4,
.myconversationsss .myconversations1 .box_4,
.groupnotificationss .My_notifications .box_4,
.enterprisenetworkss .My_enterprisenetwork .box_4 {
  grid-area: box_4;
}

.myconnections .col_myconnection,
.caasmembers1 .cassmembers2,
.myconversationsss .myconversations1,
.groupnotificationss .My_notifications,
.enterprisenetworkss .My_enterprisenetwork {
  column-gap: 1.5em;

  display: grid;

  grid-area: content;

  grid-template-rows: auto;

  grid-template-columns: 16% auto 16% 16%;
  grid-template-columns: 15% 2fr 15% 15%;
  grid-template-columns: 1fr;
  grid-template-areas:
    "box_1"
    "box_2"
    "box_3"
    "box_4";
}
.My_home .box_1 {
  grid-area: box_1;
}
.My_home .box_2 {
  grid-area: box_2;
}
.My_home .box_4 {
  grid-area: box_4;
}

.My_home,
.My_groups,
.My_grouplist {
  column-gap: 1.5em;

  display: grid;

  grid-area: content;

  grid-template-rows: auto;

  grid-template-columns: 17% auto 17%;
  grid-template-columns: minmax(0, 3.435fr) minmax(0, 12fr) minmax(0, 3.435fr);
  grid-template-columns: 1fr;
  grid-template-areas:
    "box_1"
    "box_4"
    "box_2";
}

@media only screen and (min-width: 575px) {
  .myconnections .col_myconnection,
  .caasmembers1 .cassmembers2,
  .myconversationsss .myconversations1,
  .groupnotificationss .My_notifications,
  .enterprisenetworkss .My_enterprisenetwork {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "box_1  box_4"
      "box_3  box_3"
      "box_2  box_2";
  }
  .My_home,
  .My_groups,
  .My_grouplist {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "box_1  box_4"
      "box_2  box_2";
  }
}
@media only screen and (min-width: 769px) {
  .myconnections .col_myconnection,
  .caasmembers1 .cassmembers2,
  .myconversationsss .myconversations1,
  .groupnotificationss .My_notifications,
  .enterprisenetworkss .My_enterprisenetwork {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "box_1  box_3 box_4"
      "box_2  box_2 box_2";
  }
}
@media only screen and (min-width: 1025px) {
  .myconnections .col_myconnection,
  .caasmembers1 .cassmembers2,
  .myconversationsss .myconversations1,
  .groupnotificationss .My_notifications,
  .enterprisenetworkss .My_enterprisenetwork {
    grid-template-columns: 17% 1fr 17% 17%;
    grid-template-areas: "box_1  box_2 box_3 box_4";
  }
  .My_home,
  .My_groups,
  .My_grouplist {
    grid-template-columns: 17% 1fr 17%;
    grid-template-areas: "box_1  box_2 box_4";
  }
}
@media only screen and (max-width: 768px) {
  .mob_res_grid {
    display: grid !important;
    grid-template-columns: auto 1fr auto auto;
    align-items: center !important;
    gap: 0.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .slide-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slide-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
/* @media only screen and (max-width: 480px){
  .lightGallery {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}
.myconnections .col_myconnection,.caasmembers1 .cassmembers2,.myconversationsss .myconversations1, .groupnotificationss .My_notifications,.enterprisenetworkss .My_enterprisenetwork{
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: auto;

} */

.TopFixed .stickyTop {
  position: fixed;

  top: 0;

  padding-right: 36px;
}

.personalwall body > #root,
.groupwall body > #root,
.caasmembers1 body > #root,
.myconnections body > #root,
.grouplistt body > #root,
.myconversationsss body > #root,
.groupnotificationss body > #root,
.enterprisenetworkss body > #root {
  position: absolute;
  width: 100%;
  height: 100vh;
}
.announcements-container .companyannouncements1,
.box_3 .connectionss {
  overflow: hidden;
}
.announcements-container:hover .companyannouncements1,
.box_3:hover .connectionss {
  overflow-y: auto;
  padding-right: 15px;
}
.box_3 select {
  padding-right: 4.25rem;
  text-overflow: ellipsis;
}
.slide-container .slide-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-image img {
  width: 80%;
  max-width: 100%;
  height: 100%;
  border-radius: 25px;
}
.slide-image video {
  width: 80%;
  max-width: 100%;
  height: 100%;
  border-radius: 25px;
}
.groupname_nowrap {
  display: grid;
}
.list-company span,
.list-company a {
  font-size: 14px;
}
.groupname_nowrap span:last-child {
  font-size: 13px;
}
.groupname_nowrap i {
  font-size: 1rem;
}

.caasworkspaceicon {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 1.3rem; */
}
.emojicommentdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Post Popup */
.quill span {
  font-size: 14px;
}
.quill .ql-toolbar.ql-snow .ql-formats {
  margin-right: 5px;
}
.quill .ql-snow .ql-picker.ql-font {
  width: 90px;
}
.quill .ql-snow.ql-toolbar button,
.quill .ql-snow .ql-toolbar button {
  height: 22px;
  width: 24px;
}
#CreatePostModal .modal-content {
  border-radius: 20px;
}
#submitPostBtn {
  opacity: 1;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 25px;
  min-width: auto;
}
@media (min-width: 992px) {
  #CreatePostModal .modal-lg,
  #CreatePostModal .modal-xl,
  #creategroup .modal-lg {
    max-width: 56%;
    margin-top: 6pc;
    padding-left: 0.5pc;
  }
}

#creategroup
  #createGroupForm
  .form-check
  .form-check-label
  input[type="radio"]
  + .input-helper:after {
  top: 3.36px;
  left: 3.55px;
}

#creategroup
  #createGroupForm
  .form-check
  .form-check-label
  input[type="radio"]
  + .input-helper:before {
  width: 15.5px;
  height: 15px;
}
#createGroupForm
  .form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #4c8ac0;
  border-color: #4c8ac0;
  border-width: 0;
}
#createGroupForm
  .form-check
  .form-check-label
  input[type="radio"]
  + .input-helper:before {
  border: solid #4c8ac0;
}
#createGroupForm
  .form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:after {
  filter: alpha(opacity=100);
  line-height: 1.5;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
#creategroup .modal-content,
.btn {
  border-radius: 20px;
}
/* .modal-open > div {
  position: relative;
} */

.personalwall body.modal-open > #root,
.groupwall body.modal-open > #root,
.caasmembers1 body.modal-open > #root,
.myconnections body.modal-open > #root,
.grouplistt body.modal-open > #root,
.myconversationsss body.modal-open > #root,
.groupnotificationss body.modal-open > #root,
.enterprisenetworkss body.modal-open > #root {
  position: relative;
  width: 100%;
  height: 100vh;
}

#imgModal {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#imgModal .img-modal-content {
  object-fit: contain;
  height: 82vh;
  width: auto;
}

.word-wrap-ellipss {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding: 0 15px;
}

.text-white {
  color: #fff !important;
}

.chatconvo {
  border-radius: 20px 20px 0 0;
  background: #0a57c8;
  color: #fff;
}
.box-home.chatconvoradius {
  border-radius: 0 0 20px 20px;
}
.myconversationsss,
.myconversationsss body {
  background: #f1f1f1;
}
.myconversationsss .img-jobs,
.personalwall .img-jobs,
.groupwall .img-jobs,
.caasmembers1 .img-jobs,
.myconnections .img-jobs,
.grouplistt .img-jobs,
.groupnotificationss .img-jobs,
.enterprisenetworkss .img-jobs {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.caasmembers1 .img-profile {
  border-radius: 15px;
  height: 75px;
  width: 75px;
}
/*14/11/22*/
.createPostMediaPreviewDiv {
  flex-wrap: wrap;

  row-gap: 10px;
}

.createPostMediaPreviewDiv a img {
  height: 129px;

  width: 129px;
}
body .mob_res_grid {
  grid-gap: 0.5rem;
  align-items: center !important;
  display: grid !important;
  gap: 1rem;
  grid-template-columns: auto 1fr auto;
  width: 100%;
}
body .mob_res_grid > div {
  width: 100%;
}
body .emojicommentdiv {
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto;
}

.editGroupIcon {
  right: 0;
}

.sidebar-icon-only .sidebar .nav .nav-item.active .nav-link i.menu-icon {
  color: #007dc5 !important;
}
.joinus-link.container-fluid.px-5 {
  text-align: left;
}
a.mt-2.text-gray.btn.btn-sm.btn-primary {
  background-color: #0066b3;
  border: 1px solid #0066b3;
}
a.savepreview {
  background-color: #dcdcde !important;
  padding-top: 10px;
  color: #000000 !important;
  border: 1px solid #abaaaa !important;
}
a.text-link.profileupdate_btncv {
  background-color: #007dc5;
  padding: 10px;
  border-radius: 15px;
  color: #fff;
}

.w-20 {
  width: 35%;
}

.unsetCase {
  text-transform: unset;
}

.profilepicframe {
  background-color: #f4f5f7;
  border-radius: 35px;
  padding: 1.3rem;
  border: 1px solid;
}
.h-130 {
  height: 130px;
}
.w-130 {
  width: 130px;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
    url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
    no-repeat;
  z-index: 1;
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#NDA .modal-content {
  width: 120% !important;
}

.s1 {
  display: none;
}

#profileviewimagealign .image-tile {
  align-items: center !important;
}
.lightGallery1 {
  margin: 0;
  width: 100%;
  box-shadow: -1px -1px 6px 6px #00000040;
  border-radius: 20px;
}
.editprofileimagesection {
  max-width: 100%;
  max-height: 220px;
  height: 220px;
  padding: 20px !important;
}
.caasmemberprofile {
  max-height: 180px;
  width: 100%;
  border-radius: 15px;
  min-height: 180px;
}
.landing-section-border-radius {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* li.profile-list-details.ml_30 {
  margin-left: 7pc;
}
li.education-list-details.ml_30, li.expereince-list-details.ml_30, li.certificate-list-details.ml_30 {
  margin-left: 10pc;
} */

div#NDA_Body_Id p,
div#NDA_Body_Id b,
span.p,
div#NDA_Body_Id a {
  font-family: Barlow-Regular !important;
}
img.signature {
  max-width: 100%;
}
.imp_size {
  font-size: 12px !important;
}
.m-w-150 {
  min-width: 150px;
}
i.mob-edit-industrypreview-btn {
  right: 0;
  margin-right: 30px;
  margin-top: 15px;
}
.right-0 {
  right: 0;
}
li.ml-pc-8 {
  margin-left: 10pc;
}
li.ml-pc-3 {
  text-align: left;
  margin-left: 6pc;
}
li.ml-pc-2 {
  margin-left: 2pc;
}
li.profile-list-details b {
  font-weight: bold;
}
.text-color-default {
  color: #1f1f1f;
}
.linked-inimg {
  margin-bottom: 1px;
  height: 20px;
  width: 20px;
}
/* .seetext-primary
{
  color:#002d62 !important;
} */

.caasmembers1 .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.caasmembers1 .seetext-primary {
  display: inline-block;
}

.myconnections .invitation_style {
  display: inline-block;
  width: auto;
  border: 0;
}

.myconnections a.collapsed {
  color: #0066b3 !important;
}
.myconnections .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.seetext-primary {
  display: inline-block;
  width: auto;
  border: 0;
}
.editiconsize {
  font-size: 1.3rem !important;
}

.form-switch.form-check.ms-2.margin-0 {
  margin: 0;
}

.form-switch .form-check-input {
  margin-left: 0.1em;
  margin-right: 0.5em;
  width: 3em;
  position: relative;
  bottom: 4px;
  height: 1.1em;
}

.active-status:checked {
  background-color: green;
  border: unset;
}

.skillstablesection th,
.skillstablesection td {
  padding: 10px !important;
}

.m-auto {
  margin: auto;
}

.pagination-success .page-item.active .page-link {
  background: #0a66c2;
  border-color: #0a66c2;
}

a.btn.addindustrybtn {
  font-weight: bold;
  background-color: #0a66c2;
  border-radius: 10px;
  border: unset;
  margin-bottom: 10px;
}

/* 2-01-23 profile page */
/* .infinite-scroll-component.ins.mt-10 {
    height: unset !important;
  } */

button.post-filter-btn {
  width: 150px;
  background-color: #222e71;
  border: unset;
}

.caasmemberfilterDiv {
  overflow-y: auto;
}
/* //added */
.card {
  display: flex;
  /* flex-direction: row; */
  width: 100%;
}
.previewpagenew .card {
  flex-direction: row;
}
/* //added */
.card .card-left {
  width: 40%;
}

.card .card-left .card-img {
  width: 100%;
  height: 55vh;
  position: relative;
}
/* .card .card-left .card-img::before {content: "";background: url(../images/elon-img.jpg);width: 100%;height: 100%;background-repeat: no-repeat;background-size: cover;display: block;border-radius: 8px 0px 0px 8px;} */

.card .card-left .card-img img {
  width: 100%;
}

.card .card-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
}

.card .card-right .card-title {
  font-size: 20px;
  color: hsl(214, 17%, 51%);
}

.card .card-right .card-text {
  font-size: 14px;
  color: hsl(212, 23%, 69%);
  margin-top: 20px;
}

.card .card-right .card-right-body {
  display: flex;
  margin-top: 26px;
}

.card .card-right .card-right-body .card-rb-1 {
  flex-grow: 1;
}

.card .card-right .card-right-body .card-rb-1 img {
  width: 45px;
  border-radius: 50%;
}

.card .card-right .card-right-body .card-rb-2 {
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 3px;
}

.card .card-right .card-right-body .card-rb-2 .card-rb-2-title {
  color: hsl(214, 17%, 51%);
}

.card .card-right .card-right-body .card-rb-2 .card-rb-2-text {
  color: hsl(212, 23%, 69%);
}

.card .card-right .card-right-body .card-rb-3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card .card-right .card-right-body .card-rb-3 .card-rb-3-inner {
  background: url(https://rvs-article-preview-component.netlify.app/images/icon-share.svg)
    hsl(210, 46%, 95%);
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
}

.card .card-right .card-right-body .card-rb-3 .card-rb-3-inner:hover {
  background: url(https://rvs-article-preview-component.netlify.app/images/icon-share-white.svg)
    hsl(217, 19%, 35%);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.card .card-right .card-right-body .card-rb-3 .card-rb-3-inner-before {
  position: absolute;
  width: 275px;
  height: 55px;
  background: hsl(217, 19%, 35%);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  color: white;
  box-sizing: border-box;
  align-items: center;
  top: -80px;
  left: -110px;
  opacity: 0;
  justify-content: center;
  padding: 0px 20px;
  z-index: 2;
}

.card .card-right .card-right-body .card-rb-3 .card-rb-3-inner-before::after {
  content: "";
  position: absolute;
  top: 40px;
  left: 50%;
  width: 40px;
  height: 40px;
  background: hsl(217, 19%, 35%);
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}

.card
  .card-right
  .card-right-body
  .card-rb-3
  .card-rb-3-inner:hover
  + .card-rb-3-inner-before {
  opacity: 1;
}

.before-1 {
  flex-grow: 2;
  letter-spacing: 7px;
  color: hsl(212, 23%, 69%);
  display: flex;
  justify-content: center;
}

.before-2 {
  flex-grow: 1;
}

.before-3 {
  flex-grow: 1;
}

.before-4 {
  flex-grow: 1;
}

@media screen and (max-width: 850px) {
  .card .card-right {
    width: 100%;
    padding: 40px 25px 20px;
  }
  .card .card-left .card-img {
    height: 210px;
  }
  .card .card-left .card-img::before {
    border-radius: 8px 8px 0px 0px;
  }
  .card .card-right .card-right-body .card-rb-3 .card-rb-3-inner-before {
    top: -3px;
    left: -235px;
    width: 230px;
  }
  .card .card-right .card-right-body .card-rb-3 .card-rb-3-inner-before::after {
    content: unset;
  }
}

button.skills-btn {
  width: auto;
  border-color: #6d7f97;
  color: #30363d;
}

button.skills-btn:hover {
  border-color: #6d7f97;
  background-color: #6d7f97;
}

.bullet-line-list:after {
  content: "";
  border: 1px solid #dee2e6;
  position: absolute;
  top: 3px;
  bottom: 0;
  left: 7px;
}

.add-profile {
  padding: 5px;
  border-radius: 10px;
}

.position-relative.p-5.text-link.inline-block {
  background-color: #dcf5fe;
  border-radius: 35px;
  border: 1px solid;
}
/* 2-01-23 profile page */

html.previewpage body,
html.editprofile body {
  background-color: #f4f5f7;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.border-radius-15 {
  border-top: 3px solid #1f3bb3;
  border-radius: 15px;
}

.nav-pills-success .nav-link {
  color: #0d6efc;
}
/* 20-12-22 */

.experience-section {
  overflow-x: auto;
  margin-left: 2pc !important;
  margin-bottom: 20px;
}

.card-rb-2-title.card-text.fw-7.mt-3.text-left.text-profile-design {
  color: hsl(212, 23%, 69%);
}

.border-right-1 {
  border-right: 1px solid #d3d3d3;
}

img.img-lg.view-profile-image {
  width: 100%;
  height: 45vh;
  border-radius: 25px !important;
}

.nav-pills.nav-pills-custom .nav-link.active {
  background: #007dc5;
  color: #ffffff;
}

.card .card-title.card-title-dash {
  text-align: left;
}

img.img-lg.viewprofile.mb-3 {
  width: 75%;
  height: unset;
  border-radius: 25px;
}
/* caroual */

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

.w-inherit {
  width: inherit;
}

.border-radius-3 {
  border-radius: 10px;
}

.nav-pills.nav-pills-custom .nav-link {
  border-radius: 14px;
}

.pie-chart {
  background: radial-gradient(
      circle closest-side,
      white 0,
      white 38.88%,
      transparent 38.88%,
      transparent 54%,
      white 0
    ),
    conic-gradient(
      from 107deg,
      #4e79a7 0,
      #4e79a7 38%,
      #f28e2c 0,
      #f28e2c 61%,
      #edc949 0,
      #edc949 100%
    );
  position: relative;
  width: 100%;
  min-height: 180px;
  margin: 0;
  outline: 1px solid #ccc;
}

.pie-chart h2 {
  position: absolute;
  margin: 1rem;
}

.pie-chart cite {
  position: absolute;
  bottom: 0;
  font-size: 80%;
  padding: 1rem;
  color: gray;
}

.pie-chart figcaption {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: smaller;
  text-align: right;
}

.pie-chart span:after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.4em;
  height: 0.8em;
  border-radius: 0.2em;
  background: currentColor;
}

button.btn-bg-theme {
  background-color: #0d6efc !important;
  border: unset;
}

.sidebar-icon-only .sidebar .nav .nav-item.active .nav-link i.menu-icon {
  color: #007dc5 !important;
}
/* 3-1-23 */

.form-switch.form-check.ms-2.margin-0 {
  margin: 0;
}

.form-switch .form-check-input {
  margin-left: 0.1em;
  margin-right: 0.5em;
  width: 3em;
  position: relative;
  bottom: 4px;
  height: 1.1em;
}

.active-status:checked {
  background-color: green;
  border: unset;
}

.skillstablesection th,
.skillstablesection td {
  padding: 10px !important;
}

.m-auto {
  margin: auto;
}

.pagination-success .page-item.active .page-link {
  background: #0a66c2;
  border-color: #0a66c2;
}

a.btn.addindustrybtn {
  font-weight: bold;
  background-color: #0a66c2;
  border-radius: 10px;
  border: unset;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar {
    width: 18%;
  }
}
/* 4-1-23 */

.previewpagenew .nav-pills .nav-link {
  border: 0px solid #0d6efc;
  padding: 0.2rem 1.75rem;
  border-radius: 20px;
}

.previewpagenew nav.profile-section.pl-5.pr-5 {
  padding-left: 0;
  padding-right: 0;
}

.bg-profilesection {
  background: linear-gradient(#fff, #f0f4f7);
}

.previewpagenew .card-body.border-radius-15 {
  border-top: 3px solid #0d6efd;
}

.previewpagenew .card.card-rounded {
  border-radius: unset;
}

.previewpagenewa.btn.btn-primary.mw-auto {
  min-width: 80px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.previewpagenew .border_radius {
  background: #0d6efc;
  color: white !important;
  padding: 6px;
  border-radius: 20px;
}
.previewpagenew .tab-content {
  border: unset !important;
}
.previewpagenew .lightGallery .gallery_img {
  padding: 15px;
  border-radius: 20px;
}
/* 4-1-23 */

.lightGallery .image-tile img {
  object-fit: fill;
  height: 35vh;
  border-radius: 20px;
}

/* //added */

.lightGallery .image-tile img {
  height: inherit;
  object-fit: contain;
  width: inherit;
}
.lightGallery {
  grid-template-columns: repeat(3, 1fr) !important;
}
/* //added */
.previewpagenew .lightGallery {
  grid-template-columns: repeat(1, 1fr) !important;
}

.previewpagenew .card .card-left .card-img img,
.previewpagenew .card .card-left .card-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

a.float.right.btn-edit {
  border: 1px solid;
  border-radius: 10px;
  padding: 3px 15px;
  background-color: #0d6efd;
  color: #fff;
  font-size: 15px;
}

.Skillmaster .nav-pills .nav-link.active,
.Skillmaster .nav-pills .show > .nav-link {
  background-color: unset;

  border-bottom: solid 2px #0d6efd !important;

  border-radius: 0px;

  color: #0d6efd;
}

/* 11-1-23 */
.Skillmaster td a.btn.btn-sm.btn-primary.btn-rounded {
  background-color: #0a66c2;
}
.Skillmaster td a.btn.btn-sm.btn-danger,
a.btn.btn-sm.btn-danger {
  background-color: #8a8a8a;
  border-color: #8a8a8a;
}
.Skillmaster .btn {
  border-radius: 10px;
}
.Skillmaster a.editskillbtn {
  background-color: #034ea2 !important;
}
.Skillmaster .collapse.show {
  /* background-color: #e7f1ff; */
  background: linear-gradient(#fff, #f0f4f7);
}
.Skillmaster table.table.table-bordered.skillstablesection,
.Skillmaster table.table.table-bordered.skillstablesection td {
  border: 1px solid #d3d3d3;
}
.Skillmaster table.table.table-bordered.skillstablesection td {
  text-align: center;
}
.Skillmaster .skillversionstatusbgcolor {
  /* background-color: #e7f1ff !IMPORTANT; */
  background: linear-gradient(#fff, #f0f4f7) !important;
  display: inline-block;
}

.Skillmaster .nav-pills .nav-link {
  border: 0px solid #0d6efc;

  padding: 0.2rem 1.75rem;
}

.borderad .btn {
  border-radius: 10px;
}

td.text-left-intend {
  text-indent: 16px;
 }

 /* table.table.table-bordered.mb-3.admin-table-res td {
  white-space: break-spaces;
  padding: 10px 5px;
} */
/* 18-1-23 */
/* a.rfp-publish-btn {
  left: 9.5pc;
  top: 1pc;
} */

/* 19-1-23 */
.admin_auto_main_screen
{
  overflow-y: auto;
    height: calc(100vh - 7rem);
}
.img-autopublish
{
  width: 123px !important;
  height: 123px !important;
  /* padding: 0.5rem;
  margin: 0 auto; */
}
.filter_headings
{
  font-size: 17px !important;
}
.admin-manage-rfpbtn
{
  width:11pc;
}
a.edit-profile.btn-orange.quote-btn.disabled {
  opacity: 0.65;
}
/* 1-03-23 */
ul.list-style-bullet li {
  list-style: disc;
  margin-left: 25px;
}