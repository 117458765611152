.sidebar-icon-only .main-panel {
    width: calc(100%);
}
.fre-authen-wrapper {
    padding-top: 20px;
    margin-bottom: 20px;
}
.fre-register-default {
    background: #FFF;
    margin: 0 auto;
    padding: 40px 0 0;
    width: 80%;
    height: auto;
    -webkit-box-shadow: 0px 1px 1px 0px rgb(185 184 184 / 75%);
    -moz-box-shadow: 0px 1px 1px 0px rgba(185, 184, 184, 0.75);
    box-shadow: 0px 1px 1px 0px rgb(185 184 184 / 75%);
}
.fre-register-default>h2 {
    /* margin: 0 0 40px; */
    font-size: 26px;
    font-weight: 600;
    color: #2c3e50;
    line-height: 1em;
    text-align: center;
}
.fre-register-wrap {
    position: relative;
    padding-top: 10px;
    padding-bottom: 0px;
}
.fre-register-wrap1:before {
    /* content: '';
    display: block;
    width: 2px;
    height: 100%;
    border-right: 1px solid #dbdbdb;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); */
}

.fre-register-wrap .register-employer {
    text-align: center;
    padding: 0 30px;
}
.fre-register-wrap .col-sm-4 .register-employer, 
.fre-register-wrap .col-sm-4 .register-freelancer
{
    border-right: 1px solid #dbdbdb;
    
    padding-bottom: 60px !important;
}
.fre-register-wrap .col-sm-4{
    border-bottom: 1px solid #dbdbdb;
}
.fre-register-wrap .col-sm-4:last-child .register-employer,
.fre-register-wrap .col-sm-4:last-child .register-freelancer
{
    border-right: 0;
}
.fre-register-wrap .register-employer h3 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 600;
    color: #415161;
    line-height: 1.15em;
}
.fre-register-wrap .register-employer p {
    margin: 0;
    font-size: 16px;
    color: #415161;
    line-height: 1.6em;
}
.fre-register-wrap .register-employer .fre-small-btn {
    margin-top: 24px;
}
body .fre-normal-btn, .primary-bg-color, .fre-small-btn, .fre-submit-btn, body .fre-payment-list>li>.btn, body .fre-payment-list>li>form>.btn body .fre-btn, body.fre-btn-disable, body .fre-payment-list>li>.btn, body .fre-btn, body.fre-btn-disable, body .fre-payment-list>li>form>button.btn {
    background-color: #1fbdbd;
    border-color: #1fbdbd;
}
.fre-register-wrap .register-freelancer {
    text-align: center;
    padding: 0 30px;
}
.fre-register-wrap .register-freelancer h3 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 600;
    color: #415161;
    line-height: 1.15em;
}
.fre-register-wrap .register-freelancer p {
    margin: 0;
    font-size: 16px;
    color: #415161;
    line-height: 1.6em;
}
.fre-register-wrap .register-freelancer .fre-small-btn {
    margin-top: 24px;
}
.fre-small-btn {
    display: inline-block;
    width: auto;
    height: 38px;
    background: #1a3298;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    border-radius: 4px;
    border: 2px solid #1a3298;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 34px;
    -webkit-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    outline: none;
    cursor: pointer;
}
.fre-register-default .fre-authen-footer>p {
    margin: 0;
    padding: 30px 0 0;
    font-size: 16px;
    color: #415161;
    line-height: 1em;
    /* border-top: 1px solid #dbdbdb; */
}
/* home */
.banner-slide{
    padding: 0px 0px 0px 0px;
    width: 100%;
    position: relative;
}
.bg-banner, .vegas-slide-inner{
    background-color: rgb(222, 222, 222);
}

.home_blur{
    height: 400px;
}
body .vegas-container {
    overflow: hidden !important;
    position: relative;
}
.home_blur {
    /* background: #dedede no-repeat center; */
    background: #3b5ef2 no-repeat center;
    background-size: cover;
    width: 100%;
    height: 400px;
    margin: auto;
    position: relative;
    background-position: center;
}
.vegas-transition-fade, .vegas-transition-fade2 {
    opacity: 0;
}
.vegas-slide, .vegas-slide-inner {
    margin: 0;
    padding: 0;
    background: transparent center center no-repeat;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.vegas-wrapper, .vegas-overlay, .vegas-timer, .vegas-slide, .vegas-slide-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
}
.vegas-slide, .vegas-slide-inner {
    margin: 0;
    padding: 0;
    background: transparent center center no-repeat;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.vegas-wrapper, .vegas-overlay, .vegas-timer, .vegas-slide, .vegas-slide-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
}
.vegas-timer {
    top: auto;
    bottom: 0;
    height: 2px;
}
.vegas-wrapper, .vegas-overlay, .vegas-timer, .vegas-slide, .vegas-slide-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
}
.vegas-timer-progress {
    width: 0%;
    height: 100%;
    background: white;
    -webkit-transition: width ease-out;
    transition: width ease-out;
}
.vegas-wrapper, .vegas-overlay, .vegas-timer, .vegas-slide, .vegas-slide-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
}
#container-home-blur {
    margin-top: 75px;
}
#container-home-blur {
    margin-top: 85px;
    margin-bottom: 85px;
}
.main_tagLine {
    color: #ffffff;
}
.main_tagLine, .sub_tagLine, .buttons_box_main {
    text-align: left;
}
.sub_tagLine {
    color: #ededed;
}
.wrps {
    width: 500px;
}
.main_tagLine, .sub_tagLine, .buttons_box_main {
    text-align: left;
}
.regular_ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}
.regular_ul li a:link, .regular_ul li a:visited {
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
}
.main_tagLine {
    font-size: calc(12px + 1.3vw);
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 0;
}
.sub_tagLine, .wrps {
    font-size: calc(12px + 0.3vw);
}
.sub_tagLine {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lato',Arial, Helvetica, sans-serif;
    margin-bottom: 30px;
    /* text-shadow: 2px 2px 5px black, 2px 2px 100px black; */
    margin: auto;
    /* width: 1000px; */
}
.sub_tagLine {
    font-size: calc(12px + 0.3vw);
}
.buttons_box_main {
    margin: auto;
    padding-top: 45px;
}
.btn-group-lg>.btn, .btn-lg {
    padding: 0.5rem 1rem !important;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item {
    margin-left: 0rem;
    padding: 0 15px;
    height: 60px;
    display: flex;
    align-items: center;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item:hover {
    border-bottom: 2px solid #1a3298;
}
.auth-form-light {
    background: #ffffff;
}
.form-group {
    margin-bottom: 1.5rem;
}
.Certificates{
    position: relative;
}
.Certificates label{
    position: absolute;
    top: 7px;
    z-index: 1;
    left: 15px;
    opacity: .3;
}
/* Upload image */
#upload {
    opacity: 0;
}
#upload-label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
}
.image-area {
    border: 2px dashed rgba(84, 83, 83, 0.7);
    padding: 1rem;
    position: relative;
}

.image-area::before {
    /*content: 'Uploaded image result';*/
    color: #ddd;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    z-index: 1;
}

.image-area img {
    z-index: 2;
    position: relative;
}

.input-group-append label{
    padding: 2px;
    font-size: 12px;
    top: -2px;
}
.input-group-append label i{
    padding-right: 5px;
}
/* REG */
.form-group {
    margin-bottom: 0.75rem;
  }
  .wizard > .content > .body{
    width: 100%;
  }
  .wizard > .content{
    min-height: auto;
    overflow: unset;
  }
  .border-bottom-title{
    border-bottom: 3px solid #1F3BB3;
  }
  .wizard.vertical > .steps {
    display: inline;
    float: left;
    width: 23%;
    padding: 0px 25px 0 0px;
}
.wizard{
    margin: 0;
}
.wizard.vertical > .content {
    display: inline;
    float: left;
    width: 77%;
    padding: 15px;
    margin: 0;
}
.wizard > .content > .body{
    padding: 0;
    position: relative;
}
.auth-form-light {
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgba(23, 23, 23, 0.32);
}
.steps li.current:after {
    width: 0;
    height: 0;
    border-left: 15px solid #1F3BB3;
    border-right: 15px solid transparent;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    content: " ";
    position: absolute;
    top: 12px;
    right: -23px;
}
.wizard.vertical > .steps > ul > li {
    position: relative;
}
.steps li.last.current.done:after{    
    width: 0;
    height: 0;
    border-left: 15px solid #5671e2;
    border-right: 15px solid transparent;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    content: " ";
    position: absolute;
    top: 12px;
    right: -23px;
}
.lazyHead .card {
    border-radius: 0;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%), 0 1px 2px 0 rgb(0 0 0 / 10%), 0 2px 20px 0 rgb(0 0 0 / 20%);
    margin: 20px 0;
}
.lazyHead .card .cardPad {
    padding: 30px 20px;
}
.lazyHead .card .widgetHead .widgetTitle {
    font-weight: 600;
    font-size: 20px;
    color: #333;
}
.lazyHead .card .widgetHead .edit {
    margin: 0 0 0 15px;
}
.lazyHead .card, .card-panel {
    margin: 0.5rem 0 1rem;
    background-color: #fff;
    transition: box-shadow .25s;
    border-radius: 2px;
    position: relative;
}

.lazyHead .icon.edit, .lazyHead .icon.edit {
    color: #4A90E2;
    cursor: pointer;
    font-size: 16px;
    vertical-align: baseline;
    padding: 0 5px;
    text-align: center;
}
.profileSummary .prefill, .resumeHeadline .prefill {
    white-space: normal;
    word-break: break-word;
}
.lazyHead .prefill {
    color: #666;
    font-size: 13px;
    /* white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap; */
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    line-height: 20px;
}
.steps.clearfix, .content.clearfix, .actions.clearfix{
clear: unset;
}
.lazyHead .card .widgetCont {
    margin: 15px 0 0;
}
.prefill .chip {
    background: #eee;
    padding: 11px 20px;
    border-radius: 4px;
    margin: 10px 10px 0 0;
    color: #666;
    max-width: 515px;
}
.prefill .chip, .profilePage .accomplishments .acm-list .acm-list-item .hdn3 {
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    text-overflow: ellipsis;
}
/* user */
html.postrfp-page .font-weight-semibold {
    font-size: 20px;
}

.postrfp-page .prefill b {
    width: 250px;
}

.widgetTitle {
    color: #666;
}

/* 27-7-22 */
.MobResView li{
    line-height: normal;
}
#home{
    position: absolute;
    top: 0;
}
.company-homepage .navbar-expand-md .navbar-nav .nav-item .nav-link{
    display: table-cell;
    vertical-align: middle;
}
.company-homepage .navbar li {
    display: table;
   
}
.company-homepage .navbar-nav .navlink{
    display: table-cell;
    align-items: center;
    text-transform: uppercase;
    min-height: 60px;
    vertical-align: middle;
}
.company-homepage .navbar.default-layout{
    min-height: 60px;
}
.MobResView li{
    text-transform: uppercase;
}
.MobResView.navbar-nav .nav-link:hover {
    border-bottom: 2px solid #1a3298;
    color: #1a3298 !important;
    font-weight: normal;
}

.navbar-expand-lg .navbar-nav .nav-link{
    vertical-align: middle;
    display: table-cell;
}
.MobResView.navbar-nav .navlink{
    display: flex;
    align-items: center;
}
.company-homepage .navbar-light .navbar-nav .nav-link.active{
    color: #f79727 !important;
    border-bottom: 2px solid #f79727;
    font-weight: bold;
}
/* Captcha */
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgb(31 59 179);
}