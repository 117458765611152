/* Index Home */
@media (min-width: 768px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

@media (max-width: 1024px) {
  ul.font-18 li {
    font-size: 20px;
    text-align: left;
  }

  .w_17 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  #carouselExampleCaptions .col-lg-10 {
    margin-top: 20px;
  }

  .company-homepage .joinus-link {
    padding: 0 12px !important;
    top: 45%;
  }

  .wrapper .form {
    padding: 0 !important;
  }

  .company-homepage .navbar-light .navbar-nav .nav-link.active {
    border-bottom: 0;
  }

  .MobResView.navbar-nav .nav-link:hover {
    border-bottom: 0;
  }

  .company-homepage .navbar-nav .navlink {
    min-height: auto;
  }

  .home-page-content .block {
    top: -2pc;
    position: relative;
  }

  .home-page-content .block {
    padding: 0;
  }

  .timeline .timeline-wrapper.timeline-inverted .timeline-panel {
    /* margin-left: auto;
      margin-right: 0; */
  }

  .navbar {
    padding: 10px !important;
  }

  .MobResView {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  #navbarCollapse {
    padding: 0;
  }

  .nav-link {
    padding: 0.5rem 1rem;
  }

  .company-homepage .navbar li {
    line-height: 1;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .JoinUs,
  .JoinUs:hover,
  .JoinUs:focus {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    font-family: Barlow-Bold;
    text-transform: uppercase;
    border: 2px solid #fff;
    padding: 7px 20px;
  }

  .joinus-link h5 {
    font-size: 62px;
    font-weight: bold;
    color: #340000;
    line-height: 1.3;
    font-family: Calibri_Bold;
  }
}

@media (max-width: 767px) {
  .width_60 {
    width: 100%;
  }

  .w_46 {
    width: auto !important;
    padding: 10px;
  }

  .w_17 {
    width: 100% !important;
  }
  .prefill b {
    width: 125px;
    font-weight: normal;
    font-size: 10px;
  }
  .previewpagenew .lightGallery .image-tile {
    width: 100%;
}
  a.btn-primary.add-pi.float-right.btn-rounded.btn-icon {
    padding: 6px 7px;
    font-size: 12px;
  }
  .mob-top-5 {
    margin-top: 3em;
  }
  .joinus-link h5,
  .joinus-link h5 span {
    font-size: 42px;
  }

  .JoinUs,
  .JoinUs:hover,
  .JoinUs:focus {
    font-size: 16px;
  }

  html.signup-page .fre-register-default {
    width: 100%;
  }

  .pricing-table .pricing-card .pricing-card-body {
    padding: 15px;
  }

  .timeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: auto;
    margin-right: 0;
  }

  .container {
    max-width: 100%;
  }

  .home-page-content .block {
    top: -2pc;
    position: relative;
  }

  ul.font-18 li {
    font-size: 14px;
  }

  .MultiCarousel .rightLst {
    right: 10px;
  }

  .MultiCarousel .leftLst {
    left: 10px;
  }

  #contact-us .form {
    padding: 10px 0;
  }

  .navbar-menu-wrapper .navbar-nav li .h3,
  .navbar-menu-wrapper .navbar-nav .h3 small {
    font-size: 1rem;
  }

  .Text-Green-Head {
    font-size: 22px;
  }

  .l_c_h{
    width: 50%;
    z-index: unset;
}
  .chatbox {
  left: 12.2pc;
  z-index: 9999;
  width: 22.9pc;
}
.caas_logo_mob{
  margin: 0 auto;
}
}

@media (max-width: 700px) {
  #contact-us1 .form {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 667px) {
}

@media (max-width: 568px) {
  .login-page .fre-authen-wrapper .fre-register-default {
    width: 100% !important;
    max-width: 100%;
  }

  .Text-Green-Head {
    font-size: 22px;
  }
}

@media (max-width: 540px) {
  .Text-Green-Head {
    margin-bottom: 15px !important;
  }

  .Partner_main_head {
    display: block !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .Partner_main_head h2,
  .Partner_main_head a {
    display: block !important;
    width: 100%;
    clear: both;
    text-align: center;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .company-homepage .joinus-link {
    padding: 0 12px !important;
    top: 45%;
  }

  .timeline li {
    min-height: 10pc;
  }
 
}


@media (max-width: 480px) {
  html,
  body,
  .sidebar .nav .nav-item.active > .nav-link .menu-title,
  .sidebar .nav .nav-item .nav-link .menu-title,
  .sidebar .nav.sub-menu .nav-item .nav-link,
  .home-tab .nav-tabs .nav-item .nav-link,
  .home-tab .statistics-details .statistics-title,
  .home-tab .statistics-details .statistics-title,
  .home-tab .btn,
  .home-tab .fc button,
  .fc .home-tab button,
  .home-tab .ajax-upload-dragdrop .ajax-file-upload,
  .ajax-upload-dragdrop .home-tab .ajax-file-upload,
  .home-tab .swal2-modal .swal2-buttonswrapper .swal2-styled,
  .swal2-modal .swal2-buttonswrapper .home-tab .swal2-styled,
  .home-tab .wizard > .actions a,
  .wizard > .actions .home-tab a,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .form-control,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .asColorPicker-input,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .dataTables_wrapper
    select,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    select,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    input[type="text"],
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    input[type="text"],
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    select,
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    select,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    input[type="number"],
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    input[type="number"],
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-container--default
    .select2-selection--single,
  .select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-selection--single,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
  .select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-search__field,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .typeahead,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .tt-query,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .tt-hint,
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown-bordered,
  .dropdown .dropdown-menu .dropdown-item,
  p,
  body *,
  #performance-line-legend .chartjs-legend ul li,
  .text-small,
  .form-control,
  .asColorPicker-input,
  .dataTables_wrapper select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-search__field,
  .typeahead,
  .tt-query,
  .tt-hint,
  .form-group label,
  .btn.btn-sm,
  .btn-group-sm > .btn,
  .fc .btn-group-sm > button,
  .ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
  .swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
  .wizard > .actions .btn-group-sm > a,
  .fc button,
  .ajax-upload-dragdrop .btn-sm.ajax-file-upload,
  .ajax-upload-dragdrop .fc button.ajax-file-upload,
  .fc .ajax-upload-dragdrop button.ajax-file-upload,
  .swal2-modal .swal2-buttonswrapper .btn-sm.swal2-styled,
  .swal2-modal .swal2-buttonswrapper .fc button.swal2-styled,
  .fc .swal2-modal .swal2-buttonswrapper button.swal2-styled,
  .wizard > .actions a.btn-sm,
  .form-check .form-check-label,
  html.company-homepage .navbar li a {
    font-size: 10px;
  }

  html.company-homepage
    .timeline
    .timeline-wrapper
    .timeline-panel
    .timeline-title {
    font-size: 12px;
    font-weight: bold;
  }

  .home-page-content .block {
    top: -2pc;
    position: relative;
  }
}

/****************************** responsive ****************************/
@media screen and (min-width: 1920px) and (max-width: 1930px) {
  .f_14 {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1340px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 1rem;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .navbar {
    padding: 0 10px !important;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .img-jobs {
    border-radius: 10px;
    height: 30px;
    width: 30px;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1368px) {
  label.form-check-label {
    font-size: 12px !important;
  }
  .box-home.box_shadow_1.main-right.icon-box.mt-4.px-4.announcements-container,
  .box-home.box_shadow_1.px-4.workspace-container {
    width: 215px;
  }
  input.form-control.comment-section {
    border-radius: 20px;
    width: 100%;
  }
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    left: 0 !important;
  }
  a.send-btn {
    margin-left: 0px;
  }
  .box-home.box_shadow_1.main-right.icon-box.mt-4.px-4.Announcement-container {
    width: 215px;
  }

  .box-home.box_shadow_1.mt-4 {
    overflow-x: hidden;
  }
  .rounded-top.h_0.text-white.d-flex.flex-row.position-relative .col-sm-2 {
    width: 65%;
  }
  .box-shadow.p-2.border-round.w-100.custom_width {
    height: 260px;
  }
}
@media only screen and (min-width: 1270px) and (max-width: 1290px) and (min-height: 690px) and (max-height: 770px) {
  input.form-control.comment-section {
    width: 90%;
    border-radius: 20px;
  }
  img#emoji-4-75,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    position: relative;
    left: 0px;
  }
  .form-check label {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1270px) and (max-width: 1290px) and (min-height: 950px) and (max-height: 1030px) {
  .workspace-container,
  .announcements-container {
    width: 220px;
  }
  input.form-control.comment-section {
    width: 90%;
    border-radius: 20px;
  }
  img#emoji-4-75,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    position: relative;
    left: -20px;
  }
}

@media only screen and (min-width: 1270px) and (max-width: 1290px) and (min-height: 600px) and (max-height: 620px) {
  input.form-control.comment-section {
    width: 90%;
    border-radius: 20px;
  }
  .emoji-icon,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    position: relative;
    left: -30px;
  }
  .form-check label {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  .timeline li {
    width: 25%;
  }

  .timeline li:nth-child(6)::after {
    background: gray;
  }

  .timeline li:nth-child(5)::after {
    background: transparent;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .carousel-item .col-lg-4:nth-child(2) .card-img-overlay {
    padding-right: 2.5rem;
  }

  .carousel-item .col-lg-4:last-child .card-img-overlay {
    padding-right: 1rem;
  }

  .box_width {
    width: 33.33%;
    flex-wrap: wrap;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  .why-wc .row {
    justify-content: center;
  }

  .contact-form {
    margin: 0 auto;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1160px) and (min-height: 850px) and (max-height: 870px) {
  .announcements-container,
  .workspace-container,
  .menu-container,
  .addToFeedBody {
    width: 180px;
  }
  .f_18,
  h3.fw-bold.fs-4.mb-0 {
    font-size: 17px !important;
  }
  input.form-control.comment-section {
    width: 95%;
  }
  .emoji-icon,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    left: -20px;
    position: relative;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1160px) and (min-height: 790px) and (max-height: 810px) {
  p.mb-0.fw-bold.text-black {
    font-size: 12px;
  }
  img#emoji-4-75,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    position: relative;
    left: -20px;
  }
  img#emoji-4-75,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    position: relative;
    left: -30px;
  }
  .workspace-container,
  .announcements-container {
    width: 190px;
  }
  .addToFeedBody {
    overflow-y: auto !important;
  }

  input.form-control.comment-section {
    border-radius: 20px;
    width: 85%;
  }
  input.form-control.comment-section {
    border-radius: 20px;
    width: 90%;
  }

  .infinite-scroll-component.ins.mt-10 {
    overflow-x: hidden !important;
  }

  p.fw-bold.text-muted.mt-2.h4 {
    font-size: 12px;
  }
  img#emoji-4-92,
  button.btn-primary.mt_15.text-black-50.send-btn.commentSubmitBtn {
    position: relative;
    left: 0px;
  }
  .word-wrap {
    word-break: break-all;
  }
}

@media screen and (max-width: 1122px) {
  .width_60 {
    width: 80%;
  }

  #recipeCarousel .carousel-control-next {
    opacity: 1;
    right: 0;
    justify-content: end;
  }

  .text-56 {
    font-size: 46px;
    font-family: Barlow-SemiBold;
    line-height: 0.78;
  }

  .text-28 {
    font-size: 22px;
    font-family: Barlow-SemiBold;
  }
}

@media screen and (max-width: 991px) {
  .width_right_hover_panel {
    padding-top: 0.5rem !important;
  }

  .section-partner-with-caas .joinus-link h5 {
    font-size: 62px;
    font-weight: bold;
    color: #340000;
    line-height: 1.3;
    font-family: Calibri_Bold;
  }

  .contact-form {
    margin-left: auto;
  }

  .timeline li {
    width: 33.33%;
  }

  .timeline li:nth-child(6)::after,
  .timeline li:nth-child(5)::after {
    background: gray;
  }

  .timeline li:nth-child(4)::after,
  .timeline li:nth-child(7)::after {
    background: transparent;
  }

  .contact-form {
    width: 100%;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
    flex: 0 0 auto;
  }

  .text-56 {
    font-size: 46px;
    font-family: Barlow-SemiBold;
    line-height: 0.78;
  }

  .section-footer .col-lg-8 {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .width_right_hover_panel .row:last-child .col-md-6:last-child {
    padding-top: 0.5rem !important;
  }

  .carousel-item .col-lg-4:first-child .card-img-overlay {
    padding-right: 2.5rem;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
    flex: 0 0 auto;
  }

  .box_width {
    width: 50%;
    flex-wrap: wrap;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  .why-wc .row {
    justify-content: center;
  }

  .contact-form {
    margin-left: auto;
  }

  .timeline li {
    width: 50%;
  }

  .timeline li:nth-child(3)::after,
  .timeline li:nth-child(5)::after,
  .timeline li:nth-child(5)::after {
    background: transparent;
  }

  .timeline li:nth-child(4)::after,
  .timeline li:nth-child(6)::after {
    background: gray;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
  }

  #recipeCarousel .carousel-inner .carousel-item > div:first-child {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .box_width {
    width: 100%;
    flex-wrap: wrap;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-bottom: 15px;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .why-wc .row {
    justify-content: center;
  }

  .contact-form {
    margin-left: auto;
  }

  .timeline li {
    width: 100%;
  }

  .timeline li::after,
  .timeline li:nth-child(5)::after,
  .timeline li:nth-child(5)::after {
    background: transparent !important;
  }

  .mh-500 {
    min-height: 200px;
  }

  #Step_2 .table td {
    width: 100%;
    display: block;
  }

  .list-item-details .d-table .d-table-cell,
  .list-item-details .d-table {
    width: 100% !important;
    display: block !important;
  }

  .list-item-details .d-table .d-table-cell {
    padding-right: 15px !important;
  }

  .btn-orange {
    padding: 5px 10px;
  }

  .RFP-btn-group {
    display: block;
  }

  .RFP-btn-group .btn {
    margin-bottom: 15px;
    border: 2.5px solid #da7120;
  }

  .RFP-btn-group.btn-group .btn + .btn {
    border: 2.5px solid #da7120;
  }
}

@media (min-width: 992px) {
  .width_left_hover_panel {
    width: 29%;
  }

  .width_right_hover_panel {
    width: 71%;
  }
}

@media (max-width: 1024px) {
  .col-md-8.box_2 {
    width: 100%;
  }

  .box-home {
    margin-top: 1.5rem !important;
  }

  .h-100 {
    height: auto !important;
  }

  .w_46 {
    width: 100% !important;
    padding: 10px;
  }
  .w_66 {
    width: 100% !important;
  }
  .infinite-scroll-component.ins.mt-10 {
    overflow-x: hidden !important;
  }
}

@media (max-width: 991px) {
  .col-md-8.box_2 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .col-md-8.box_2 {
    width: 100%;
  }

  .header {
    display: block !important;
  }

  .header > div:not(:first-child) {
    margin-top: 15px;
  }

  .h_60 {
    height: auto;
  }

  .sticky-top {
    position: relative;
  }
}

@media (min-width: 811px) and (max-width: 824px) {
  .sticky-top {
    position: relative;
  }

  .body-section.mt-4 {
    margin-top: 0rem !important;
  }

  .col-md-8.box_2 {
    width: 100%;
  }

  .box-home {
    margin-top: 1.5rem !important;
  }

  .box_3,
  .box_4 {
    margin-top: 0px;
  }
  .lightGallery1 {
    margin: 0 auto;
    width: 50%;
  }
}

@media (max-width: 568px) {
  .col_res.box_3,
  .col_res.box_4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .box-home {
    margin-top: 1.5rem !important;
  }

  .box_3,
  .box_4 {
    margin-top: 0px;
  }

  .body-section.mt-4 {
    margin-top: 0rem !important;
  }

  .h-100 {
    height: auto !important;
  }
}

/****************************** responsive ****************************/
@media screen and (min-width: 1430px) and (max-width: 1450px) and (min-height: 1050px) and (max-height: 1060px) {
  img.trending_projects_img {
    width: 35px;
    height: 35px;
  }

  img.img-fluid.img-IT-rounded {
    max-width: 100%;
  }
}

@media screen and (min-width: 1365px) and (max-width: 1368px) and (max-height: 768px) {
  img.img-fluid.img-IT-rounded {
    max-width: 100%;
  }

  .w_90 {
    width: 100%;
  }
}

@media screen and (max-width: 1340px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 1rem;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .navbar {
    padding: 5px 10px !important;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .img-jobs {
    border-radius: 10px;
    height: 20px;
    width: 20px;
  }

  img.img-fluid.img-IT-rounded {
    max-width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .timeline li {
    width: 25%;
  }

  .timeline li:nth-child(6)::after {
    background: gray;
  }

  .timeline li:nth-child(5)::after {
    background: transparent;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .carousel-item .col-lg-4:nth-child(2) .card-img-overlay {
    padding-right: 2.5rem;
  }

  .carousel-item .col-lg-4:last-child .card-img-overlay {
    padding-right: 1rem;
  }

  .box_width {
    width: 33.33%;
    flex-wrap: wrap;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  .why-wc .row {
    justify-content: center;
  }

  .contact-form {
    margin: 0 auto;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
  }
}

@media screen and (max-width: 1122px) {
  .width_60 {
    width: 80%;
  }

  #recipeCarousel .carousel-control-next {
    opacity: 1;
    right: 0;
    justify-content: end;
  }

  .text-56 {
    font-size: 46px;
    font-family: Barlow-SemiBold;
    line-height: 0.78;
  }

  .text-28 {
    font-size: 22px;
    font-family: Barlow-SemiBold;
  }
}
/* ipad pro */
@media screen and (min-width:1020px) and (max-width:1030px) and (min-height:1360px) and (max-height:1370px){
  .sidebar-icon-only .sidebar {
    width: 23%;
  }
  .custom-width_125 {
    width: 100%;
  }
  .chatbox{
    margin-right: 2.5pc;
width: 30pc;

}
.l_c_h{
width:30%;
}
}
/* ipad landscape */
@media screen and (min-width: 1020px) and (max-width: 1030px) and (min-height: 760px) and (max-height: 770px) {
  .ipad-ps-3 {
    margin-left: 3pc;
}
.custom-width_125{
  width: 100%;
}
.sidebar-icon-only .sidebar {
  width: 23%;
}
.chatbox{
  margin-right: 6pc;
  width: 23pc;
}
.l_c_h{
width:35%;
}
}
/* normal-ipad portrait */
@media screen and (min-width: 760px) and (max-width: 780px) and (min-height: 1020px) and (max-height: 1030px) {
  .ipad-ps-3 {
    margin-left: 3pc;
  }
  .lightGallery1 {
    margin: 0 auto;
    width: 50%;
  }
  .mob-mt-5 {
    margin-top: 1em;
  }
  aside.EmojiPickerReact.epr-main {
    left: 24pc;
    top: 9pc;
z-index: 9999;
}
.chatbox{
margin-right: 0pc;
width: 23pc;
}
.l_c_h{
width: 33%;
}
}
/* zoom 125 */
@media screen and (min-width: 1020px) and (max-width: 1030px) and (min-height: 450px) and (max-height: 490px) {
  .custom-width_125 {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .width_right_hover_panel {
    padding-top: 0.5rem !important;
  }

  .section-partner-with-caas .joinus-link h5 {
    font-size: 62px;
    font-weight: bold;
    color: #340000;
    line-height: 1.3;
    font-family: Calibri_Bold;
  }

  .contact-form {
    margin-left: auto;
  }

  .timeline li {
    width: 33.33%;
  }

  .timeline li:nth-child(6)::after,
  .timeline li:nth-child(5)::after {
    background: gray;
  }

  .timeline li:nth-child(4)::after,
  .timeline li:nth-child(7)::after {
    background: transparent;
  }

  .contact-form {
    width: 100%;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
    flex: 0 0 auto;
  }

  .text-56 {
    font-size: 46px;
    font-family: Barlow-SemiBold;
    line-height: 0.78;
  }

  .section-footer .col-lg-8 {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .width_right_hover_panel .row:last-child .col-md-6:last-child {
    padding-top: 0.5rem !important;
  }

  .carousel-item .col-lg-4:first-child .card-img-overlay {
    padding-right: 2.5rem;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
    flex: 0 0 auto;
  }

  .box_width {
    width: 50%;
    flex-wrap: wrap;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  .why-wc .row {
    justify-content: center;
  }

  .contact-form {
    margin-left: auto;
  }

  .timeline li {
    width: 50%;
  }

  .timeline li:nth-child(3)::after,
  .timeline li:nth-child(5)::after,
  .timeline li:nth-child(5)::after {
    background: transparent;
  }

  .timeline li:nth-child(4)::after,
  .timeline li:nth-child(6)::after {
    background: gray;
  }

  .carousel-item .col-lg-4 {
    padding: 0 12px;
    width: 50%;
  }
  .mob-mt-5 {
    margin-top: 1em;
  }
  button.btn.industry-list {
    font-size: 10px;
  }
  .l_c_h {
    width: 50%;
  }
  #recipeCarousel .carousel-inner .carousel-item > div:first-child {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .box_width {
    width: 100%;
    flex-wrap: wrap;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-bottom: 15px;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .why-wc .row {
    justify-content: center;
  }

  .contact-form {
    margin-left: auto;
  }

  .timeline li {
    width: 100%;
  }

  .timeline li::after,
  .timeline li:nth-child(5)::after,
  .timeline li:nth-child(5)::after {
    background: transparent !important;
  }

  .mh-500 {
    min-height: 200px;
  }

  #Step_2 .table td {
    width: 100%;
    display: block;
  }

  .list-item-details .d-table .d-table-cell,
  .list-item-details .d-table {
    width: 100% !important;
    display: block !important;
  }

  .list-item-details .d-table .d-table-cell {
    padding-right: 15px !important;
  }

  .btn-orange {
    padding: 5px 10px;
  }

  .RFP-btn-group {
    display: block;
  }

  .RFP-btn-group .btn {
    margin-bottom: 15px;
    border: 2.5px solid #da7120;
  }

  .RFP-btn-group.btn-group .btn + .btn {
    border: 2.5px solid #da7120;
  }
}

@media (min-width: 992px) {
  .width_left_hover_panel {
    width: 29%;
  }

  .width_right_hover_panel {
    width: 71%;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
    width: 460px;
    padding: 20px 20px;
    right: 0;
  }
}
/* Index Home */
@media (min-width: 768px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

@media (max-width: 1024px) {
  ul.font-18 li {
    font-size: 20px;
    text-align: left;
  }
}

@media (max-width: 991px) {
  #carouselExampleCaptions .col-lg-10 {
    margin-top: 20px;
  }

  .company-homepage .joinus-link {
    padding: 0 12px !important;
    top: 45%;
  }

  .wrapper .form {
    padding: 0 !important;
  }

  .company-homepage .navbar-light .navbar-nav .nav-link.active {
    border-bottom: 0;
  }

  .MobResView.navbar-nav .nav-link:hover {
    border-bottom: 0;
  }

  .company-homepage .navbar-nav .navlink {
    min-height: auto;
  }

  .home-page-content .block {
    top: -2pc;
    position: relative;
  }

  .home-page-content .block {
    padding: 0;
  }

  .navbar {
    padding: 10px !important;
  }

  .MobResView {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  #navbarCollapse {
    padding: 0;
  }

  .nav-link {
    padding: 0.5rem 1rem;
  }

  .company-homepage .navbar li {
    line-height: 1;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .JoinUs,
  .JoinUs:hover,
  .JoinUs:focus {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    font-family: Barlow-Bold;
    text-transform: uppercase;
    border: 2px solid #fff;
    padding: 7px 20px;
  }

  .joinus-link h5 {
    font-size: 62px;
    font-weight: bold;
    color: #340000;
    line-height: 1.3;
    font-family: Calibri_Bold;
  }
}

@media (max-width: 767px) {
  .width_60 {
    width: 100%;
  }

  .joinus-link h5,
  .joinus-link h5 span {
    font-size: 42px;
  }

  .JoinUs,
  .JoinUs:hover,
  .JoinUs:focus {
    font-size: 16px;
  }

  html.signup-page .fre-register-default {
    width: 100%;
  }

  .pricing-table .pricing-card .pricing-card-body {
    padding: 15px;
  }

  .timeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: auto;
    margin-right: 0;
  }

  .container {
    max-width: 100%;
  }

  .home-page-content .block {
    top: -2pc;
    position: relative;
  }

  ul.font-18 li {
    font-size: 14px;
  }

  .MultiCarousel .rightLst {
    right: 10px;
  }

  .MultiCarousel .leftLst {
    left: 10px;
  }

  #contact-us .form {
    padding: 10px 0;
  }

  .navbar-menu-wrapper .navbar-nav li .h3,
  .navbar-menu-wrapper .navbar-nav .h3 small {
    font-size: 1rem;
  }

  .Text-Green-Head {
    font-size: 22px;
  }
}

@media (max-width: 700px) {
  #contact-us1 .form {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 667px) {
}

@media (max-width: 568px) {
  .login-page .fre-authen-wrapper .fre-register-default {
    width: 100% !important;
    max-width: 100%;
  }

  .Text-Green-Head {
    font-size: 22px;
  }
}

@media (max-width: 540px) {
  .Text-Green-Head {
    margin-bottom: 15px !important;
  }

  .Partner_main_head {
    display: block !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .Partner_main_head h2,
  .Partner_main_head a {
    display: block !important;
    width: 100%;
    clear: both;
    text-align: center;
  }

  nav .container-fluid.px-5 {
    padding: 0 !important;
  }

  .company-homepage .joinus-link {
    padding: 0 12px !important;
    top: 45%;
  }

  .timeline li {
    min-height: 10pc;
  }
}

@media (max-width: 480px) {
  html,
  body,
  .sidebar .nav .nav-item.active > .nav-link .menu-title,
  .sidebar .nav .nav-item .nav-link .menu-title,
  .sidebar .nav.sub-menu .nav-item .nav-link,
  .home-tab .nav-tabs .nav-item .nav-link,
  .home-tab .statistics-details .statistics-title,
  .home-tab .statistics-details .statistics-title,
  .home-tab .btn,
  .home-tab .fc button,
  .fc .home-tab button,
  .home-tab .ajax-upload-dragdrop .ajax-file-upload,
  .ajax-upload-dragdrop .home-tab .ajax-file-upload,
  .home-tab .swal2-modal .swal2-buttonswrapper .swal2-styled,
  .swal2-modal .swal2-buttonswrapper .home-tab .swal2-styled,
  .home-tab .wizard > .actions a,
  .wizard > .actions .home-tab a,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .form-control,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .asColorPicker-input,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .dataTables_wrapper
    select,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    select,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    input[type="text"],
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    input[type="text"],
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    select,
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    select,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    input[type="number"],
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    input[type="number"],
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-container--default
    .select2-selection--single,
  .select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-selection--single,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
  .select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .select2-search__field,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .typeahead,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .tt-query,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .navbar-date-picker
    .tt-hint,
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown-bordered,
  .dropdown .dropdown-menu .dropdown-item,
  p,
  body *,
  #performance-line-legend .chartjs-legend ul li,
  .text-small,
  .form-control,
  .asColorPicker-input,
  .dataTables_wrapper select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-search__field,
  .typeahead,
  .tt-query,
  .tt-hint,
  .form-group label,
  .btn.btn-sm,
  .btn-group-sm > .btn,
  .fc .btn-group-sm > button,
  .ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
  .swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
  .wizard > .actions .btn-group-sm > a,
  .fc button,
  .ajax-upload-dragdrop .btn-sm.ajax-file-upload,
  .ajax-upload-dragdrop .fc button.ajax-file-upload,
  .fc .ajax-upload-dragdrop button.ajax-file-upload,
  .swal2-modal .swal2-buttonswrapper .btn-sm.swal2-styled,
  .swal2-modal .swal2-buttonswrapper .fc button.swal2-styled,
  .fc .swal2-modal .swal2-buttonswrapper button.swal2-styled,
  .wizard > .actions a.btn-sm,
  .form-check .form-check-label,
  html.company-homepage .navbar li a {
    font-size: 10px;
  }

  html.company-homepage
    .timeline
    .timeline-wrapper
    .timeline-panel
    .timeline-title {
    font-size: 12px;
    font-weight: bold;
  }

  .home-page-content .block {
    top: -2pc;
    position: relative;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1540px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 57vh;
  }
}

/* media query for 125% */

@media (min-width: 1190px) and (max-width: 1200px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 57vh;
  }
}

/* media query for 110% */

@media (min-width: 1352px) and (max-width: 1365px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* media query for 150% */

@media (min-width: 991px) and (max-width: 1000px) {
  .previewpagenew .center_img {
    width: 100%;
    height: 0px;
    object-fit: contain;
  }
  .previewpagenew .elon {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card .card-left {
    width: 100%;
  }
  .lightGallery .image-tile img {
    height: 86vh;
  }
  .previewpagenew .card .card-left .card-img {
    height: 400px;
  }
  .card .card-right {
    width: 100%;
  }
}

/* it is for 67% */

@media (min-width: 2233px) and (max-width: 2300px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* it is for 75% */

@media (min-width: 1981px) and (max-width: 2030px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* it is for 80% */

@media (min-width: 1850px) and (max-width: 1919px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 42vh;
  }
}

/* media query for 125% */

@media (min-width: 1190px) and (max-width: 1200px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 57vh;
  }
}

/* media query for 110% */

@media (min-width: 1352px) and (max-width: 1365px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* media query for 150% */

@media (min-width: 991px) and (max-width: 1000px) {
  .previewpagenew .center_img {
    width: 100%;
    height: 0px;
    object-fit: contain;
  }
  .previewpagenew .elon {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card .card-left {
    width: 100%;
  }
  .lightGallery .image-tile img {
    height: 86vh;
  }
  .previewpagenew .card .card-left .card-img {
    height: 400px;
  }
  .card .card-right {
    width: 100%;
  }
}

/* it is for 67% */

@media (min-width: 2233px) and (max-width: 2300px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* it is for 75% */

@media (min-width: 1981px) and (max-width: 2030px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* it is for 80% */

@media (min-width: 1850px) and (max-width: 1919px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 42vh;
  }
}

/* media query for 50% */

@media (min-width: 2970px) and (max-width: 3020px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

/* media query for 50% */

@media (min-width: 2970px) and (max-width: 3020px) {
  .previewpagenew .lightGallery .image-tile img {
    height: 44vh;
  }
}

@media only screen and (min-width: 667px) and (max-width: 767px) {
  .previewpagenew .elon {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card .card-left {
    width: 100%;
  }
  .previewpagenew .card .card-left .card-img {
    height: 400px;
  }
  .lightGallery .image-tile img {
    height: 65vh;
  }
}

@media (min-width: 912px) and (max-width: 980px) {
  .previewpagenew .center_img {
    width: 100%;
    height: 0px;
    object-fit: contain;
    left: 64px;
  }
  .previewpagenew .elon {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card .card-left {
    width: 100%;
  }
  .previewpagenew .card .card-left .card-img {
    height: 400px;
  }
  .card .card-right {
    width: 100%;
  }
}

@media (min-height: 720px) {
  html.previewpagenew .container-fluid {
    max-width: 1366px;
  }
}

/* 12/1/23 */
@media screen and (min-width:1020px) and (max-width:1030px) and (min-height:1360px) and (max-height:1370px){

  .Skillmaster .collapse.show {
    overflow-x: auto;
  }
}
  @media (max-width: 767px) { 
    .Skillmaster .collapse.show {
      overflow-x: auto;
    }
  }